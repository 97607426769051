import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { useForm, Controller } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Select from "react-select";

const AddBranchesUser = ({
  handleAprroveClose,
  showApproveModal,
  getUsers,
  userid,
  branches,
  userData,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  // console.log(userData);
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    // console.log(data.branches);
    const values = data?.branches.map((branch) => branch.value).join(",");

    const newData = {
      branches: values,
    };

    //  console.log(newData);

    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.patch(
        `users/${userData?.id}`,
        newData,
        {
          signal: controller.signal,
        }
      );
      console.log(response.data);
      toast.success(response?.data?.messages);
      progress.finish();
      getUsers();
      setDisabled(false);
      reset();
      handleAprroveClose();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const options = branches?.map((branch) => ({
    value: `${branch.id}`,
    label: `${branch.name}`,
  }));
  const defaultBranchIds = (userData?.branches || "").split(",");

  // Set the default values based on the array of branch IDs
  const defaultBranches = options.filter((branch) =>
    defaultBranchIds.includes(branch.value)
  );
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "60px",
      width: "470px",
      borderColor: "#cccccc",
      backgroundColor: "#fff",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      opacity: 1,
      zIndex: 9999,
    }),
  };

  // Set the default values initially
  //  setValue("branches", defaultBranches);

  // console.log(defaultBranches);

  return (
    <>
      <Modal
        show={showApproveModal}
        backdrop="static"
        keyboard={false}
        onHide={handleAprroveClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Attached Branches{" "}
          </Modal.Title>
          <button
            onClick={handleAprroveClose}
            className="btn-close btn-danger"
          ></button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="form-floating mb-1">
                  {/* <label htmlFor="branches" className="text-small p-2">
                    Select branches
                  </label> */}
                  <Controller
                    control={control}
                    name="branches"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        {...register("branches", { required: true })}
                        options={options}
                        defaultValue={defaultBranches}
                        isMulti
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                        searchable={true}
                        styles={customStyles}
                        placeholder=""
                        ref={ref}
                      />
                    )}
                  />
                  {errors?.branches?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please select branches
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleAprroveClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-success"
            >
              {!disabled && "Update"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddBranchesUser;
