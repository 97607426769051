import { useMemo, useState } from "react";
import ConfirmModal from "pages/components/confirmModal";
import Table from "pages/components/common_table";
import { Modal } from "react-bootstrap";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const LoanDocumentTable = ({ data, refetch, isLoading, roles }) => {
  const [show, setShow] = useState(false);
  const handleConfirm = () => setShow(false);
  const handleCancel = () => setShow(false);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [documentFile, setDocumentFile] = useState([]);
   const handleOpenFileModal = (data) => {
     setOpenFileModal(true);
     setDocumentFile(data);
   };
   const handleCloseFileModal = (data) => {
     setOpenFileModal(false);
     setDocumentFile([]);
   };
  const columns_colletral = useMemo(
    () => [
      {
        id: "title", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Title",
        accessorFn: (row) => row.title,
      },
      {
        id: "timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Timestamp",
        accessorFn: (row) => row.timestamp,
      },

      {
        id: "url", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Document",
        accessorFn: (row) => (
          <div className="align-middle text-center text-sm">
             {roles.includes(10000056) && (
            <button
              className="btn btn-link text-info"
              onClick={() => handleOpenFileModal([{ uri: row.url ?? [] }])}
            >
              <i className="ti ti-file-filled"></i>
            </button>
             )}
          </div>
        ),
      },
      {
        id: "id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Action",
        accessorFn: (row) => (
          <div className="align-middle text-center text-sm">
             {roles.includes(10000057) && (
            <button
              className="btn btn-link  text-danger"
              onClick={() => {
                setShow(true);
              }}
            >
              <i className="ti ti-trash-x"></i>
            </button>
             )}
          </div>
        ),
      },
    ],
    [roles]
  );
  return (
    <>
      <div className="border-none">
        <div>
          <h6 className="fw-semibold p-4 text-center">Documents</h6>
          <Table
            columns={columns_colletral}
            data={data}
            refetch={refetch}
            isLoading={isLoading}
          />
        </div>
      </div>
      <ConfirmModal
        message="Are you sure you want to delete this document?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
      <Modal
        show={openFileModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseFileModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            View File
          </Modal.Title>
          <button
            onClick={handleCloseFileModal}
            className="btn-close btn-danger"
          ></button>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={documentFile}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-dark"
            onClick={handleCloseFileModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoanDocumentTable;
