import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Loader from "services/loaders/loader";

const LoanApplicationBranches = ({ roles }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const controller = new AbortController();
  const [branches, setBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getBranches = async () => {
    try {
      const response = await axiosPrivate.get("/users/branches", {
        signal: controller.signal,
      });
      setBranches(response?.data?.data?.branches);
      // console.log(response?.data);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container-fluid note-has-grid">
        <div className="card bg-light-info shadow-none position-relative overflow-hidden mt-md-0 mt-5">
          <div className="card-body px-4 py-3">
            <div className="row align-items-center">
              <div className="col-12">
                <h5 className="fw-semibold mb-8">Loan Applications</h5>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        className="text-muted text-decoration-none"
                        to="/dashboard"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Loan Applications
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fuild">
          <div className="card">
            <div className="card-header row">
              <div className="col-md-6 col-sm-12">
                <h5 className="fw-semibold mb-8">
                  Select Branch To Loan Applications
                </h5>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="container row">
                {isLoading && (
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body text-center">
                        <Loader />
                      </div>
                    </div>
                  </div>
                )}
                {branches?.map((branch) => (
                  <div className="col-md-3" key={branch.id}>
                    <div className="card shadow-lg">
                      <div className="card-header text-center">
                        {branch.name}
                      </div>
                      <div className="card-body text-center">
                        <Link
                          to={`/applications/branch/${branch.id}`}
                          className="btn btn-primary"
                        >
                          View Loan Applications
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanApplicationBranches;
