import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const ApproveLoan = ({ handleAprroveClose, showApproveModal, getLoan }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.put(
        `applications/${params.id}`,
        data,
        {
          signal: controller.signal,
        }
      );
      console.log(response.data);
      toast.success(response?.data?.messages);
      progress.finish();
      getLoan();
      setDisabled(false);
      reset();
      handleAprroveClose();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Modal
        show={showApproveModal}
        backdrop="static"
        keyboard={false}
        onHide={handleAprroveClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          {/* <Modal.Title id="contained-modal-title-vcenter"> </Modal.Title> */}
          <button
            onClick={handleAprroveClose}
            className="btn-close btn-danger"
          ></button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="row">
              <div className="mb-1 form-floating">
                <input
                  type="date"
                  className="form-control"
                  id="date"
                  name="date"
                  placeholder=""
                  {...register("date", { required: true })}
                />
                {errors?.date?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field is Required
                  </p>
                )}
                <label htmlFor="date" className="text-small px-3">
                  Approval Date
                </label>
                <input
                  type="hidden"
                  value="Loan has approved"
                  {...register("notes")}
                />
                <input type="hidden" value="approved" {...register("action")} />
              </div>

              <div className="mb-1 form-floating">
                <input
                  type="number"
                  className="form-control"
                  id="penalty"
                  name="penalty"
                  placeholder="Loan Penalty Percentage"
                  {...register("penalty", { required: true })}
                />
                <label htmlFor="penalty" className="px-4 text-small">
                  Loan Penalty
                </label>
                {errors?.penalty?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    This Field is Required
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleAprroveClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-success"
            >
              {!disabled && "Approve"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ApproveLoan;
