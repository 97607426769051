import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  defaults,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Title,
  Filler,
  Tooltip,
  Legend,
  (defaults.font.family = "Nunito")
);
const ClientsGroupsPie = ({ transactions }) => {
  const [chartOptions, setChartOptions] = useState({});
  const [chartData, setChartData] = useState({ datasets: [] });
  useEffect(() => {
    // Extract months and total amounts
    const titles = transactions?.map((group) => group.title);
    const members = transactions?.map((group) => group.members);
    const randomColors = generateRandomColors(transactions.length);
    // Prepare chart data
    const data = {
      labels: titles,
      datasets: [
        {
          label: "Clients",
          data: members,
          tension: 0.1,
          fill: true,
          borderColor: randomColors,
          backgroundColor: randomColors, // Area under the line
          pointBackgroundColor: randomColors, // Point color
          pointRadius: 5, // Point size
        },
      ],
    };

    setChartData(data);
    const options = {
      layout: {
        padding: 0, // Remove padding around the chart
      },

      plugins: {
        legend: {
          display: true, // Hide legend
        },
        tooltip: {
          enabled: true,
          titleFont: {
            size: 14,
            weight: "bold",
          },
          bodyFont: {
            size: 12,
          },
        },
      },
    };
    setChartOptions(options);
  }, [transactions]);

  // Function to generate random RGBA colors
  const generateRandomColors = (count) => {
    const colors = [];
    for (let i = 0; i < count; i++) {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      const alpha = Math.random(); // Random alpha (transparency) value between 0 and 1
      const rgbaColor = `rgba(${r}, ${g}, ${b}, ${alpha})`;
      colors.push(rgbaColor);
    }
    return colors;
  };

  return (
    <>
      <Doughnut data={chartData} options={chartOptions} />
    </>
  );
};

export default ClientsGroupsPie;
