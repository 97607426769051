import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import ConfirmModal from "pages/components/confirmModal";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
const AccountProfile = () => {
  const controller = new AbortController();

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar();

  const [profile, setProfile] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const getProfile = async () => {
    try {
      const response = await axiosPrivate.get("/single/users", {
        signal: controller.signal,
      });
      setProfile(response.data.data.user);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = async () => {};
  const handleCancel = async () => {};
  const {
    handleSubmit,
    register,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  
  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.post("/password", data, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      getProfile();
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  return (
    <>
      <Toaster />

          <div className="container-fluid note-has-grid">
            <div className="card bg-light-danger shadow-none position-relative overflow-hidden mt-md-0 mt-5">
              <div className="card-body px-4 py-3 ">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h5 className="fw-semibold mb-8">Profile</h5>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            className="text-muted text-decoration-none"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          Profile
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fuild">
              <div className="card">
                <div className="card-header row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="fw-semibold mb-8">
                      Profile - Update Password
                    </h5>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-md-6 mx-3">
                      <div className="card">
                        <div className="card-header h5">Profile</div>
                        <div className="card-body pt-4 mt-2">
                          <ul className="list-group">
                            <li className="list-group-item border-0 d-flex">
                              <div className="d-flex align-items-start flex-column justify-content-center">
                                <h6 className="mb-0 text-sm">FullName</h6>
                              </div>
                              <p className="pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                                {`${profile?.firstname} ${profile?.lastname}`}
                              </p>
                            </li>
                            <li className="list-group-item border-0 d-flex">
                              <div className="d-flex align-items-start flex-column justify-content-center">
                                <h6 className="mb-0 text-sm">Contact</h6>
                              </div>
                              <p className="pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                                {`${profile?.contact}`}
                              </p>
                            </li>
                            <li className="list-group-item border-0 d-flex">
                              <div className="d-flex align-items-start flex-column justify-content-center">
                                <h6 className="mb-0 text-sm">Branch</h6>
                              </div>
                              <p className="pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                                {`${profile?.branch}`}
                              </p>
                            </li>
                            <li className="list-group-item border-0 d-flex">
                              <div className="d-flex align-items-start flex-column justify-content-center">
                                <h6 className="mb-0 text-sm">Role</h6>
                              </div>
                              <p className="pe-0 ps-0 mb-0 ms-auto w-25 w-md-auto">
                                {`${profile?.role}`}
                              </p>
                            </li>
                            <li className="list-group-item border-0 d-flex">
                              <div className="d-flex align-items-start flex-column justify-content-center">
                                <h6 className="mb-0 text-sm">Status</h6>
                              </div>
                              <p className="pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                                {`${profile?.status}`}
                              </p>
                            </li>
                            <li className="list-group-item border-0 d-flex">
                              <div className="d-flex align-items-start flex-column justify-content-center">
                                <h6 className="mb-0 text-sm">Email</h6>
                              </div>
                              <code className="pe-3 ps-0 mb-0 ms-auto w-40 w-md-auto">
                                {`${profile?.email}`}
                              </code>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="card">
                        <div className="card-header h5">Update Password</div>
                        <div className="card-body p-1 mt-2">
                          <form
                            className="container"
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit(onSave)}
                          >
                            <div className="mb-2 form-floating">
                              <input
                                type="password"
                                className="form-control px-2"
                                id="password"
                                name="password"
                                placeholder=" "
                                autoComplete="current-password"
                                {...register("password", {
                                  required: true,
                                })}
                              />

                              <label htmlFor="password" className="px-2">
                                Old Password
                              </label>
                              {errors?.password?.type === "required" && (
                                <p className="text-small m-0 text-start text-danger help-block">
                                  This Field is Required
                                </p>
                              )}
                            </div>
                            <div className="mb-2 form-floating">
                              <input
                                type="password"
                                className="form-control px-2"
                                id="newpassword"
                                name="newpassword"
                                placeholder=" "
                                autoComplete="new-password"
                                {...register("newpassword", {
                                  required: true,
                                })}
                              />

                              <label htmlFor="newpassword" className="px-2">
                                New Password
                              </label>
                              {errors?.newpassword?.type === "required" && (
                                <p className="text-small m-0 text-start text-danger help-block">
                                  This Field is Required
                                </p>
                              )}
                            </div>
                            <div className="mb-2 form-floating">
                              <input
                                type="password"
                                className="form-control px-2"
                                id="confirmpassword"
                                name="confirmpassword"
                                autoComplete="confirm-password"
                                placeholder=" "
                                {...register("confirmpassword", {
                                  required: true,
                                  validate: (value) => {
                                    return (
                                      value === watch("newpassword") || false
                                    );
                                  },
                                })}
                              />

                              <label htmlFor="confirmpassword" className="px-2">
                                Confirm Password
                              </label>
                              {errors?.confirmpassword?.type === "required" && (
                                <p className="text-small m-0 text-start text-danger help-block">
                                  This Field is Required
                                </p>
                              )}
                              {watch("confirmpassword") !==
                                watch("newpassword") &&
                              getValues("confirmpassword") ? (
                                <p className="text-small text-danger help-block">
                                  Password not match
                                </p>
                              ) : null}
                            </div>
                            <button
                              className="btn btn-danger float-end"
                              disabled={disabled}
                            >
                              {!disabled && "Update Password"}{" "}
                              {disabled && "Please Wait"}
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
      <ConfirmModal
        message="Are you sure you want to delete this branch?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

export default AccountProfile;
