import { useState, useMemo } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
// import Table from "pages/components/common_table";
import ConfirmModal from "pages/components/confirmModal";
import toast, { Toaster } from "react-hot-toast";
import LoanCalculator from "./../components/applications/loan-calculator";
import AddNewApplicationBranch from "./../components/applications/add-loan-branch-applications";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here

const LoanApplicationsBranch = ({ roles }) => {
  // console.log(roles)

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [showModal, setShowModal] = useState(false);
  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState([]);

  const handleConfirm = async () => {
    try {
      const controller = new AbortController();
      const response = await axiosPrivate.delete("/applications/" + id, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      refetch();
    } catch (error) {
      if (!error?.response) {
        console.log(error?.response);
        toast("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", {
            state: { from: location },
            replace: true,
          });
        }
        toast.error(error?.response?.data?.messages, {
          style: {
            background: "#e91e63",
            fontSize: "13px",
            color: "#fff",
          },
        });
      }
    }
  };
  const handleCancel = async () => {
    setId([]);
  };
  const columns = useMemo(
    () => [
      {
        id: "loan_app_id", //access nested data with dot notation
        accessorFn: (row) => (
          <div className="">
            {roles.includes(10000043) && (
              <Link
                to={`/applications/${row.loan_app_id}`}
                className="text-dark font-weight-bold text-xs"
              >
                view
              </Link>
            )}
            {roles.includes(10000046) && (
              <button
                className="btn btn-link text-danger mb-0"
                onClick={() => {
                  setId(row.loan_app_id);
                  setShow(true);
                }}
              >
                <i className="ti ti-trash"></i>
              </button>
            )}
          </div>
        ),
        enableClickToCopy: false,
        size: 50,
        header: "Action",
      },
      {
        accessorKey: "loan_app_date", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Date",
      },
      {
        accessorKey: "loan_app_number", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Loan No.",
      },
      {
        id: "client_account_number", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "A/C No.",
        accessorFn: (row) => `${row.client_account_number}`,
      },
      {
        id: "client_firstname", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "A/C Name.",
        accessorFn: (row) => `${row.client_firstname} ${row.client_lastname}`,
      },
      {
        id: "loan_app_amount", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Amount",
        accessorFn: (row) => parseFloat(row.loan_app_amount).toLocaleString(),
      },
      {
        id: "customer_category_title", //access nested data with dot notation
        accessorFn: (row) => row.customer_category_title,
        enableClickToCopy: true,
        size: 50,
        header: "Group",
      },
      {
        id: "client_identification", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "National ID",
        accessorFn: (row) => `${row.client_identification}`,
      },
      {
        id: "client_contact", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Contact",
        accessorFn: (row) => `${row.client_contact}`,
      },

      {
        id: "loan_app_status", //access nested data with dot notation
        accessorFn: (row) => (
          <div className="">
            {row.loan_app_status === "approved" ? (
              <span className="text-small text-center badge badge-sm bg-success">
                Approved
              </span>
            ) : row.loan_app_status === "pending" ? (
              <span className="text-small text-center badge badge-sm bg-info">
                Pending
              </span>
            ) : (
              <span className="text-small text-center badge badge-sm bg-danger">
                Rejected
              </span>
            )}
          </div>
        ),
        enableClickToCopy: true,
        size: 50,
        header: "Status",
      },
      {
        id: "user_firstname", //access nested data with dot notation
        accessorFn: (row) => `${row.user_firstname} ${row.user_firstname}`,
        enableClickToCopy: true,
        size: 50,
        header: "Officer",
      },
      {
        id: "branch_name", //access nested data with dot notation
        accessorFn: (row) => row.branch_name,
        enableClickToCopy: true,
        size: 50,
        header: "Branch Name",
      },
      {
        id: "loan_app_frequency", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Frequency",
        accessorFn: (row) =>
          row.loan_app_frequency === "monthly"
            ? "Monthly"
            : row.loan_app_frequency === "weekly"
            ? "Weekly"
            : row.loan_app_frequency === "daily"
            ? "Daily"
            : "Yearly",
      },
    ],
    [roles]
  );
  // eslint-disable-next-line
  const [columnFilters, setColumnFilters] = useState([]);
  // eslint-disable-next-line
  const [globalFilter, setGlobalFilter] = useState("");
  // eslint-disable-next-line
  const [sorting, setSorting] = useState([]);
  // eslint-disable-next-line
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const {
    data: { data = [], meta } = {}, // your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, // refetch when columnFilters changes
      globalFilter, // refetch when globalFilter changes
      pagination.pageIndex, // refetch when pagination.pageIndex changes
      pagination.pageSize, // refetch when pagination.pageSize changes
      sorting, // refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = `server-logic/branch/loan-applications`;

      try {
        const response = await axiosPrivate.get(fetchURL, {
          params: {
            start: pagination.pageIndex * pagination.pageSize,
            size: pagination.pageSize,
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? "",
            sorting: JSON.stringify(sorting ?? []),
            branchid: params.id
          },
        });

        // console.log(response.data.data);
        return response.data.data;
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        console.error("Axios request failed", error);
        throw error;
      }
    },
    placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
  });
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const [showLoanModal, setShowLoanModal] = useState(false);
  const handleLoanModalOpen = () => setShowLoanModal(true);
  const handleLoanModalClose = () => setShowLoanModal(false);
  return (
    <>
      <Toaster />
      <div className="container-fluid note-has-grid">
        <div className="card bg-light-info shadow-none position-relative overflow-hidden mt-md-0 mt-5">
          <div className="card-body px-4 py-3 ">
            <div className="row align-items-center">
              <div className="col-12">
                <h5 className="fw-semibold mb-8">Loan Applications Branch</h5>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        className="text-muted text-decoration-none"
                        to="/dashboard"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Loan Applications Branch
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fuild">
          <div className="card">
            <div className="card-header row">
              <div className="col-md-6 col-sm-12">
                <h5 className="fw-semibold mb-8">Loan Applications Branch</h5>
              </div>
              <div className="col-md-3 col-sm-6">
                {roles.includes(10000045) && (
                  <button
                    className="btn btn-primary float-end"
                    onClick={handleOpen}
                  >
                    Loan Calculation
                  </button>
                )}
                <LoanCalculator
                  handleLoanClose={handleClose}
                  showLoanModal={showModal}
                />
              </div>
              <div className="col-md-3 col-sm-6">
                {roles.includes(10000044) && (
                  <button
                    className="btn btn-info float-start"
                    onClick={handleLoanModalOpen}
                  >
                    Add Loan Application
                  </button>
                )}
                <AddNewApplicationBranch
                  handleClose={handleLoanModalClose}
                  showModal={showLoanModal}
                  getLoansAps={refetch}
                />
              </div>
            </div>
            <div className="card-body p-0">
              {/* <Table
                columns={columns}
                data={loans}
                isLoading={isLoading}
                refetch={refetch}
              /> */}
              <MaterialReactTable
                data={data}
                columns={columns}
                enableColumnFilterModes
                enableColumnOrdering
                enableRowSelection
                manualFiltering={true}
                manualPagination={true}
                manualSorting={true}
                enableGrouping
                enableStickyHeader
                className="table align-items-justify table-flush p-0 m-0"
                initialState={{
                  density: "compact",
                  showColumnFilters: false,
                }}
                // paginationDisplayMode={"pages"}
                muiPaginationProps={{
                  shape: "rounded",
                  sx: { fontFamily: "Nunito", fontSize: "10px" },
                }}
                // state={{ isLoading: isLoading }}
                muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                muiTableHeadCellProps={{
                  sx: {
                    fontWeight: "normal",
                    fontSize: "12px",
                    fontFamily: "Nunito",
                  },
                }}
                muiCircularProgressProps={{
                  color: "secondary",
                  thickness: 5,
                  size: 25,
                }}
                muiSkeletonProps={{
                  animation: "pulse",
                  height: 28,
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontWeight: "normal",
                    fontSize: "12px",
                    fontFamily: "Nunito",
                  },
                }}
                muiSearchTextFieldProps={{
                  placeholder: "Search",
                  sx: { fontFamily: "Nunito", fontSize: "12px" },
                }}
                renderTopToolbarCustomActions={({ table }) => (
                  <>
                    <div className="btn-group">
                      <button className="btn btn-sm btn-danger rounded-0">
                        <i className="fa fa-file-pdf"></i>
                      </button>

                      <button
                        className="btn btn-sm btn-success rounded-0"
                        disabled={table.getRowModel().rows.length === 0}
                        //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                        onClick={() =>
                          handleExportRows(table.getRowModel().rows)
                        }
                      >
                        <i className="fa fa-file-excel"></i>
                      </button>
                      <button className="btn btn-sm btn-info rounded-0">
                        <i className="fa fa-print"></i>
                      </button>
                      <button
                        onClick={refetch}
                        className="btn btn-sm btn-dark rounded-0"
                      >
                        <i className="fa"> &#xf021;</i>
                      </button>
                    </div>
                  </>
                )}
                rowCount={meta?.totalRowCount ?? 0}
                state={{
                  columnFilters,
                  globalFilter,
                  isLoading,
                  pagination,
                  showAlertBanner: isError,
                  showProgressBars: isRefetching,
                  sorting,
                }}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
              />
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        message="Are you sure you want to delete this loan application?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

export default LoanApplicationsBranch;
