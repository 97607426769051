import { useLocation, Navigate, Outlet  } from 'react-router-dom';

import useAuth from './useAuth';

const RequiredAuth = ({allowedRoles}) => {
  const roles = JSON.parse(localStorage?.roles);
    // console.log(roles)
    const { auth } = useAuth();
    const location = useLocation();
    return roles?.find((role) => allowedRoles?.includes(role)) ? (
      <Outlet />
    ) : auth?.accesstoken ? (
      <Navigate to="/unathorized" state={{ from: location }} replace />
    ) : (
      <Navigate to="/" state={{ from: location }} replace />
    );
}
 
export default RequiredAuth;