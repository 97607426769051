import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Table from "pages/components/common_table";

const Emails = () => {
  const controller = new AbortController();

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getEmail = async () => {
    try {
      const response = await axiosPrivate.get("/email/general", {
        signal: controller.signal,
      });
      setEmails(response.data.data.emails);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = async () => {
    setIsLoading(true);
    await getEmail();
  };

  const columns = useMemo(
    () => [
      {
        id: "email", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Email",
        accessorFn: (row) => row.email,
      },
      {
        accessorKey: "subject", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Subject",
      },
      {
        accessorKey: "body", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Body",
      },
      {
        accessorKey: "timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Timestamp",
      },
    ],
    []
  );



  return (
    <>

          <div className="container-fluid note-has-grid">
            <div className="card bg-light-info shadow-none position-relative overflow-hidden mt-md-0 mt-5">
              <div className="card-body px-4 py-3 ">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h5 className="fw-semibold mb-8">Emails</h5>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            className="text-muted text-decoration-none"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          Emails
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fuild">
              <div className="card">
                <div className="card-header row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="fw-semibold mb-8">Emails</h5>
                  </div>
                  
                </div>
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-md-12">
                      <Table
                        columns={columns}
                        data={emails}
                        isLoading={isLoading}
                        refetch={refetch}
                        // height={"150px"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
    </>
  );
};

export default Emails;
