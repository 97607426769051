import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend,
  defaults,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  (defaults.font.family = "Nunito")
);
const MonthlyPayments = ({ transactions }) => {
  const [chartOptions, setChartOptions] = useState({});
  const [chartData, setChartData] = useState({ datasets: [] });
  useEffect(() => {
    const groupedData = groupTransactionsByMonth(transactions);

    // Extract months and total amounts
    const months = Object.keys(groupedData);
    const totalAmounts = Object.values(groupedData);

    // Prepare chart data
    const data = {
      labels: months,
      datasets: [
        {
          label: "Amount",
          data: totalAmounts,
          tension: 0.9,
          fill: false,
          borderColor: ["rgb(2,15,70)"],
          backgroundColor: ["rgba(2,15,70,0.8)"], // Area under the line
          pointBackgroundColor: ["rgba(2,15,70,0.9)"], // Point color
          pointRadius: 5, // Point size
        },
      ],
    };

    setChartData(data);
    const options = {
      layout: {
        padding: 0, // Remove padding around the chart
      },
      scales: {
        y: {
          beginAtZero: false,
          title: {
            display: false,
            text: "Total Amount",
            font: {
              size: 12,
              weight: "bold",
            },
          },
          ticks: {
            font: {
              size: 12,
            },
          },
          grid: {
            display: false, // Remove y-axis grid lines
            borderColor: "transparent",
          },
          stroke: {
            width: 4,
            curve: "smooth",
          },
        },
        x: {
          title: {
            display: true,
            text: "Months",
            font: {
              size: 12,
              weight: "bold",
            },
          },
          ticks: {
            font: {
              size: 12,
            },
          },
          grid: {
            display: false, // Remove x-axis grid lines
          },
        },
      },
      plugins: {
        legend: {
          display: false, // Hide legend
        },
        tooltip: {
          enabled: true,
          titleFont: {
            size: 14,
            weight: "bold",
          },
          bodyFont: {
            size: 12,
          },
        },
      },
    };
    setChartOptions(options);
  }, [transactions]);

  const groupTransactionsByMonth = (transactions) => {
    const groupedData = {};
    transactions?.forEach((transaction) => {
      const date = new Date(transaction.date);
      const monthYear = date.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      if (groupedData[monthYear]) {
        groupedData[monthYear] += transaction.amount;
      } else {
        groupedData[monthYear] = transaction.amount;
      }
    });
    return groupedData;
  };

  return (
    <>
      <Line data={chartData} options={chartOptions} />
    </>
  );
};

export default MonthlyPayments;
