import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Table from "pages/components/common_table";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import toast, {Toaster} from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
const AccountVotes = () => {
 
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const navigate = useNavigate();
  const progress = new ProgressBar();



  const controller = new AbortController();
  const [groups, setGroups] = useState([
    { title: "Assets" },
    { title: "Liabilities" },
    { title: "Equity" },
    { title: "Income" },
    { title: "Expenses" },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const columns_groups = useMemo(
    () => [
      {
        accessorKey: "title",
        enableClickToCopy: true,
        header: "Vote Group Title",
      },
      {
        id: "action", //access nested data with dot notation
        enableClickToCopy: false,
        header: "Action",
        accessorFn: row => "No action",
      },
    ],
    []
  );
  const columns_sub_groups = useMemo(
    () => [
      {
        accessorKey: "title",
        enableClickToCopy: true,
        size: 50,
        header: "Title",
      },
      {
        accessorKey: "code",
        size: 50,
        enableClickToCopy: true,
        header: "Code",
      },
      {
        accessorKey: "account_group",
        enableClickToCopy: true,
        size: 50,
        header: "Vote Group",
      },
      {
        id: "action", //access nested data with dot notation
        enableClickToCopy: false,
        header: "Action",
        size: 50,
        accessorFn: (row) => (
          <>
            <button className="btn btn-sm btn-info mx-1">update</button>
            <button className="btn btn-sm btn-danger">delete</button>
          </>
        ),
      },
    ],
    []
  );
  const columns_accounts = useMemo(
    () => [
      {
        accessorKey: "title",
        enableClickToCopy: true,
        size: 50,
        header: "Title",
      },
      {
        accessorKey: "code",
        size: 50,
        enableClickToCopy: true,
        header: "Code",
      },
      {
        accessorKey: "account_sub_group",
        enableClickToCopy: true,
        size: 50,
        header: "Vote Sub Group",
      },
      {
        id: "action", //access nested data with dot notation
        enableClickToCopy: false,
        header: "Action",
        size: 50,
        accessorFn: (row) => (
          <>
            <button className="btn btn-sm btn-info mx-1">update</button>
            <button className="btn btn-sm btn-danger">delete</button>
          </>
        ),
      },
    ],
    []
  );
  const refetch = () => {
        setGroups([
          { title: "Assets" },
          { title: "Liabilities" },
          { title: "Equity" },
          { title: "Income" },
          { title: "Expenses" },
        ]);
        setIsLoading(false);
  }
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const {
    handleSubmit: handleSubmit1,
    register: register1,
    reset: reset1,
    formState: { errors: errors1  },
  } = useForm();
  
    const {
      handleSubmit: handleSubmit2,
      register: register2,
      reset: reset2,
      formState: { errors: errors2 },
    } = useForm();
  const [accounts, setAccounts] = useState([]);
  const [defaults, setDefaults] = useState([]);
  const [subAccounts, setSubAccounts] = useState([]);
  const [charts, setCharts] = useState([]);
  const getAccounts = async() => {
    try {
      const response = await axiosPrivate.get(`/settings/accounts`, {
        signal: controller.signal,
      });
      setAccounts(response?.data?.data?.accounts);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  }
   const  getSubAccounts = async () => {
     try {
       const response = await axiosPrivate.get(`/settings/sub-accounts`, {
         signal: controller.signal,
       });
       setSubAccounts(response?.data?.data?.accounts);
       setIsLoading(false);
     } catch (error) {
       // console.log(error)
       if (error?.response?.status === 401) {
         navigate("/", { state: { from: location }, replace: true });
       }
     }
   };
  const getDefaults = async() => {
    try {
      const response = await axiosPrivate.get(`/settings/votes/defaults`, {
        signal: controller.signal,
      });
      setDefaults(response?.data?.data?.defaults);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  }
   const getCharts = async () => {
     try {
       const response = await axiosPrivate.get(`/settings/charts/accounts`, {
         signal: controller.signal,
       });
       setCharts(response?.data?.data?.chart_of_accounts);
       setIsLoading(false);
     } catch (error) {
       // console.log(error)
       if (error?.response?.status === 401) {
         navigate("/", { state: { from: location }, replace: true });
       }
     }
   };
  useEffect( ()=>{
    getAccounts();
    getDefaults();
    getSubAccounts();
    getCharts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  const onSave = async(data) => {
   try {
     progress.start();
     setDisabled(true);
     const response = await axiosPrivate.patch(
       "/settings/votes/defaults",
       data,
       {
         signal: controller.signal,
       }
     );
     toast.success(response?.data?.messages);
     progress.finish();
     setDisabled(false);
     reset();
   } catch (error) {
     if (!error?.response) {
       toast.error("No server response");
       progress.finish();
       setDisabled(false);
     } else {
       progress.finish();
       setDisabled(false);
       if (error?.response?.status === 401) {
         navigate("/", { state: { from: location }, replace: true });
       }
       toast.error(error?.response?.data?.messages, {
         style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
       });
     }
   }
  }
  const onSaveSubAccount = async (data) => {
    try {
      // console.log(data);
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.post(
        "/settings/sub-accounts",
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      // console.log(response?.data);
      progress.finish();
      setDisabled(false);
      getSubAccounts();
      reset1();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const onSaveAccount = async (data) => {
    try {
      // console.log(data);
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.post("/settings/accounts", data, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      // console.log(response?.data);
      progress.finish();
      setDisabled(false);
      getAccounts();
      reset2();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  return (
    <>
      <Toaster />
      <>

            <div className="container-fluid note-has-grid">
              <div className="card bg-light-danger shadow-none position-relative overflow-hidden mt-md-0 mt-5">
                <div className="card-body px-4 py-3 ">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <h5 className="fw-semibold mb-8">
                        Votes (System Accounts)
                      </h5>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link
                              className="text-muted text-decoration-none"
                              to="/dashboard"
                            >
                              Dashboard
                            </Link>
                          </li>
                          <li className="breadcrumb-item" aria-current="page">
                            Votes
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fuild">
                <div className="card">
                  <div className="card-body">
                    <ul
                      className="nav nav-pills user-profile-tab justify-content-center mt-2 bg-white rounded-2"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link position-relative rounded-0 active d-flex align-items-center justify-content-center bg-transparent fs-3 py-6"
                          id="pills-groups-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-groups"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="true"
                        >
                          <i className="ti ti-box-multiple-1 me-2 fs-6"></i>
                          <span className="d-none d-md-block">Vote Groups</span>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-6"
                          id="pills-sub-groups-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-sub-groups"
                          type="button"
                          role="tab"
                          aria-controls="pills-sub-groups"
                          aria-selected="false"
                        >
                          <i className="ti ti-box-multiple-2 me-2 fs-6"></i>
                          <span className="d-none d-md-block">
                            Vote Sub Groups
                          </span>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-6"
                          id="pills-votes-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-votes"
                          type="button"
                          role="tab"
                          aria-controls="pills-votes"
                          aria-selected="false"
                        >
                          <i className="ti ti-box-multiple-3 me-2 fs-6"></i>
                          <span className="d-none d-md-block">Votes</span>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-6"
                          id="pills-vote-chart-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-vote-chart"
                          type="button"
                          role="tab"
                          aria-controls="pills-vote-chart"
                          aria-selected="false"
                        >
                          <i className="ti ti-box-multiple-4 me-2 fs-6"></i>
                          <span className="d-none d-md-block">Vote Charts</span>
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane  show active"
                        id="pills-groups"
                        role="tabpanel"
                        aria-labelledby="pills-groups-tab"
                        tabIndex="0"
                      >
                        <div className="row">
                          <div className="col-6">
                            <Table
                              data={groups}
                              columns={columns_groups}
                              isLoading={isLoading}
                              refetch={refetch}
                            />
                          </div>
                          <div className="col-6">
                            <div className="card">
                              <div className="card-header">Vote Defaults</div>
                              <div className="card-body p-2">
                                <form
                                  className="row"
                                  noValidate
                                  onSubmit={handleSubmit(onSave)}
                                >
                                  <div className="col-md-6">
                                    <div className="mb-1 form-floating">
                                      <select
                                        type="text"
                                        className="form-control px-2"
                                        id="loans_account"
                                        name="loans_account"
                                        {...register("loans_account", {
                                          required: true,
                                        })}
                                      >
                                        <option
                                          value={defaults?.loans_account?.id}
                                        >
                                          {defaults?.loans_account?.title}
                                        </option>
                                        {accounts?.map((account) => (
                                          <option
                                            value={account.id}
                                            key={account.id}
                                          >
                                            {account.title}
                                          </option>
                                        ))}
                                      </select>
                                      <label
                                        htmlFor="loans_account"
                                        className="px-2"
                                      >
                                        Default Loans Vote
                                      </label>
                                      {errors?.loans_account?.type ===
                                        "required" && (
                                        <p className="text-small m-0 text-start text-danger help-block">
                                          This Field is Required
                                        </p>
                                      )}
                                    </div>
                                    <div className="mb-1 form-floating">
                                      <select
                                        type="text"
                                        className="form-control px-2"
                                        id="interest_account"
                                        name="interest_account"
                                        {...register("interest_account", {
                                          required: true,
                                        })}
                                      >
                                        <option
                                          value={defaults?.interest_account?.id}
                                        >
                                          {defaults?.interest_account?.title}
                                        </option>
                                        {accounts?.map((account) => (
                                          <option
                                            value={account.id}
                                            key={account.id}
                                          >
                                            {account.title}
                                          </option>
                                        ))}
                                      </select>
                                      <label
                                        htmlFor="interest_account"
                                        className="px-2"
                                      >
                                        Default Interest Vote
                                      </label>
                                      {errors?.interest_account?.type ===
                                        "required" && (
                                        <p className="text-small m-0 text-start text-danger help-block">
                                          This Field is Required
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-1 form-floating">
                                      <select
                                        type="text"
                                        className="form-control px-2"
                                        id="penalty_account"
                                        name="penalty_account"
                                        {...register("penalty_account", {
                                          required: true,
                                        })}
                                      >
                                        <option
                                          value={defaults?.penalty_account?.id}
                                        >
                                          {defaults?.penalty_account?.title}
                                        </option>
                                        {accounts?.map((account) => (
                                          <option
                                            value={account.id}
                                            key={account.id}
                                          >
                                            {account.title}
                                          </option>
                                        ))}
                                      </select>
                                      <label
                                        htmlFor="penalty_account"
                                        className="px-2"
                                      >
                                        Default Penalty Vote
                                      </label>
                                      {errors?.penalty_account?.type ===
                                        "required" && (
                                        <p className="text-small m-0 text-start text-danger help-block">
                                          This Field is Required
                                        </p>
                                      )}
                                    </div>
                                    <div className="mb-1 form-floating">
                                      <select
                                        type="text"
                                        className="form-control px-2"
                                        id="extra_charges_account"
                                        name="extra_charges_account"
                                        {...register("extra_charges", {
                                          required: true,
                                        })}
                                      >
                                        <option
                                          value={
                                            defaults?.extra_charges_account?.id
                                          }
                                        >
                                          {
                                            defaults?.extra_charges_account
                                              ?.title
                                          }
                                        </option>
                                        {accounts?.map((account) => (
                                          <option
                                            value={account.id}
                                            key={account.id}
                                          >
                                            {account.title}
                                          </option>
                                        ))}
                                      </select>
                                      <label
                                        htmlFor="extra_charges_account"
                                        className="px-2"
                                      >
                                        Default Extra Charges Vote
                                      </label>
                                      {errors?.extra_charges?.type ===
                                        "required" && (
                                        <p className="text-small m-0 text-start text-danger help-block">
                                          This Field is Required
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="container pt-2">
                                    <button
                                      className="btn btn-outline-primary float-end"
                                      disabled={disabled}
                                    >
                                      {!disabled && "Update"}{" "}
                                      {disabled && "Please Wait"}
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="pills-sub-groups"
                        role="tabpanel"
                        aria-labelledby="pills-sub-groups-tab"
                        tabIndex="0"
                      >
                        <div className="row">
                          <div className="col-8">
                            <Table
                              data={subAccounts}
                              columns={columns_sub_groups}
                              isLoading={isLoading}
                              refetch={refetch}
                            />
                          </div>
                          <div className="col-4">
                            <div className="card">
                              <div className="card-header">
                                Add Sub Vote Group
                              </div>
                              <div className="card-body p-2">
                                <form
                                  className="container"
                                  noValidate
                                  onSubmit={handleSubmit1(onSaveSubAccount)}
                                >
                                  <div className="mb-1 form-floating">
                                    <select
                                      type="text"
                                      className="form-control px-4"
                                      id="group"
                                      name="group"
                                      {...register1("group", {
                                        required: true,
                                      })}
                                    >
                                      <option value={"Assets"}>Assets</option>
                                      <option value={"Liabilities"}>
                                        Liabilities
                                      </option>
                                      <option value={"Income"}>Income</option>
                                      <option value={"Expenses"}>
                                        Expenses
                                      </option>
                                      <option value={"Equity"}>Equity</option>
                                    </select>
                                    <label htmlFor="group" className="px-2">
                                      Select Account Group
                                    </label>
                                    {errors1?.group?.type === "required" && (
                                      <p className="text-small m-0 text-start text-danger help-block">
                                        This Field is Required
                                      </p>
                                    )}
                                  </div>
                                  <div className="mb-1 form-floating">
                                    <input
                                      type="text"
                                      className="form-control px-2"
                                      id="title"
                                      name="title"
                                      {...register1("title", {
                                        required: true,
                                      })}
                                    />

                                    <label htmlFor="title" className="px-2">
                                      Vote Title
                                    </label>
                                    {errors1?.title?.type === "required" && (
                                      <p className="text-small m-0 text-start text-danger help-block">
                                        This Field is Required
                                      </p>
                                    )}
                                  </div>

                                  <button
                                    className="btn btn-outline-primary float-end"
                                    disabled={disabled}
                                  >
                                    {!disabled && "Save"}{" "}
                                    {disabled && "Please Wait"}
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-votes"
                        role="tabpanel"
                        aria-labelledby="pills-votes-tab"
                        tabIndex="0"
                      >
                        <div className="row">
                          <div className="col-8">
                            <Table
                              data={accounts}
                              columns={columns_accounts}
                              isLoading={isLoading}
                              refetch={refetch}
                            />
                          </div>
                          <div className="col-4">
                            <div className="card">
                              <div className="card-header">Add New Vote</div>
                              <div className="card-body p-2">
                                <form
                                  className="container"
                                  noValidate
                                  onSubmit={handleSubmit2(onSaveAccount)}
                                >
                                  <div className="mb-1 form-floating">
                                    <select
                                      type="text"
                                      className="form-control px-4"
                                      id="accountid"
                                      name="accountid"
                                      {...register2("accountid", {
                                        required: true,
                                      })}
                                    >
                                      {subAccounts?.map((account) => (
                                        <option value={account?.id} key={account?.id}>
                                          {account?.title}
                                        </option>
                                      ))}
                                    </select>
                                    <label htmlFor="accountid" className="px-2">
                                      Select Sub Account Group
                                    </label>
                                    {errors2?.accountid?.type ===
                                      "required" && (
                                      <p className="text-small m-0 text-start text-danger help-block">
                                        This Field is Required
                                      </p>
                                    )}
                                  </div>
                                  <div className="mb-1 form-floating">
                                    <input
                                      type="text"
                                      className="form-control px-2"
                                      id="title"
                                      name="title"
                                      {...register2("title", {
                                        required: true,
                                      })}
                                    />

                                    <label htmlFor="title" className="px-2">
                                      Vote Title
                                    </label>
                                    {errors2?.title?.type === "required" && (
                                      <p className="text-small m-0 text-start text-danger help-block">
                                        This Field is Required
                                      </p>
                                    )}
                                  </div>

                                  <button
                                    className="btn btn-outline-primary float-end"
                                    disabled={disabled}
                                  >
                                    {!disabled && "Save"}{" "}
                                    {disabled && "Please Wait"}
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-vote-chart"
                        role="tabpanel"
                        aria-labelledby="pills-vote-chart-tab"
                        tabIndex="0"
                      >
                        <div className="container row p-4">
                          <table className="table table-dark table-bordered table-striped table-hover">
                            <thead>
                              <tr>
                                <th>Vote Group</th>
                                <th>Vote Group Code</th>
                                <th>Vote Sub Group</th>
                                <th>Vote Sub Group Code</th>
                                <th>Vote</th>
                                <th>Vote Code</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="table-active">
                                <td>Assets</td>
                                <td>A-001</td>
                                <td colSpan={4}></td>
                              </tr>

                              {charts
                                ?.filter(
                                  (chart) => chart.account_type === "Assets"
                                )
                                ?.map((chart, i) => (
                                  <React.Fragment
                                    key={`${i}-assets-${chart.id}`}
                                  >
                                    <tr>
                                      <td colSpan={2}></td>
                                      <td>{chart?.account_name}</td>
                                      <td>{chart?.account_code}</td>
                                      <td colSpan={2}></td>
                                    </tr>
                                    {chart?.accounts?.map((vote, i) => (
                                      <tr
                                        key={`${i}-assets-${chart.id}-${vote.id}`}
                                      >
                                        <td colSpan={4}></td>
                                        <td>{vote?.title}</td>
                                        <td>{vote?.code}</td>
                                      </tr>
                                    ))}
                                  </React.Fragment>
                                ))}
                              <tr className="table-active">
                                <td>Liabilities</td>
                                <td>L-001</td>
                                <td colSpan={4}></td>
                              </tr>

                              {charts
                                ?.filter(
                                  (chart) =>
                                    chart.account_type === "Liabilities"
                                )
                                ?.map((chart, i) => (
                                  <React.Fragment
                                    key={`${i}-liabilities-${chart.id}`}
                                  >
                                    <tr>
                                      <td colSpan={2}></td>
                                      <td>{chart?.account_name}</td>
                                      <td>{chart?.account_code}</td>
                                      <td colSpan={2}></td>
                                    </tr>
                                    {chart?.accounts?.map((vote, i) => (
                                      <tr
                                        key={`${i}-liabilities-${chart.id}-${vote.id}`}
                                      >
                                        <td colSpan={4}></td>
                                        <td>{vote?.title}</td>
                                        <td>{vote?.code}</td>
                                      </tr>
                                    ))}
                                  </React.Fragment>
                                ))}
                              <tr className="table-active">
                                <td>Income</td>
                                <td>I-001</td>
                                <td colSpan={4}></td>
                              </tr>

                              {charts
                                ?.filter(
                                  (chart) => chart.account_type === "Income"
                                )
                                ?.map((chart, i) => (
                                  <React.Fragment
                                    key={`${i}-income-${chart.id}`}
                                  >
                                    <tr>
                                      <td colSpan={2}></td>
                                      <td>{chart?.account_name}</td>
                                      <td>{chart?.account_code}</td>
                                      <td colSpan={2}></td>
                                    </tr>
                                    {chart?.accounts?.map((vote, i) => (
                                      <tr
                                        key={`${i}-income-${chart.id}-${vote.id}`}
                                      >
                                        <td colSpan={4}></td>
                                        <td>{vote?.title}</td>
                                        <td>{vote?.code}</td>
                                      </tr>
                                    ))}
                                  </React.Fragment>
                                ))}

                              <tr className="table-active">
                                <td>Expenses</td>
                                <td>E-001</td>
                                <td colSpan={4}></td>
                              </tr>

                              {charts
                                ?.filter(
                                  (chart) => chart.account_type === "Expenses"
                                )
                                ?.map((chart, i) => (
                                  <React.Fragment
                                    key={`${i}-expenses-${chart.id}`}
                                  >
                                    <tr>
                                      <td colSpan={2}></td>
                                      <td>{chart?.account_name}</td>
                                      <td>{chart?.account_code}</td>
                                      <td colSpan={2}></td>
                                    </tr>
                                    {chart?.accounts?.map((vote, i) => (
                                      <tr
                                        key={`${i}-expenses-${chart.id}-${vote.id}`}
                                      >
                                        <td colSpan={4}></td>
                                        <td>{vote?.title}</td>
                                        <td>{vote?.code}</td>
                                      </tr>
                                    ))}
                                  </React.Fragment>
                                ))}
                              <tr className="table-active">
                                <td>Equity</td>
                                <td>E-002</td>
                                <td colSpan={4}></td>
                              </tr>

                              {charts
                                ?.filter(
                                  (chart) => chart.account_type === "Equity"
                                )
                                ?.map((chart, i) => (
                                  <React.Fragment
                                    key={`${i}-equity-${chart.id}`}
                                  >
                                    <tr>
                                      <td colSpan={2}></td>
                                      <td>{chart?.account_name}</td>
                                      <td>{chart?.account_code}</td>
                                      <td colSpan={2}></td>
                                    </tr>
                                    {chart?.accounts?.map((vote, i) => (
                                      <tr
                                        key={`${i}-equity-${chart.id}-${vote.id}`}
                                      >
                                        <td colSpan={4}></td>
                                        <td>{vote?.title}</td>
                                        <td>{vote?.code}</td>
                                      </tr>
                                    ))}
                                  </React.Fragment>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
      </>
    </>
  );
};

export default AccountVotes;
