import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
const AddDocuments = ({
  getClient,
  showModalDocument,
  handleProfileDocumentClose,
}) => {
    const [disabled, setDisabled] = useState(false);
    const params = useParams();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
      const progress = new ProgressBar({ size: 5, color: "green" });

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm();

      const onSave = async (data) => {
        const formData = new FormData();
        formData.append("file", data.file[0]);
        formData.append("attributes", JSON.stringify({ clientid: params.id, title: data.title }));
        try {
          progress.start();
          setDisabled(true);
          const controller = new AbortController();
          const response = await axiosPrivate.post(
            "/clients/documents",
            formData,
            {
              headers: {
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
              },
            },
            {
              signal: controller.signal,
            }
          );
          toast.success(response?.data?.messages);
          progress.finish();
          setDisabled(false);
          reset();
          getClient();
          handleProfileDocumentClose();
        } catch (error) {
          if (!error?.response) {
            toast.error("No server response");
            progress.finish();
            setDisabled(false);
          } else {
            console.log(error?.response);
            progress.finish();
            setDisabled(false);
            if (error?.response?.status === 401) {
              navigate("/", { state: { from: location }, replace: true });
            }
            toast.error(error?.response?.data?.messages, {
              style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
            });
          }
        }
      };
  return (
    <>
      <Toaster />
      <Modal
        show={showModalDocument}
        backdrop="static"
        keyboard={false}
        onHide={handleProfileDocumentClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Add Document
          </Modal.Title>
          <button
            onClick={handleProfileDocumentClose}
            className="btn-close btn-danger"
          ></button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="container row">
              <div className="form-floating mb-1 col-md-12">
                <input
                  className="form-control"
                  type="title"
                  id="title"
                  placeholder=""
                  {...register("title", { required: true })}
                />
                <label htmlFor="title">Document Title</label>
                {errors?.title?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter document title
                  </p>
                )}
              </div>
              <div className="form-group col-md-12 input-container">
                <div className="mb-3">
                  <label htmlFor="file" className="form-label px-1">
                    Attach Document
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="file"
                    {...register("file", { required: true })}
                  />
                </div>
                {errors?.file?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please select document
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleProfileDocumentClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Save"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
 
export default AddDocuments;