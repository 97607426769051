import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
const SendClientSMS = ({
  getSMS,
  showModalSms,
  handleSmsDocumentClose,
  contact,
}) => {
  const [disabled, setDisabled] = useState(false);
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "blue" });

  const controller = new AbortController();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.post(
        `sms/client/${params.id}`,
        JSON.stringify({ contact: contact, message: data.message }),
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleSmsDocumentClose();
      getSMS();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: {
            background: "#e91e63",
            fontSize: "13px",
            color: "#fff",
          },
        });
      }
    }
  };
  return (
    <>
      <Toaster />
      <Modal
        show={showModalSms}
        backdrop="static"
        keyboard={false}
        onHide={handleSmsDocumentClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Send Client Message
          </Modal.Title>
          <button
            onClick={handleSmsDocumentClose}
            className="btn-close btn-danger"
          ></button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="container-fuild">
              <div className="form-floating mb-1">
                <textarea
                  className="form-control"
                  type="message"
                  id="message"
                  placeholder=""
                  style={{ height: "100px" }}
                  {...register("message", { required: true })}
                ></textarea>
                <label htmlFor="message" className="px-4">Message</label>
                {errors?.message?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter message
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleSmsDocumentClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Send SMS"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default SendClientSMS;
