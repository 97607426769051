import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Table from "pages/components/common_table";

const WritternOff = ({ roles }) => {
  const controller = new AbortController();

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [loans, setLoans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getLoansAps = async () => {
    try {
      const response = await axiosPrivate.get("/applications/approved", {
        signal: controller.signal,
      });
      setLoans(response.data.data.loanApps);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getLoansAps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = async () => {
    setIsLoading(true);
    await getLoansAps();
  };

  const columns = useMemo(
    () => [
      {
        header: "Action",
        id: "id",
        size: 50,
        accessorFn: (row) => (
          <>
            {roles.includes(10000043) && (
              <Link
                to={`/loans/${row.id}`}
                className="text-dark font-weight-bold text-xs"
              >
                view
              </Link>
            )}
          </>
        ),
        enableClickToCopy: false,
      },
      {
        header: "Approved Date",
        accessorKey: "date",
        size: 50,
        enableClickToCopy: true,
      },
      {
        header: "Loan N0.",
        accessorKey: "number",
        enableClickToCopy: true,
      },
      {
        header: "Group",
        size: 50,
        id: "group",
        accessorFn: (row) => row.client.group,
        enableClickToCopy: true,
      },
      {
        header: "A/C N0.",
        id: "account",
        size: 50,
        accessorFn: (row) => `${row.client.account}`,

        enableClickToCopy: true,
      },
      {
        header: "National Id",
        id: "nin",
        size: 50,
        accessorFn: (row) => `${row.client.identification}`,

        enableClickToCopy: true,
      },
      {
        header: "Contact",
        id: "contact",
        size: 50,
        accessorFn: (row) => `${row.client.contact}`,

        enableClickToCopy: true,
      },
      {
        header: "Client",
        id: "client",
        size: 50,
        accessorFn: (row) => `${row.client.firstname} ${row.client.lastname}`,

        enableClickToCopy: true,
      },
      {
        header: "Officer",
        id: "officer",
        size: 50,
        accessorFn: (row) => `${row.officer.firstname} ${row.officer.lastname}`,
        enableClickToCopy: true,
      },
      {
        header: "Cycle",
        id: "frequency",
        size: 50,
        enableClickToCopy: true,
        accessorFn: (row) =>
          row.frequency === "monthly"
            ? "Monthly"
            : row.frequency === "weekly"
            ? "Weekly"
            : row.frequency === "daily"
            ? "Daily"
            : "Yearly",
      },
      {
        header: "Principal Taken",
        id: "amount",
        size: 50,
        enableClickToCopy: true,
        accessorFn: (row) => row.amount.toLocaleString(),
      },

      {
        header: "Status",
        id: "status",
        size: 50,
        accessorFn: (row) => (
          <div className="align-middle float-end text-sm">
            {row.status === "ongoing" ? (
              <span className="text-small text-center badge badge-sm bg-success">
                OnGoing
              </span>
            ) : row.status === "closed" ? (
              <span className="text-small text-center badge badge-sm bg-info">
                Completed
              </span>
            ) : row.status === "stopped" ? (
              <span className="text-small text-center badge badge-sm bg-primary">
                Stopped
              </span>
            ) : row.status === "flagoff" ? (
              <span className="text-small text-center badge badge-sm bg-dark">
                Flagged Off
              </span>
            ) : (
              <span className="text-small text-center badge badge-sm bg-danger">
                Overdue
              </span>
            )}
          </div>
        ),
        enableClickToCopy: true,
      },
    ],
    [roles]
  );

  return (
    <>
      <div className="container-fluid note-has-grid">
        <div className="card bg-light-info shadow-none position-relative overflow-hidden mt-md-0 mt-5">
          <div className="card-body px-4 py-3 ">
            <div className="row align-items-center">
              <div className="col-12">
                <h5 className="fw-semibold mb-8">Writtern Off Loans General</h5>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        className="text-muted text-decoration-none"
                        to="/dashboard"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Writtern Off Loans General
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fuild">
          <div className="card">
            <div className="card-header row">
              <div className="col-md-6 col-sm-12">
                <h5 className="fw-semibold mb-8">Writtern Off Loans General</h5>
              </div>
            </div>
            <div className="card-body p-0">
              <Table
                columns={columns}
                data={loans?.filter((loan) => loan.status === "flagoff")}
                isLoading={isLoading}
                refetch={refetch}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WritternOff;
