import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import ProgressBar from "@badrap/bar-of-progress";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
const AddDocument = ({ showModal, handleClose, getLoan }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const params  = useParams ();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const onSave = async (data) => {
    // console.log(data)
    const formData = new FormData();
    formData.append("file", data.file[0]);
    formData.append(
      "attributes",
      JSON.stringify({ title: data.name, loanid: data.loanid })
    );
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "application/documents",
        formData,
        {
          headers: {
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        },
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      getLoan();
      handleClose();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Add New Document{" "}
          </Modal.Title>
          <button onClick={handleClose} className="btn-close btn-danger">
            <i className="material-icons opacity-10"></i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-1 form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="title"
                      placeholder=""
                      {...register("name", { required: true })}
                    />
                    <label htmlFor="title">Document Title</label>
                    {errors?.title?.type === "required" && (
                      <p className="text-small m-0 text-start text-danger help-block">
                        This Field is Required
                      </p>
                    )}

                    <input
                      value={params?.id}
                      {...register("loanid")}
                      type="hidden"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group col-md-12 input-container">
                    <div className="mb-3">
                      <label htmlFor="file" className="form-label px-1">
                        Attach Document
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="file"
                        {...register("file", { required: true })}
                      />
                    </div>
                    {errors?.file?.type === "required" && (
                      <p className="text-small m-0 px-1 text-start text-danger help-block">
                        Please select document
                      </p>
                    )}
                  </div>
                </div>
              </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-warning"
            >
              {!disabled && "Upload"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddDocument;
