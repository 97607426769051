import Table from "pages/components/common_table";
import { useMemo, useState } from "react";
import ConfirmModal from "pages/components/confirmModal";
const LoanColletralTable = ({ data, refetch, isLoading,roles }) => {
  const [show, setShow] = useState(false);
  const handleConfirm = () => setShow(false);
  const handleCancel = () => setShow(false);
  const columns_colletral = useMemo(
    () => [
      {
        accessorKey: "name", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Title",
      },
      {
        accessorKey: "value", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Estimated Value",
      },
      {
        accessorKey: "description", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "More Notes",
      },
      {
        id: "id", //access nested data with dot notation
        accessorFn: (row) => (
          <div className="">
             {roles.includes(10000059) && (
            <button
              className="btn btn-link text-danger"
              onClick={() => {
                setShow(true);
              }}
            >
              <i className="ti ti-trash"></i>
            </button>
             )}
          </div>
        ),
        enableClickToCopy: false,
        size: 50,
        header: "Action",
      },
    ],
    [roles]
  );
  return (
    <>
      <div className="border-none">
        <div >
          <h6 className="fw-semibold p-4 text-center">Colletral</h6>
          <Table
            columns={columns_colletral}
            data={data}
            refetch={refetch}
            isLoading={isLoading}
          />
        </div>
      </div>
      <ConfirmModal
        message="Are you sure you want to delete colletral?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

export default LoanColletralTable;
