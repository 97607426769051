import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Table from "pages/components/common_table";
const Transactions = () => {
  const controller = new AbortController();


  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getTransactions = async () => {
    try {
      const response = await axiosPrivate.get("/transactions", {
        signal: controller.signal,
      });
      setTransactions(response.data.data.transactions);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = async () => {
    setIsLoading(true);
    await getTransactions();
  };

  const columns = useMemo(
    () => [
      {
        id: "amount", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Amount",
        accessorFn: (row) => row.amount.toLocaleString(),
      },
      {
        accessorKey: "transId", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Transaction Id",
      },
      {
        id: "status", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Status",
        accessorFn: (row) => (row.status === "payment" ? "Payment" : "Penalty"),
      },
      {
        accessorKey: "client_name", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Client",
      },

      {
        accessorKey: "date", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Timestamp",
      },
      {
        id: "user", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "User",
        accessorFn: (row) => `${row.user.firstname} ${row.user.lastname}`,
      },
      {
        id: "action", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Action",
        accessorFn: (row) => <div>No action</div>,
      },
    ],
    []
  );

  return (
    <>
   
          <div className="container-fluid note-has-grid">
            <div className="card bg-light-success shadow-none position-relative overflow-hidden mt-md-0 mt-5">
              <div className="card-body px-4 py-3 ">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h5 className="fw-semibold mb-8">Transactions</h5>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            className="text-muted text-decoration-none"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          Transactions
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fuild">
              <div className="card">
                <div className="card-header row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="fw-semibold mb-8">Transactions</h5>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-md-12">
                      <Table
                        columns={columns}
                        data={transactions}
                        isLoading={isLoading}
                        refetch={refetch}
                        // height={"150px"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
    </>
  );
};

export default Transactions;
