import Footer from "pages/components/footer";
import Navbar from "pages/components/navbar";
import Sidebar from "pages/components/sidebar";
import { useState } from "react";
import { Outlet } from "react-router-dom";
const AuthLayout = ({children}) => {
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleSidebarToggle = () => {
    setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen);
  }
  const roles = JSON.parse(localStorage?.roles);
    return (
      <>
        <div
          className="page-wrapper"
          id="main-wrapper"
          data-theme="blue_theme"
          data-layout="vertical"
          data-sidebartype="full"
          data-sidebar-position="fixed"
          data-header-position="fixed"
        >
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            handleSidebarToggle={handleSidebarToggle}
            roles={roles}
          />

          <div className="body-wrapper">
            <Navbar handleSidebarToggle={handleSidebarToggle}
            roles={roles} />
            {children}
            <Outlet />
          </div>
        </div>
        <Footer />
      </>
    );
}

export default AuthLayout;