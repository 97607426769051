import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
import Loader from "services/loaders/loader";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ChangeProfilePic from "./components/profilepic";
import EditClient from "./components/edit-client";
import CLientLoans from "./components/client-loans";
import AddDocuments from "./components/documents-client";
import ClientDocuments from "./components/client-documents";
import SendClientSMS from "./components/client-sms";
import ClientSmsTable from "./components/client-sms-table";
// import { decrypt } from "utils/encrpyt";
const ClientSingle = ({ roles }) => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [client, setClient] = useState();

  const [status, setStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const handleChangeStatus = async (e) => {
    progress.start();
    const controller = new AbortController();
    const data =
      client?.status === "active"
        ? JSON.stringify({ status: "inactive" })
        : JSON.stringify({ status: "active" });
    try {
      const response = await axiosPrivate.patch(
        `/clients/${client?.id}`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success("client status settings updated");
      setStatus(
        response?.data?.data?.client?.status === "active" ? true : false
      );
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        // console.log(error?.response)
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const [blocked, setBlocked] = useState(true);
  const handleChangeBlocked = async (e) => {
    progress.start();
    const controller = new AbortController();
    const data =
      client?.status === "overdue"
        ? JSON.stringify({ status: "active" })
        : JSON.stringify({ status: "overdue" });
    try {
      const response = await axiosPrivate.patch(
        `/clients/${client?.id}`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success("client blocked status updated");
      setStatus(
        response?.data?.data?.client?.status === "overdue" ? true : false
      );
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        // console.log(error?.response)
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const [email, setEmail] = useState(true);
  const handleChangeEmail = async (e) => {
    progress.start();
    const controller = new AbortController();
    const data =
      client?.email_setting === "yes"
        ? JSON.stringify({ emails: "no" })
        : JSON.stringify({ emails: "yes" });
    try {
      const response = await axiosPrivate.patch(
        `/clients/${client?.id}`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success("email settings changed successfully");
      setEmail(
        response?.data?.data?.client?.email_setting === "yes" ? true : false
      );
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        // console.log(error?.response)
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const [sms, setSms] = useState(true);
  const handleChangeSms = async (e) => {
    progress.start();
    const controller = new AbortController();
    const data =
      client?.sms_setting === "yes"
        ? JSON.stringify({ sms: "no" })
        : JSON.stringify({ sms: "yes" });
    try {
      const response = await axiosPrivate.patch(
        `/clients/${client?.id}`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success("sms settings changed successfully");
      setSms(
        response?.data?.data?.client?.sms_setting === "yes" ? true : false
      );
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        // console.log(error?.response)
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  // const columns = [];
  // const data = [];
  const controller = new AbortController();
  const getClient = async () => {
    try {
      const response = await axiosPrivate.get(`/clients/${params.id}`, {
        signal: controller.signal,
      });
      setClient(response?.data?.data?.client);

      setEmail(
        response?.data?.data?.client?.email_setting === "yes" ? true : false
      );

      setSms(
        response?.data?.data?.client?.sms_setting === "yes" ? true : false
      );

      setStatus(
        response?.data?.data?.client?.status === "active" ? true : false
      );

      setBlocked(
        response?.data?.data?.client?.status === "overdue" ? true : false
      );
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    getClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [showModalProfile, setShowModalProfile] = useState(false);
  const handleProfileModalOpen = () => setShowModalProfile(true);
  const handleProfileModalClose = () => setShowModalProfile(false);
  const [showModalEditClient, setShowModalEditClient] = useState(false);
  const handleShowEditClient = () => setShowModalEditClient(true);
  const handleCloseEditClient = () => setShowModalEditClient(false);

  const [loans, setLoans] = useState([]);

  const getLoans = async () => {
    try {
      const response = await axiosPrivate.get(`/account/${params.id}`, {
        signal: controller.signal,
      });
      setLoans(response?.data?.data?.account);
      // console.log(response?.data?.data?.account);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  const refetch_loans = async () => {
    setIsLoading(true);
    await getLoans();
  };
  const refetch = async () => {
    setIsLoading(true);
    await getClient();
  };

  const [showModalDocument, setShowModalDocument] = useState(false);
  const handleDocumentModalOpen = () => setShowModalDocument(true);
  const handleDocumentModalClose = () => setShowModalDocument(false);

  const [smsData, setSmsData] = useState([]);
  const getSMS = async () => {
    try {
      const response = await axiosPrivate.get(`sms/client/${params.id}`, {
        signal: controller.signal,
      });
      setSmsData(response?.data?.data?.sms);
      // console.log(response?.data?.data?.account);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getLoans();
    getSMS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch_sms = async () => {
    setIsLoading(true);
    await getSMS();
  };

  const [showSMSModal, setShowSMSModal] = useState(false);
  const handleSMSModalOpen = () => setShowSMSModal(true);
  const handleSMSModalCLose = () => setShowSMSModal(false);

  return (
    <>
      <Toaster />

      <div className="container-fluid note-has-grid">
        <div className="card bg-light-info shadow-none position-relative overflow-hidden mt-md-0 mt-5">
          <div className="card-body px-4 py-3 ">
            <div className="row align-items-center">
              <div className="col-12">
                <h5 className="fw-semibold mb-8">Client Summary</h5>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        className="text-muted text-decoration-none"
                        to="/dashboard"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Client Summary
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      {client?.firstname} {client?.lastname}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fuild">
          <div className="card overflow-hidden">
            <div className="card-body p-0">
              <div className="row align-items-center mt-5">
                <div className="col-lg-4 order-lg-1 order-2 mt-5">
                  <div className="d-flex align-items-center justify-content-around m-4">
                    <div className="text-center">
                      <i className="ti ti-file-description fs-6 d-block mb-2"></i>
                      <h4 className="mb-0 fw-semibold lh-1">
                        {
                          loans?.filter((loan) => loan.status === "closed")
                            ?.length
                        }
                      </h4>
                      <p className="mb-0 fs-1">Completed Loans</p>
                    </div>
                    <div className="text-center">
                      <i className="ti ti-activity fs-6 d-block mb-2"></i>
                      <h4 className="mb-0 fw-semibold lh-1">
                        {" "}
                        {
                          loans?.filter((loan) => loan.status === "ongoing")
                            ?.length
                        }
                      </h4>
                      <p className="mb-0 fs-1">Active Loans</p>
                    </div>
                    <div className="text-center">
                      <i className="ti ti-alert-octagon fs-6 d-block mb-2"></i>
                      <h4 className="mb-0 fw-semibold lh-1">
                        {" "}
                        {
                          loans?.filter((loan) => loan.status === "overdue")
                            ?.length
                        }
                      </h4>
                      <p className="mb-0 fs-1">Overdue Loans</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mt-n3 order-lg-2 order-1">
                  <div className="mt-n5">
                    <div className="d-flex align-items-center justify-content-center mb-2">
                      <div
                        className="linear-gradient d-flex align-items-center justify-content-center rounded-circle"
                        style={{ width: "110px", height: "110px" }}
                      >
                        <div
                          className="border border-4 border-white d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{ width: "100px", height: "100px" }}
                        >
                          <img
                            src={
                              client?.image ??
                              "../../dist/images/profile/user-1.jpg"
                            }
                            alt=""
                            className="w-100 h-100"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <>
                          <h5 className="fs-2 mb-0 fw-semibold">
                            {client?.firstname} {client?.lastname}
                          </h5>
                          <p className="mb-0 fs-1">{client?.account}</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 order-last">
                  <ul className="list-unstyled d-flex align-items-center justify-content-center justify-content-lg-start my-3 gap-3">
                    {roles.includes(10000026) && (
                      <li className="position-relative">
                        <button
                          className="text-white d-flex  bg-danger btn btn-link align-items-center justify-content-center bg-primary p-2 fs-4 rounded-circle"
                          width="30"
                          height="30"
                          onClick={handleProfileModalOpen}
                        >
                          <i className="ti ti-upload"></i>
                        </button>
                        <ChangeProfilePic
                          getClient={getClient}
                          handleProfileModalClose={handleProfileModalClose}
                          showModalProfile={showModalProfile}
                        />
                      </li>
                    )}
                    {roles.includes(10000027) && (
                      <li className="position-relative">
                        <button
                          className="text-white bg-primary btn btn-link d-flex align-items-center justify-content-center p-2 fs-4 rounded-circle"
                          onClick={handleDocumentModalOpen}
                        >
                          <i className="ti ti-book-upload"></i>
                        </button>
                        <AddDocuments
                          getClient={getClient}
                          handleProfileDocumentClose={handleDocumentModalClose}
                          showModalDocument={showModalDocument}
                        />
                      </li>
                    )}
                    {roles.includes(10000031) && (
                      <li className="position-relative">
                        <button
                          onClick={handleSMSModalOpen}
                          className="text-white bg-success btn btn-link d-flex align-items-center justify-content-center p-2 fs-4 rounded-circle"
                        >
                          <i className="ti ti-device-mobile-message"></i>
                        </button>
                        <SendClientSMS
                          showModalSms={showSMSModal}
                          handleSmsDocumentClose={handleSMSModalCLose}
                          getSMS={getSMS}
                          contact={client?.contact}
                        />
                      </li>
                    )}
                    <li className="position-relative">
                      <button className="text-white bg-dark btn btn-link d-flex align-items-center justify-content-center p-2 fs-4 rounded-circle">
                        <i className="ti ti-cash"></i>
                      </button>
                    </li>
                    {roles.includes(10000025) && (
                      <li>
                        <button
                          className="btn btn-primary"
                          onClick={handleShowEditClient}
                        >
                          {" "}
                          Update Client
                        </button>
                      </li>
                    )}
                    <EditClient
                      handleClose={handleCloseEditClient}
                      showModal={showModalEditClient}
                      clientData={client}
                      getClient={getClient}
                    />
                  </ul>
                </div>
              </div>
              <ul
                className="nav nav-pills user-profile-tab justify-content-end mt-2 bg-light-info rounded-2"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link position-relative rounded-0 active d-flex align-items-center justify-content-center bg-transparent fs-3 py-6"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="true"
                  >
                    <i className="ti ti-user-circle me-2 fs-6"></i>
                    <span className="d-none d-md-block">Summary</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-6"
                    id="pills-followers-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-followers"
                    type="button"
                    role="tab"
                    aria-controls="pills-followers"
                    aria-selected="false"
                  >
                    <i className="ti ti-device-mobile-message me-2 fs-6"></i>
                    <span className="d-none d-md-block">Communication</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-6"
                    id="pills-friends-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-friends"
                    type="button"
                    role="tab"
                    aria-controls="pills-friends"
                    aria-selected="false"
                  >
                    <i className="ti ti-pdf me-2 fs-6"></i>
                    <span className="d-none d-md-block">Documents</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-6"
                    id="pills-gallery-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-gallery"
                    type="button"
                    role="tab"
                    aria-controls="pills-gallery"
                    aria-selected="false"
                  >
                    <i className="ti ti-receipt-refund me-2 fs-6"></i>
                    <span className="d-none d-md-block">Loans</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabIndex="0"
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="card shadow-none border">
                    <div className="card-body">
                      <h6 className="fw-semibold mb-3">Summary</h6>
                      <ul className="list-unstyled mb-0">
                        <li className="list-group-item border-0 d-flex align-items-center px-0 mb-2 pt-0">
                          <div className="d-flex align-items-start flex-column justify-content-center">
                            <h6 className="mb-0 text-sm">Full Name</h6>
                            <p className="mb-0 text-small">
                              Firstname & Lastname
                            </p>
                          </div>
                          <p className="text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-50 w-md-auto">
                            {client?.firstname} {client?.lastname}
                          </p>
                        </li>
                        <li className="list-group-item border-0 d-flex align-items-center px-0 mb-2 pt-0">
                          <div className="d-flex align-items-start flex-column justify-content-center">
                            <h6 className="mb-0 text-sm">Mobile</h6>
                            <p className="mb-0 text-small">UGA (+256) </p>
                          </div>
                          <p className="text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-50 w-md-auto">
                            {" "}
                            {client?.contact}{" "}
                          </p>
                        </li>
                        <li className="list-group-item border-0 d-flex align-items-center px-0 mb-2 pt-0">
                          <div className="d-flex align-items-start flex-column justify-content-center">
                            <h6 className="mb-0 text-sm">Occupation</h6>
                            <p className="mb-0 text-small">Job / Profession</p>
                          </div>
                          <p className="text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-50 w-md-auto">
                            {client?.occupation}
                          </p>
                        </li>
                        <li className="list-group-item border-0 d-flex align-items-center px-0 mb-2 pt-0">
                          <div className="d-flex align-items-start flex-column justify-content-center">
                            <h6 className="mb-0 text-sm">E-mail</h6>
                            <p className="mb-0 text-small">primary email</p>
                          </div>
                          <p className="text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-50 w-md-auto">
                            {client?.email}
                          </p>
                        </li>
                        <li className="list-group-item border-0 d-flex align-items-center px-0 mb-2 pt-0">
                          <div className="d-flex align-items-start flex-column justify-content-center">
                            <h6 className="mb-0 text-sm">Identification</h6>
                            <p className="mb-0 text-small">passport or NIN</p>
                          </div>
                          <p className="text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-50 w-md-auto">
                            {client?.identification}
                          </p>
                        </li>
                        <li className="list-group-item border-0 d-flex align-items-center px-0 mb-2 pt-0">
                          <div className="d-flex align-items-start flex-column justify-content-center">
                            <h6 className="mb-0 text-sm">Location</h6>
                            <p className="mb-0 text-small">Address </p>
                          </div>
                          <p className="text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-50 w-md-auto">
                            {client?.address}
                          </p>
                        </li>
                        <li className="list-group-item border-0 d-flex align-items-center px-0 mb-2 pt-0">
                          <div className="d-flex align-items-start flex-column justify-content-center">
                            <h6 className="mb-0 text-sm">Gender</h6>
                            <p className="mb-0 text-small">Male Or Female</p>
                          </div>
                          <p className="text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-50 w-md-auto">
                            {client?.gender === "male" ? "Male" : "Female"}
                          </p>
                        </li>
                        <li className="list-group-item border-0 d-flex align-items-center px-0 mb-2 pt-0">
                          <div className="d-flex align-items-start flex-column justify-content-center">
                            <h6 className="mb-0 text-sm">Client Notes</h6>
                            <p className="mb-0 text-small">
                              {" "}
                              {client?.otherinfo}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card shadow-none border">
                    <div className="card-body">
                      <h6 className="fw-semibold mb-3">
                        Communication Settings
                      </h6>
                      <ul className="list-unstyled mb-0">
                        <li className="list-group-item border-0 px-0">
                          <div className="form-check form-switch ps-0">
                            <input
                              className="form-check-input ms-auto"
                              type="checkbox"
                              name="email"
                              onChange={handleChangeEmail}
                              checked={email}
                              disabled={!roles.includes(10000030)}
                            />
                            <label className="form-check-label text-body ms-2 text-truncate w-80 mb-0">
                              Send Emails
                            </label>
                          </div>
                        </li>
                        <li className="list-group-item border-0 px-0">
                          <div className="form-check form-switch ps-0">
                            <input
                              className="form-check-input ms-auto"
                              type="checkbox"
                              name="sms"
                              onChange={handleChangeSms}
                              checked={sms}
                              disabled={!roles.includes(10000030)}
                            />
                            <label className="form-check-label text-body ms-2 text-truncate w-80 mb-0">
                              Send SMS
                            </label>
                          </div>
                        </li>
                      </ul>
                      <h6 className="fw-semibold mt-4">Status</h6>
                      <ul className="list-group">
                        <li className="list-group-item border-0 px-0">
                          <div className="form-check form-switch ps-0">
                            <input
                              className="form-check-input ms-auto"
                              type="checkbox"
                              name="status"
                              onChange={handleChangeStatus}
                              checked={status}
                              disabled={!roles.includes(10000030)}
                            />
                            <label className="form-check-label text-body ms-2 text-truncate w-80 mb-0">
                              InActive / Active
                            </label>
                          </div>
                        </li>
                        <li className="list-group-item border-0 px-0">
                          <div className="form-check form-switch ps-0">
                            <input
                              className="form-check-input ms-auto"
                              type="checkbox"
                              name="blocked"
                              onChange={handleChangeBlocked}
                              checked={blocked}
                              disabled={!roles.includes(10000030)}
                            />
                            <label className="form-check-label text-body ms-2 text-truncate w-80 mb-0">
                              Unblock / Block
                            </label>
                          </div>
                        </li>
                      </ul>
                      <h6 className="fw-semibold mt-2">Bulk Actions</h6>
                      <ul className="list-group">
                        <li className="list-group-item border-0 px-0">
                          <div className="form-check form-switch ps-0">
                            <input
                              className="form-check-input ms-auto"
                              type="checkbox"
                              name="status"
                              onChange={handleChangeStatus}
                              checked={status}
                              disabled={!roles.includes(10000030)}
                            />
                            <label className="form-check-label text-body ms-2 text-truncate w-80 mb-0">
                              Bulk SMS / Active
                            </label>
                          </div>
                        </li>
                        <li className="list-group-item border-0 px-0">
                          <div className="form-check form-switch ps-0">
                            <input
                              className="form-check-input ms-auto"
                              type="checkbox"
                              name="blocked"
                              onChange={handleChangeBlocked}
                              checked={blocked}
                              disabled={!roles.includes(10000030)}

                            />
                            <label className="form-check-label text-body ms-2 text-truncate w-80 mb-0">
                              Bulk Emails / Active
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-gallery"
              role="tabpanel"
              aria-labelledby="pills-gallery-tab"
              tabIndex="0"
            >
              <div className="row">
                <CLientLoans
                  loans={loans}
                  refetch={refetch_loans}
                  isLoading={isLoading}
                />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-friends"
              role="tabpanel"
              aria-labelledby="pills-friends-tab"
              tabIndex="0"
            >
              <div className="row">
                <ClientDocuments
                  documents={client?.documents ?? []}
                  refetch={refetch}
                  roles={roles}
                  isLoading={isLoading}
                />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-followers"
              role="tabpanel"
              aria-labelledby="pills-followers-tab"
              tabIndex="0"
            >
              <div className="row">
                <ClientSmsTable
                  data={smsData ?? []}
                  refetch={refetch_sms}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientSingle;
