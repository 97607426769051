import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
const AgingLoansReport = () => {
 
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const controller = new AbortController();
  const [loans, setLoans] = useState([]);
  // eslint-disable-next-line
  const onQuery = async (data) => {
    try {
      progress.start();
      const response = await axiosPrivate.post(
        `/reports/accounting/loans`,
        data,
        {
          signal: controller.signal,
        }
      );

      setLoans(
        response.data.data.loans.filter(
          (loan) => loan.status === "approved" && loan.status === "closed"
        )
      );
      progress.finish();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        console.log(error?.response);
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const total = loans.map((data) => data.amount).reduce((a, b) => a + b, 0);
  return (
    <>
      <Toaster />
      <>
       
            <div className="container-fluid note-has-grid">
              <div className="card bg-light-dark text-white shadow-none position-relative overflow-hidden mt-md-0 mt-5">
                <div className="card-body px-4 py-3">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <h5 className="fw-semibold mb-8 text-white">
                        Loans Reports - Aging Loans
                      </h5>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link
                              className="text-muted text-decoration-none text-white"
                              to="/dashboard"
                            >
                              Dashboard
                            </Link>
                          </li>
                          <li className="breadcrumb-item" aria-current="page">
                            Aging Loans
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fuild">
                <div className="card">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card mb-5 mb-lg-0">
                        <div className="card-header bg-light-dark">
                          <h2 className="h5 p-1 text-bold text-uppercase text-center text-white">
                            Aging Loans{" "}
                          </h2>
                        </div>
                        <div className="card-body mt-3 mb-3">
                          <div className="mt-3">
                            <table className="table table-hover table-bordered">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Loan Number</th>
                                  <th>Client</th>
                                  <th>
                                    Principal in <br /> arrears
                                  </th>
                                  <th>
                                    Interest in <br /> arrears
                                  </th>
                                  <th>
                                    Penalty in <br /> arrears
                                  </th>
                                  <th>1-30 days</th>
                                  <th>31-60 days</th>
                                  <th>61-90 days</th>
                                  <th>91-120 days</th>
                                  <th>121-180 days</th>
                                  <th>More 180 days</th>
                                </tr>
                              </thead>
                              <tbody>
                                {loans?.map((loan) => (
                                  <tr key={loan?.id}>
                                    <td>{loan?.date}</td>
                                    <td>{loan?.reference}</td>
                                    <td>{loan?.client}</td>
                                    <td>{loan?.frequency}</td>
                                    <td>{loan?.type}</td>
                                    <td>{loan?.interest}</td>
                                    <td>{loan?.amount?.toLocaleString()}</td>
                                  </tr>
                                ))}
                                <tr>
                                  <th colSpan={3}>Total</th>
                                  <th>{total.toLocaleString()}</th>
                                  <th>{total.toLocaleString()}</th>
                                  <th>{total.toLocaleString()}</th>
                                  <th>{total.toLocaleString()}</th>
                                  <th>{total.toLocaleString()}</th>
                                  <th>{total.toLocaleString()}</th>
                                  <th>{total.toLocaleString()}</th>
                                  <th>{total.toLocaleString()}</th>
                                  <th>{total.toLocaleString()}</th>
                                  <th>{total.toLocaleString()}</th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
      </>
    </>
  );
};

export default AgingLoansReport;
