import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Table from "pages/components/common_table";
import ConfirmModal from "pages/components/confirmModal";
import AddRole from "./components/add-role";
import EditRoles from "./components/edit-role";

const Roles = () => {
  const controller = new AbortController();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState('');
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const getRoles = async () => {
    try {
      const response = await axiosPrivate.get("/roles", {
        signal: controller.signal,
      });
      setRoles(response.data.data.role);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
   const getPermissionGroups = async () => {
     try {
       const response = await axiosPrivate.get("/groups/permissions", {
         signal: controller.signal,
       });
       setGroups(response.data.data.permissions_groups);
       setIsLoading(false);
     } catch (error) {
       // console.log(error)
       if (error?.response?.status === 401) {
         navigate("/", { state: { from: location }, replace: true });
       }
     }
   };
  useEffect(() => {
    getRoles();
    getPermissionGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = async () => {
    setIsLoading(true);
    await getRoles();
    await getPermissionGroups();
  };
  const handleConfirm = async () => {};
  const handleCancel = async () => {};
 
 const columns_two = useMemo(
   () => [
     {
       accessorKey: "name", //access nested data with dot notation
       enableClickToCopy: true,
       size: 50,
       header: "Title",
     },
   ],
   []
 );

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  
  const [showModalEdit, setShowModalEdit] = useState(false);
 
  const [permissionEdit, setPermissionEdit] = useState([]);
  
   const handleOpenModalEdit = async (id) => {
   
    try {
      const response = await axiosPrivate.get(`roles/${id}`, {
        signal: controller.signal,
      });
      setPermissionEdit(response.data.data.permissions);
      setShowModalEdit(true);
      setRoleId(id);
      console.log(permissionEdit);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
    
   };
   const handleCloseModalEdit = () => setShowModalEdit(false);
  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Title",
      },
      {
        id: "id", //access nested data with dot notation
        accessorFn: (row) => (
          <>
            <button
              className="btn btn-info btn-sm mb-0 m-1"
              onClick={() => {
                handleOpenModalEdit(row.id);
              }}
            >
              Update
            </button>
            <button
              className="btn btn-danger btn-sm mb-0 m-1"
              onClick={() => {
                setShow(true);
              }}
            >
              Delete
            </button>
          </>
        ),
        enableClickToCopy: false,
        size: 50,
        header: "Action",
      },
    ],
    // eslint-disable-next-line
    []
  );
  
  return (
    <>
      <div className="container-fluid note-has-grid">
        <div className="card bg-light-danger shadow-none position-relative overflow-hidden mt-md-0 mt-5">
          <div className="card-body px-4 py-3 ">
            <div className="row align-items-center">
              <div className="col-12">
                <h5 className="fw-semibold mb-8">Roles and Permissions</h5>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        className="text-muted text-decoration-none"
                        to="/dashboard"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Roles and Permissions
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fuild">
          <div className="card">
            <div className="card-header row">
              <div className="col-md-6 col-sm-12">
                <h5 className="fw-semibold mb-8">Roles And Permissions</h5>
              </div>
              <div className="col-md-6 col-sm-12">
                <button
                  className="btn btn-primary float-end"
                  onClick={handleOpenModal}
                >
                  Add New Role
                </button>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="row">
                <div className="col-md-7">
                  <Table
                    columns={columns}
                    data={roles}
                    isLoading={isLoading}
                    refetch={refetch}
                    height={"250px"}
                  />
                </div>
                <div className="col-md-5">
                  <Table
                    columns={columns_two}
                    data={groups}
                    isLoading={isLoading}
                    refetch={refetch}
                    height={"250px"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        message="Are you sure you want to delete this role?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
      <AddRole
        getRoles={getRoles}
        handleClose={handleCloseModal}
        showModal={showModal}
      />
      {permissionEdit && <EditRoles
        getRoles={getRoles}
        handleClose={handleCloseModalEdit}
        showModal={showModalEdit}
        roles={permissionEdit}
        id={roleId}
      />}
    </>
  );
};

export default Roles;
