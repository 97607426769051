import { useState, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import Loader from "services/loaders/loader";
import ApproveLoan from "./components/applications/actions/approve-loan";
import DeclineLoan from "./components/applications/actions/decline-loan";
import LoanApplicationEdit from "./components/applications/actions/update-loan";
import AddDocument from "./components/applications/add-document";
import AddColletral from "./components/applications/add-colletral";
import AddGarantor from "./components/applications/add-guarantor";
import LoanColletralTable from "./components/applications/loan-add-on-tables/loan-colletral";
import LoanGuarantorTable from "./components/applications/loan-add-on-tables/loan-guarantor";
import LoanDocumentTable from "./components/applications/loan-add-on-tables/loan-documents";
const LoanApplicationSingle = ({ roles }) => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [loan, setLoan] = useState([]);

  const controller = new AbortController();
  const getLoanAppData = async () => {
    try {
      const response = await axiosPrivate.get(`/applications/${params.id}`, {
        signal: controller.signal,
      });
      setLoan(response?.data?.data?.loanApp);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getLoanAppData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [approveLoanModal, setApprovalLoanModal] = useState(false);
  const handleApproveLoanModalOpen = () => setApprovalLoanModal(true);
  const handleApproveLoanModalClose = () => setApprovalLoanModal(false);
  const [declineLoanModal, setDeclineLoanModal] = useState(false);
  const handleDeclineLoanModalOpen = () => setDeclineLoanModal(true);
  const handleDeclineLoanModalClose = () => setDeclineLoanModal(false);
  const [editLoanModal, setEditLoanModal] = useState(false);
  const handleEditLoanModalOpen = () => setEditLoanModal(true);
  const handleEditLoanModalClose = () => setEditLoanModal(false);
  const [documentLoanModal, setDocumentLoanModal] = useState(false);
  const handleDocumentLoanModalOpen = () => setDocumentLoanModal(true);
  const handleDocumentLoanModalClose = () => setDocumentLoanModal(false);
  const [showModalColletral, setShowModalColletral] = useState(false);
  const handleOpenColletral = () => setShowModalColletral(true);
  const handleCloseColletral = () => setShowModalColletral(false);
  const [showModalGuarantor, setShowModalGuarantor] = useState(false);
  const handleOpenGuarantor = () => setShowModalGuarantor(true);
  const handleCloseGuarantor = () => setShowModalGuarantor(false);

  const refetch = async () => {
    setIsLoading(true);
    await getLoanAppData();
  };

  return (
    <>
      <div className="container-fluid note-has-grid">
        <div className="card bg-light-info shadow-none position-relative overflow-hidden mt-md-0 mt-5">
          <div className="card-body px-4 py-3 ">
            <div className="row align-items-center">
              <div className="col-12">
                <h5 className="fw-semibold mb-8">Loan Application Summary</h5>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        className="text-muted text-decoration-none"
                        to="/dashboard"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Loan Application Summary
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      {loan?.number}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fuild">
          <div className="card overflow-hidden">
            <div className="card-body p-0">
              <div className="row align-items-center mt-5">
                <div className="col-lg-4 mt-1 p-0 order-lg-1 order-2">
                  <div className="mt-n5">
                    <div className="d-flex align-items-center justify-content-center mb-2">
                      <div
                        className="linear-gradient d-flex align-items-center justify-content-center rounded-circle"
                        style={{ width: "110px", height: "110px" }}
                      >
                        <div
                          className="border border-4 border-white d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{ width: "100px", height: "100px" }}
                        >
                          <img
                            src={
                              loan?.client?.client_image ??
                              "../../dist/images/profile/user-1.jpg"
                            }
                            alt=""
                            className="w-100 h-100"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <>
                          <h5 className="fs-2 mb-0 fw-semibold">
                            {loan?.client?.firstname} {loan?.client?.lastname}
                          </h5>
                          <p className="mb-0 fs-1">{loan?.client?.account}</p>
                          <p className="mb-0 fs-1">{loan?.number}</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 order-last">
                  <ul className="list-unstyled d-flex align-items-center justify-content-center justify-content-lg-center my-2 gap-1">
                    {loan?.status === "pending" ? (
                      <>
                        <li>
                          {roles.includes(10000052) && (
                            <button
                              className="btn btn-success"
                              onClick={handleApproveLoanModalOpen}
                            >
                              {" "}
                              <i className="ti ti-check"></i> Approve
                            </button>
                          )}
                          <ApproveLoan
                            handleAprroveClose={handleApproveLoanModalClose}
                            showApproveModal={approveLoanModal}
                            getLoan={getLoanAppData}
                          />
                        </li>
                        <li>
                          {roles.includes(10000053) && (
                            <button
                              className="btn btn-danger"
                              onClick={handleDeclineLoanModalOpen}
                            >
                              {" "}
                              <i className="ti ti-circle-x"></i> Reject
                            </button>
                          )}
                          <DeclineLoan
                            handleDeclineClose={handleDeclineLoanModalClose}
                            showDeclineModal={declineLoanModal}
                            getLoan={getLoanAppData}
                          />
                        </li>

                        <li>
                          {roles.includes(10000054) && (
                            <button
                              className="btn btn-primary"
                              onClick={handleEditLoanModalOpen}
                            >
                              <i className="ti ti-edit"></i> Edit Loan
                            </button>
                          )}
                          <LoanApplicationEdit
                            handleClose={handleEditLoanModalClose}
                            showModal={editLoanModal}
                            loanAppData={loan}
                            getLoan={getLoanAppData}
                          />
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    <li>
                      {roles.includes(10000055) && (
                        <button
                          className="btn btn-dark"
                          onClick={handleDocumentLoanModalOpen}
                        >
                          <i className="ti ti-file-description"></i> + Document
                        </button>
                      )}
                      <AddDocument
                        showModal={documentLoanModal}
                        handleClose={handleDocumentLoanModalClose}
                        getLoan={getLoanAppData}
                      />
                    </li>
                    <li>
                      {roles.includes(10000058) && (
                        <button
                          className="btn btn-warning"
                          onClick={handleOpenColletral}
                        >
                          <i className="ti ti-file-infinity"></i> Colletral
                        </button>
                      )}
                      <AddColletral
                        showModal={showModalColletral}
                        handleClose={handleCloseColletral}
                        getLoan={getLoanAppData}
                      />
                    </li>
                    <li>
                      {roles.includes(10000060) && (
                        <button
                          className="btn btn-dark"
                          onClick={handleOpenGuarantor}
                        >
                          <i className="ti ti-user-plus"></i> Guarantor
                        </button>
                      )}
                      <AddGarantor
                        handleClose={handleCloseGuarantor}
                        showModal={showModalGuarantor}
                        getLoan={getLoanAppData}
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <ul
                className="nav nav-pills user-profile-tab justify-content-end mt-2 bg-light-info rounded-2"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link position-relative rounded-0 active d-flex align-items-center justify-content-center bg-transparent fs-3 py-6"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="true"
                  >
                    <i className="ti ti-user-circle me-2 fs-6"></i>
                    <span className="d-none d-md-block">Summary</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-6"
                    id="pills-followers-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-followers"
                    type="button"
                    role="tab"
                    aria-controls="pills-followers"
                    aria-selected="false"
                  >
                    <i className="ti ti-file-description me-2 fs-6"></i>
                    <span className="d-none d-md-block">Colletral</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-6"
                    id="pills-documents-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-documents"
                    type="button"
                    role="tab"
                    aria-controls="pills-documents"
                    aria-selected="false"
                  >
                    <i className="ti ti-pdf me-2 fs-6"></i>
                    <span className="d-none d-md-block">Documents</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-6"
                    id="pills-gurantors-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-gurantors"
                    type="button"
                    role="tab"
                    aria-controls="pills-gurantors"
                    aria-selected="false"
                  >
                    <i className="ti ti-user-plus me-2 fs-6"></i>
                    <span className="d-none d-md-block">Guarantors</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane  show active"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabIndex="0"
            >
              <div className="row">
                <div className="col-md-6 card card-body shadow-sm border-3">
                  <ul className="list-unstyled mb-0">
                    <li className="list-group-item border-0 d-flex align-items-center ">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">Amount</h6>
                      </div>
                      <p className="btn  text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                        {" "}
                        {loan?.amount?.toLocaleString()}
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center ">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">Application Fee</h6>
                      </div>
                      <p className="btn  text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                        {" "}
                        {loan?.fee?.toLocaleString()}
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center ">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">Application Rate</h6>
                      </div>
                      <p className="btn  text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                        {" "}
                        {loan?.rate} %
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center ">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">
                          Application Payment Frequency
                        </h6>
                      </div>
                      <p className="btn  text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                        {" "}
                        {loan?.frequency}
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center ">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">Application Type</h6>
                      </div>
                      <p className="btn  text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                        {" "}
                        {loan?.type}
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center ">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">Application Period</h6>
                      </div>
                      <p className="btn  text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                        {" "}
                        {loan?.period}
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 card card-body shadow-sm border-3">
                  <ul className="list-unstyled mb-0">
                    <li className="list-group-item border-0 d-flex align-items-center ">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">
                          Loan Application Status
                        </h6>
                        {/* <p className="mb-0 text-xs">UGA (+256) </p> */}
                      </div>
                      <p className="btn  text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                        {" "}
                        {loan?.status === "approved" ? (
                          <span className="text-small text-center badge badge-sm bg-success">
                            Approved
                          </span>
                        ) : loan?.status === "pending" ? (
                          <span className="text-small text-center badge badge-sm bg-info">
                            Pending
                          </span>
                        ) : (
                          <span className="text-small text-center badge badge-sm bg-danger">
                            Rejected
                          </span>
                        )}
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center ">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">
                          Loan Application Number
                        </h6>
                        {/* <p className="mb-0 text-xs">UGA (+256) </p> */}
                      </div>
                      <p className="btn  text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                        {" "}
                        {loan?.number}
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center ">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">Loan Application Date</h6>
                        {/* <p className="mb-0 text-xs">UGA (+256) </p> */}
                      </div>
                      <p className="btn  text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                        {" "}
                        {loan?.date}
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center ">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">
                          Loan Application Diburse Method
                        </h6>
                        {/* <p className="mb-0 text-xs">UGA (+256) </p> */}
                      </div>
                      <p className="btn  text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                        {" "}
                        {loan?.method}
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center ">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">
                          Loan Application Officer
                        </h6>
                        {/* <p className="mb-0 text-xs">UGA (+256) </p> */}
                      </div>

                      <p className="btn  text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                        {" "}
                        {loan?.officer?.firstname} {loan?.officer?.lastname}
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center ">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">More Notes</h6>
                      </div>
                      <p className="btn  text-xs text-dark pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">
                        {" "}
                        {loan?.witness?.information}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="pills-gurantors"
              role="tabpanel"
              aria-labelledby="pills-gurantors-tab"
              tabIndex="0"
            >
              <div className="row">
                <LoanGuarantorTable
                  data={loan?.garantor ?? []}
                  refetch={refetch}
                  isLoading={isLoading}
                  roles={roles}
                />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-documents"
              role="tabpanel"
              aria-labelledby="pills-documents-tab"
              tabIndex="0"
            >
              <div className="row">
                <LoanDocumentTable
                  data={loan?.docs ?? []}
                  refetch={refetch}
                  isLoading={isLoading}
                  roles={roles}
                />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-followers"
              role="tabpanel"
              aria-labelledby="pills-followers-tab"
              tabIndex="0"
            >
              <div className="row">
                <LoanColletralTable
                  data={loan?.colletral ?? []}
                  refetch={refetch}
                  isLoading={isLoading}
                  roles={roles}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanApplicationSingle;
