import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { Modal } from "react-bootstrap";
const AddRole = ({ getRoles, handleClose, showModal }) => {
  const progress = new ProgressBar();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const controller = new AbortController();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.post("/roles", data, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleClose();
      getRoles();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const [permissions, setPermissions] =  useState([]);
  const getPermissions = async () => {
    try {
      const response = await axiosPrivate.get("/permissions", {
        signal: controller.signal,
      });
      setPermissions(response.data.data.permissions);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect( ()=> {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Add New Role
          </Modal.Title>
          <button
            onClick={handleClose}
            className="btn-close btn-danger"
          ></button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="form-floating mb-1">
                  <input
                    type="text"
                    className="form-control"
                    id="role"
                    name="role"
                    placeholder=""
                    {...register("role", { required: true })}
                  />
                  <label htmlFor="role">Role Title</label>
                  {errors?.role?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      This Field Is Required
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12 row">
                {permissions?.map((data) => (
                  <React.Fragment key={data?.id}>
                    <div className="bg-dark rounded-0 col-12 p-2 m-2">
                      <h5 className="text-light">{data?.name}</h5>
                    </div>
                    {data?.permissions?.map((permission) => (
                      <div className="col-3" key={permission?.id}>
                        <input
                          type="checkbox"
                          value={permission?.id}
                          name="permissions"
                          {...register("roles")}
                        />
                        <span className="px-1 text-dark text-sm">
                          {permission?.name}
                        </span>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Save"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddRole;
