import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Table from "pages/components/common_table";
import ConfirmModal from "pages/components/confirmModal";
import AddNewUser from "./components/add-new-user";
import UpdateUserRole from "./components/change-role";
import AddBranchesUser from "./components/add-branches";

const StaffAccounts = () => {
  const controller = new AbortController();

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [show, setShow] = useState(false);
  const [showUnlock, setShowUnlock] = useState(false);
  const getUsers = async () => {
    try {
      const response = await axiosPrivate.get("/users", {
        signal: controller.signal,
      });
      setUsers(response.data.data.users);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const getRoles = async () => {
    try {
      const response = await axiosPrivate.get("/roles", {
        signal: controller.signal,
      });
      setRoles(response.data.data.role);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
    const [branches, setBranches] = useState([]);

    const getBranches = async () => {
      try {
        const response = await axiosPrivate.get("/settings/branches", {
          signal: controller.signal,
        });
        setBranches(response.data.data.branches);
      } catch (error) {
        // console.log(error)
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    };
  useEffect(() => {
    getUsers();
    getRoles();
    getBranches();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = async () => {
    setIsLoading(true);
    await getUsers();
  };
  
  const handleConfirm = async () => {};
  const handleCancel = async () => {};

  const columns = useMemo(
    () => [
      {
        id: "title", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Fullname",
        accessorFn: (row) => `${row.firstname} ${row.lastname}`,
      },
      {
        accessorKey: "role", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Role",
      },
      {
        accessorKey: "contact", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Contact",
      },
      {
        accessorKey: "branch", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Branch",
      },
      {
        id: "email", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Email",
        accessorFn: (row) => `${row?.email}`,
      },

      {
        id: "status", //access nested data with dot notation
        accessorFn: (row) => (
          <div className="">
            {row.status === "active" ? (
              <span className="text-small text-center badge badge-sm bg-success">
                Active
              </span>
            ) : row.status === "inactive" ? (
              <span className="text-small text-center badge badge-sm bg-danger">
                Inactive
              </span>
            ) : (
              ""
            )}
          </div>
        ),
        enableClickToCopy: true,
        size: 50,
        header: "Status",
      },
      {
        id: "id", //access nested data with dot notation
        accessorFn: (row) => (
          <>
            <button
              className="btn btn-link text-info mb-0"
              onClick={() => {
                handleOpenUpdate(row.id, row);
              }}
            >
              <i className="ti ti-edit"></i>
            </button>
            <button
              className="btn btn-link text-info mb-0"
              onClick={() => {
                setShowUnlock(true);
              }}
            >
              <i className="fa fa-unlock"></i>
            </button>
            <button className="btn btn-link text-info mb-0" onClick={()=>{handleBranchesShow(row)}}>
              <i className="ti ti-home"></i>
            </button>
          </>
        ),
        enableClickToCopy: false,
        size: 50,
        header: "Action",
      },
    ],
    []
  );
  const [userid, setUserId] = useState("");
  const [userData, setUserData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const handleOpen = () => setShowModal(true);
  const handleOpenUpdate = (id, data) => {
    console.log(data)
    setShowModalUpdate(true);
    setUserId(id);
    setUserData(data);
  };
  const handleClose = () => setShowModal(false);
  const handleCloseUpdate = () => {
    setShowModalUpdate(false);
    setUserId("");
     setUserData([]);
  };

  const [branchesShowAttach, setBranchesShowAttach] = useState(false);
  const handleBranchesShow = (data) => {setBranchesShowAttach(true); setUserData(data);};
  const handleBranchesClose = () => {setBranchesShowAttach(false); setUserData([])}; 

  return (
    <>
      <div className="container-fluid note-has-grid">
        <div className="card bg-light-danger shadow-none position-relative overflow-hidden mt-md-0 mt-5">
          <div className="card-body px-4 py-3 ">
            <div className="row align-items-center">
              <div className="col-12">
                <h5 className="fw-semibold mb-8">User Accounts</h5>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        className="text-muted text-decoration-none"
                        to="/dashboard"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      User Accounts
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fuild">
          <div className="card">
            <div className="card-header row">
              <div className="col-md-6 col-sm-12">
                <h5 className="fw-semibold mb-8">User Accounts</h5>
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-primary float-end"
                  onClick={handleOpen}
                >
                  Add New User
                </button>
                <AddNewUser
                  getUsers={getUsers}
                  handleClose={handleClose}
                  showModal={showModal}
                  branches={branches}
                />
              </div>
            </div>
            <div className="card-body p-0">
              <div className="row">
                <div className="col-md-12">
                  <Table
                    columns={columns}
                    data={users}
                    isLoading={isLoading}
                    refetch={refetch}
                    // height={"150px"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        message="Are you sure you want to delete this user?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
      <ConfirmModal
        message="Are you sure you want to unlock this user? "
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={showUnlock}
        setShow={setShowUnlock}
      />
      {roles && showModalUpdate && (
        <UpdateUserRole
          handleAprroveClose={handleCloseUpdate}
          showApproveModal={showModalUpdate}
          getUsers={getUsers}
          userid={userid}
          roles={roles}
          branches={branches}
          userData={userData}
        />
      )}
      {branchesShowAttach && (
        <AddBranchesUser
          handleAprroveClose={handleBranchesClose}
          showApproveModal={branchesShowAttach}
          getUsers={getUsers}
          branches={branches}
          userData={userData}
        />
      )}
    </>
  );
};

export default StaffAccounts;
