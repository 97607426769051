import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Table from "pages/components/common_table";
import SendBulkSMS from "./components/send-sms";

const BulkSMS = () => {
  const controller = new AbortController();
  
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [sms, setSMS] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getSMS = async () => {
    try {
      const response = await axiosPrivate.get("/sms/general", {
        signal: controller.signal,
      });
      setSMS(response.data.data.sms);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getSMS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = async () => {
    setIsLoading(true);
    await getSMS();
  };


  const columns = useMemo(
    () => [
      {
        id: "contact", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Contact",
        accessorFn: (row) => row.contact,
      },
      {
        accessorKey: "body", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Message",
      },

      {
        id: "status", //access nested data with dot notation
        accessorFn: (row) => (
          <div className="">
            {row.status === "y" ? (
              <span className="text-small text-center badge badge-sm bg-success">
                Sent
              </span>
            ) : row.status === "n" ? (
              <span className="text-small text-center badge badge-sm bg-danger">
                Failed
              </span>
            ) : (
              ""
            )}
          </div>
        ),
        enableClickToCopy: true,
        size: 50,
        header: "Status",
      },
      {
        accessorKey: "timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Timestamp",
      },
    ],
    []
  );

  const [showModal, setShowModal] = useState(false);
  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
     
          <div className="container-fluid note-has-grid">
            <div className="card bg-light-info shadow-none position-relative overflow-hidden mt-md-0 mt-5">
              <div className="card-body px-4 py-3 ">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h5 className="fw-semibold mb-8">Bulk SMS</h5>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            className="text-muted text-decoration-none"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          Bulk SMS
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fuild">
              <div className="card">
                <div className="card-header row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="fw-semibold mb-8">Bulk SMS</h5>
                  </div>
                  <div className="col-md-6">
                    <button
                      className="btn btn-primary float-end"
                      onClick={handleOpen}
                    >
                      Send SMS
                    </button>
                   <SendBulkSMS 
                   handleClose={handleClose}
                   getSMS={getSMS}
                   showModal={showModal}

                   />
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-md-12">
                      <Table
                        columns={columns}
                        data={sms}
                        isLoading={isLoading}
                        refetch={refetch}
                        // height={"150px"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
    </>
  );
};

export default BulkSMS;
