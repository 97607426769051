import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Table from "pages/components/common_table";
import AddIncomes from "./components/add-income";
const Incomes = ({roles}) => {
  const controller = new AbortController();

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [incomes, setIncomes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getIncomes = async () => {
    try {
      const response = await axiosPrivate.get("/incomes", {
        signal: controller.signal,
      });
      setIncomes(response.data.data.incomes);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getIncomes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = async () => {
    setIsLoading(true);
    await getIncomes();
  };


  const columns = useMemo(
    () => [
      {
        id: "title", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Title",
        accessorFn: (row) => row.name,
      },
      {
        accessorKey: "description", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Description",
      },
      {
        id: "amount", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Amount",
        accessorFn: (row) => row.amount.toLocaleString(),
      },
      {
        accessorKey: "date", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Date",
      },
      {
        accessorKey: "account", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Account",
      },

      {
        accessorKey: "timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Timestamp",
      },
      {
        id: "user", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "User",
        accessorFn: (row) => `${row.user.firstname} ${row.user.lastname}`,
      },
      {
        id: "action", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Action",
        accessorFn: (row) => <div>No action</div>,
      },
    ],
    []
  );

  const [showModal, setShowModal] = useState(false);
  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      
          <div className="container-fluid note-has-grid">
            <div className="card bg-light-success shadow-none position-relative overflow-hidden mt-md-0 mt-5">
              <div className="card-body px-4 py-3 ">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h5 className="fw-semibold mb-8">Incomes</h5>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            className="text-muted text-decoration-none"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          Incomes
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fuild">
              <div className="card">
                <div className="card-header row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="fw-semibold mb-8">Incomes</h5>
                  </div>
                  <div className="col-md-6">
                    {roles.includes(100000109) &&
                    <button
                    className="btn btn-primary float-end"
                    onClick={handleOpen}
                    >
                      Add Income
                    </button>
                    }
                   <AddIncomes 
                   handleClose={handleClose}
                   getIncomes={getIncomes}
                   showModal={showModal}

                   />
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-md-12">
                      <Table
                        columns={columns}
                        data={incomes}
                        isLoading={isLoading}
                        refetch={refetch}
                        // height={"150px"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
    </>
  );
};

export default Incomes;
