import toast, { Toaster } from "react-hot-toast";
import {useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
const AddFixed = ({ getDeposits, handleClose, showModal }) => {
  const progress = new ProgressBar();
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const controller = new AbortController();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const [clients, setClients] = useState([]);
  const getClients = async () => {
    try {
      const response = await axiosPrivate.get(`/simple/clients`, {
        signal: controller.signal,
      });
      setClients(response?.data?.data?.clients);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSave = async (data) => {
    try {
      progress.start();

      setDisabled(true);
      let client = data.client.value;
      delete data.client;
      data.client = client;
      const response = await axiosPrivate.post("/deposits", data, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      handleClose();
      getDeposits();
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "60px",
      width: "240px",
      borderColor: "#cccccc",
      backgroundColor: "#fff",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      opacity: 1,
      zIndex: 9999,
    }),
  };
  const options = clients?.map((client) => ({
    value: `${client.id}`,
    label: `${client.firstname} ${client.lastname} - ${client.account} - ${client.contact}`,
  }));
  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Add Fixed Deposits
          </Modal.Title>
          <button onClick={handleClose} className="btn-close btn-danger">
            <i className="material-icons opacity-10"></i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="row">
              <div className="form-floating mb-1 col-6">
                <Controller
                  control={control}
                  name="client"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      {...register("client", { required: true })}
                      options={options}
                      onChange={onChange}
                      isMulti={false}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      searchable={true}
                      styles={customStyles}
                      placeholder="Please select client"
                      ref={ref}
                    />
                  )}
                />
                {errors?.client?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please select client
                  </p>
                )}
              </div>
              <div className="form-floating mb-1 col-6">
                <input
                  type="number"
                  className="form-control"
                  id="amount"
                  name="amount"
                  placeholder=""
                  {...register("amount", {
                    required: true,
                  })}
                />
                <label htmlFor="amount">Amount</label>
                {errors?.amount?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter amount
                  </p>
                )}
              </div>
              <div className="form-floating mb-1 col-6">
                <input
                  type="number"
                  className="form-control"
                  id="rate"
                  name="rate"
                  placeholder=""
                  {...register("rate", {
                    required: true,
                  })}
                />
                <label htmlFor="rate">Rate</label>
                {errors?.rate?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter rate
                  </p>
                )}
              </div>
              <div className="form-floating mb-1 col-6">
                <input
                  type="date"
                  className="form-control"
                  id="date"
                  name="date"
                  placeholder=""
                  {...register("date", {
                    required: true,
                  })}
                />
                <label htmlFor="date">Date</label>
                {errors?.date?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter date
                  </p>
                )}
              </div>
              <div className="form-floating mb-1 col-6">
                <input
                  type="number"
                  className="form-control"
                  id="length"
                  name="length"
                  placeholder=""
                  {...register("length", {
                    required: true,
                  })}
                />
                <label htmlFor="length">Length</label>
                {errors?.length?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter length
                  </p>
                )}
              </div>
              <div className="form-floating mb-1 col-6">
                <select
                  className="form-control"
                  id="period"
                  name="period"
                  placeholder=""
                  {...register("period", {
                    required: true,
                  })}
                >
                  <option value="day">Daily</option>
                  <option value="week">Weekly</option>
                  <option value="month">Monthly</option>
                  <option value="year">Yearly</option>
                </select>
                <label htmlFor="period">Period Interval</label>
                {errors?.period?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter period interval
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Submit"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddFixed;
