import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const LoanApplicationEdit = ({
  handleClose,
  showModal,
  loanAppData,
  getLoan,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const params = useParams();
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.patch(
        `/applications/${params.id}`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      handleClose();
      getLoan();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            <h4 className="text-center fs-5 container">
              Edit Loan Application
            </h4>
          </Modal.Title>
          <button onClick={handleClose} className="btn-close"></button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-1 form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="amount"
                    name="amount"
                    placeholder=""
                    {...register("amount", { required: true })}
                    defaultValue={loanAppData?.amount}
                  />
                  <label htmlFor="amount">Amount</label>
                  {errors?.amount?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter loan amount
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="fee"
                    name="fee"
                    {...register("fee", { required: true })}
                    defaultValue={loanAppData?.fee}
                  />
                  <label htmlFor="fee">Fee </label>
                  {errors?.fee?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter valid fee
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-1 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="method"
                    name="method"
                    {...register("method", { required: true })}
                    defaultValue={loanAppData?.method}
                  />
                  <label htmlFor="method">Method</label>
                  {errors?.method?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter method
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="rate"
                    name="rate"
                    {...register("rate", { required: true })}
                    defaultValue={loanAppData?.rate}
                  />
                  <label htmlFor="rate">Rate</label>
                  {errors?.rate?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter rate
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-1 form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="period"
                    name="period"
                    {...register("period", { required: true })}
                    defaultValue={loanAppData?.period}
                  />
                  <label htmlFor="period">Period</label>
                  {errors?.period?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter valid period
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="officer"
                    name="officer"
                    disabled
                    defaultValue={`${loanAppData?.officer?.firstname} ${loanAppData?.officer?.lastname}`}
                  />
                  <label htmlFor="officer">Officer</label>
                </div>
              </div>

              <div className="col-lg-12 mb-2 form-floating">
                <textarea
                  type="text"
                  className="form-control form-control-lg"
                  id="info"
                  name="info"
                  {...register("info")}
                  defaultValue={loanAppData?.wintess?.information}
                  style={{ border: "solid 1px #ccc" }}
                ></textarea>
                <label htmlFor="info" className="px-4">
                  More Information
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-danger"
            >
              {!disabled && "Update"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default LoanApplicationEdit;
