import { useMemo, useState } from "react";
import Table from "pages/components/common_table";
import { Modal } from "react-bootstrap";

const ClientSmsTable = ({ data, refetch, isLoading }) => {
  const [message, setMessage] = useState("");
  const columns = useMemo(
    () => [
      {
        id: "contact", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Contact",
        accessorFn: (row) => row.contact,
      },
      {
        id: "status", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Status",
        accessorFn: (row) =>
          row.status === "y" ? (
            <div className="badge badge-sm text-small bg-success">Sent</div>
          ) : (
            <div className="badge badge-sm text-small bg-info">Pending</div>
          ),
      },

      {
        id: "timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Timestamp",
        accessorFn: (row) => row.timestamp,
      },

      {
        id: "id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Action",
        accessorFn: (row) => (
          <div className="align-middle text-center text-sm">
            <button
              className="btn btn-danger"
              onClick={() => handleShow(row.body)}
            >
              <i className="ti ti-eye"></i>
            </button>
          </div>
        ),
      },
    ],
    []
  );
  const [show, setShow] = useState(false);
  const handleShow = (message) => {
    setShow(true);
    setMessage(message);
  };
  const handleClose = () => {
    setShow(false);
    setMessage("");
  };
  return (
    <>
      <div className="border-none">
        <div>
          <h6 className="fw-semibold p-4 text-center">SMS History</h6>
          <Table
            columns={columns}
            data={data ?? []}
            isLoading={isLoading}
            refetch={refetch}
          />
        </div>
      </div>

      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <button
            onClick={handleClose}
            className="btn-close btn-danger"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <textarea
            type="text"
            disabled
            defaultValue={message}
            className="form-control"
            rows={5}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-dark" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientSmsTable;
