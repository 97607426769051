import { useState, useMemo } from "react";
import Table from "pages/components/common_table";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Modal } from "react-bootstrap";
import ConfirmModal from "pages/components/confirmModal";
import toast from "react-hot-toast";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
const ClientDocuments = ({ documents, refetch, isLoading, roles }) => {
  const [openFileModal, setOpenFileModal] = useState(false);
  const [documentFile, setDocumentFile] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
  const handleOpenFileModal = (data) => {
    setOpenFileModal(true);
    setDocumentFile(data);
  };
  const handleCloseFileModal = (data) => {
    setOpenFileModal(false);
    setDocumentFile([]);
  };
  const [id, setId] = useState([]);
    const [show, setShow] = useState(false);

    const handleConfirm = async () => {
      try {
        const controller = new AbortController();
        const response = await axiosPrivate.delete("/clients/documents/" + id, {
          signal: controller.signal,
        });
        toast.success(response?.data?.messages);
        refetch();
      } catch (error) {
        if (!error?.response) {
          toast("No server response");
        } else {
          console.log(error?.response);
          if (error?.response?.status === 401) {
            navigate("/login", {
              state: { from: location },
              replace: true,
            });
          }
          toast.error(error?.response?.data?.messages, {
            style: {
              background: "#e91e63",
              fontSize: "13px",
              color: "#fff",
            },
          });
        }
      }
    };
    const handleCancel = async () => {
      setId([]);
    };
  const columns = useMemo(
    () => [
      {
        id: "title", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Title",
        accessorFn: (row) => row.title,
      },
      {
        id: "timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Timestamp",
        accessorFn: (row) => row.timestamp,
      },

      {
        id: "url", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Document",
        accessorFn: (row) => (
          <div className="align-middle text-center text-sm">
            {roles.includes(10000028) && (
              <button
                className="btn btn-link text-info"
                onClick={() => handleOpenFileModal([{ uri: row.url }])}
              >
                <i className="ti ti-file-filled"></i>
              </button>
            )}
          </div>
        ),
      },
      {
        id: "id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Action",
        accessorFn: (row) => (
          <div className="align-middle text-center text-sm">
            {roles.includes(10000029) &&
            <button
            className="btn btn-danger"
            onClick={() => {
              setId(row.id);
              setShow(true);
              }}
              >
              <i className="ti ti-trash-x"></i>
            </button>
            }
          </div>
        ),
      },
    ],
    [roles]
  );
  return (
    <>
      <div className="border-none">
        <div>
          <h6 className="fw-semibold p-4 text-center">Documents</h6>
          <Table
            columns={columns}
            data={documents}
            isLoading={isLoading}
            refetch={refetch}
          />
        </div>
      </div>
      <ConfirmModal
        message="Are you sure you want to delete this document?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />

      <Modal
        show={openFileModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseFileModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            View File
          </Modal.Title>
          <button
            onClick={handleCloseFileModal}
            className="btn-close btn-danger"
          ></button>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={documentFile}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-dark"
            onClick={handleCloseFileModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientDocuments;
