import Table from "pages/components/common_table";
import { useMemo, useState } from "react";
import ConfirmModal from "pages/components/confirmModal";
const LoanGuarantorTable = ({ data, refetch, isLoading, roles }) => {
  const [show, setShow] = useState(false);
  const handleConfirm = () => setShow(false);
  const handleCancel = () => setShow(false);
  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Fullname",
      },
      {
        accessorKey: "contact", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Contact",
      },
      {
        accessorKey: "nin", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Identification",
      },
      {
        accessorKey: "occupation", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Occupation",
      },
      {
        accessorKey: "location", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Location",
      },
      {
        accessorKey: "description", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "More Notes",
      },

      {
        id: "id", //access nested data with dot notation
        accessorFn: (row) => (
          <div className="">
             {roles.includes(10000061) && (
            <button
              className="btn btn-link text-danger mb-0"
              onClick={() => {
                setShow(true);
              }}
            >
              <i className="ti ti-trash"></i>
            </button>
             )}
          </div>
        ),
        enableClickToCopy: false,
        size: 50,
        header: "Action",
      },
    ],
    [roles]
  );
  return (
    <>
      <div className="border-none">
        <div>
          <h6 className="fw-semibold p-4 text-center">Guarantors</h6>
          <Table
            columns={columns}
            data={data}
            refetch={refetch}
            isLoading={isLoading}
          />
        </div>
      </div>
      <ConfirmModal
        message="Are you sure you want to delete guarantor?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

export default LoanGuarantorTable;
