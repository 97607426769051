import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const AddNewUser = ({getUsers, handleClose, showModal, branches}) => {
  const progress = new ProgressBar();
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const controller = new AbortController();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.post("/users", data, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      handleClose();
      getUsers();
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const [roles, setRoles] = useState([]);
  const getRoles = async() => {
     try {
      const response = await axiosPrivate.get("/roles", {
        signal: controller.signal,
      });
      setRoles(response.data.data.role);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  }

  useEffect ( () => {
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Add New User
          </Modal.Title>
          <button onClick={handleClose} className="btn-close btn-danger">
            <i className="material-icons opacity-10"></i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6">
                <div className="form-floating mb-1">
                  <input
                    type="email"
                    className="form-control"
                    id="username"
                    name="username"
                    placeholder=""
                    {...register("username", {
                      required: true,
                      pattern: { value: /^\S+@\S+$/i },
                    })}
                  />
                  <label htmlFor="username">Email</label>
                  {errors?.username?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter email
                    </p>
                  )}
                  {errors?.username?.type === "pattern" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter valid username
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <label htmlFor="role" className="text-small p-2">
                    Select Role
                  </label>
                  <select
                    className="form-control"
                    id="role"
                    placeholder=""
                    name="role"
                    {...register("role", { required: true })}
                  >
                    {roles?.map((role) => (
                      <option value={role.id} key={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                  {errors?.role?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please select role
                    </p>
                  )}
                </div>
                <div className="mb-1 form-floating">
                  <label htmlFor="branch" className="text-small p-2">
                    Select branch
                  </label>
                  <select
                    className="form-control"
                    id="branch"
                    placeholder=""
                    name="branch"
                    {...register("branch", { required: true })}
                  >
                    {branches?.map((branch) => (
                      <option value={branch.id} key={branch.id}>
                        {branch.name}
                      </option>
                    ))}
                  </select>
                  {errors?.branch?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please select branch
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-1">
                  <input
                    type="text"
                    className="form-control"
                    id="firstname"
                    name="firstname"
                    placeholder=""
                    {...register("firstname", {
                      required: true,
                    })}
                  />
                  <label htmlFor="firstname">Firstname</label>
                  {errors?.firstname?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter firstname
                    </p>
                  )}
                </div>
                <div className="form-floating mb-1">
                  <input
                    type="text"
                    className="form-control"
                    id="lastname"
                    name="lastname"
                    placeholder=""
                    {...register("lastname", {
                      required: true,
                    })}
                  />
                  <label htmlFor="lastname">Lastname</label>
                  {errors?.lastname?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter lastname
                    </p>
                  )}
                </div>

                <div className="form-floating mb-1">
                  <input
                    type="tel"
                    className="form-control"
                    id="contact"
                    name="contact"
                    placeholder=""
                    {...register("contact", {
                      required: true,
                    })}
                  />
                  <label htmlFor="contact">Contact</label>
                  {errors?.contact?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter contact
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Submit"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddNewUser;
