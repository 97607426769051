import { useState, useMemo } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import ConfirmModal from "pages/components/confirmModal";
import toast, { Toaster } from "react-hot-toast";
import AddClientBranch from "./components/add-client-branch";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here

const ClientsBranch = ({ roles }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  // const [clients, setClients] = useState([]);
  const [showModal, setShowModal] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState([]);
 
  const columns = useMemo(
    () => [
      {
        id: "client", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Client Name",
        accessorFn: (row) => `${row.client_firstname} ${row.client_lastname}`,
      },
      {
        id: "client_account_number", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Client Account Info",
        accessorFn: (row) =>
          `${row.client_account_number} - ${row.client_contact}`,
      },

      {
        id: "info", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "More Info",
        accessorFn: (row) =>
          `${row.client_identification} - ${
            row.client_gender === "male" ? "Male" : "Female"
          }`,
      },
      {
        id: "status", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Status",
        accessorFn: (row) => (
          <div className="align-center text-sm">
            {row.client_status === "active" ? (
              <span className="text-small text-center badge badge-sm bg-success">
                Active
              </span>
            ) : row.client_status === "inactive" ? (
              <span className="text-small text-center badge badge-sm bg-warning">
                Inactive
              </span>
            ) : (
              <span className="text-small text-center badge badge-sm bg-danger">
                Blocked
              </span>
            )}
          </div>
        ),
      },
      {
        id: "client_address", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "DOB - Location",
        accessorFn: (row) => (
          <div className="align-middle text-start text-sm">
            <span className="text-dark text-xs font-weight-bold">
              {row.client_dob}
            </span>
            <p className="text-xs text-dark mb-0">{row.client_address}</p>
          </div>
        ),
      },
      {
        id: "client_id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Action",
        accessorFn: (row) => (
          <>
            {roles?.includes(10000022) && (
              <Link
                to={`/clients/${row?.client_id}`}
                className="text-dark font-weight-bold text-xs"
              >
                view
              </Link>
            )}
            {roles?.includes(10000024) && (
              <button
                className="btn btn-link text-danger mb-0"
                onClick={() => {
                  setId(row.client_id);
                  setShow(true);
                }}
              >
                <i className="ti ti-trash"></i>
              </button>
            )}
          </>
        ),
      },
    ],
    [roles]
  );

  // eslint-disable-next-line
  const [columnFilters, setColumnFilters] = useState([]);
  // eslint-disable-next-line
  const [globalFilter, setGlobalFilter] = useState("");
  // eslint-disable-next-line
  const [sorting, setSorting] = useState([]);
  // eslint-disable-next-line
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const {
    data: { data = [], meta } = {}, // your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "branch-table-data",
      columnFilters, // refetch when columnFilters changes
      globalFilter, // refetch when globalFilter changes
      pagination.pageIndex, // refetch when pagination.pageIndex changes
      pagination.pageSize, // refetch when pagination.pageSize changes
      sorting, // refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = `server-logic/${params.id}/clients`;

      try {
        const response = await axiosPrivate.get(fetchURL, {
          params: {
            start: pagination.pageIndex * pagination.pageSize,
            size: pagination.pageSize,
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? "",
            sorting: JSON.stringify(sorting ?? []),
          },
        });

        // console.log(response.data.data);
        return response.data.data;
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        console.error("Axios request failed", error);
        throw error;
      }
    },
    placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
  });
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };
  const handleConfirm = async () => {
    try {
      const controller = new AbortController();
      const response = await axiosPrivate.delete("/clients/" + id, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      refetch();
    } catch (error) {
      if (!error?.response) {
        toast("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/login", {
            state: { from: location },
            replace: true,
          });
        }
        toast.error(error?.response?.data?.messages, {
          style: {
            background: "#e91e63",
            fontSize: "13px",
            color: "#fff",
          },
        });
      }
    }
  };
  const handleCancel = async () => {
    setId([]);
  };
  
  return (
    <>
      <Toaster />

      <div className="container-fluid note-has-grid">
        <div className="card bg-light-info shadow-none position-relative overflow-hidden mt-md-0 mt-5">
          <div className="card-body px-4 py-3 ">
            <div className="row align-items-center">
              <div className="col-12">
                <h5 className="fw-semibold mb-8">Clients Branch</h5>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        className="text-muted text-decoration-none"
                        to="/dashboard"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Clients Branch
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fuild">
          <div className="card">
            <div className="card-header row">
              <div className="col-md-6 col-sm-12">
                <h5 className="fw-semibold mb-8">Clients Branch</h5>
              </div>
              <div className="col-md-6 col-sm-12">
                {roles?.includes(10000023) && (
                  <button
                    className="btn btn-primary float-end"
                    onClick={handleOpen}
                  >
                    Add Client
                  </button>
                )}
              </div>
            </div>
            <div className="card-body p-0">
              {/* <Table
                columns={columns}
                data={clients}
                isLoading={isLoading}
                refetch={refetch}
              /> */}
              <MaterialReactTable
                data={data}
                columns={columns}
                enableColumnFilterModes
                enableColumnOrdering
                enableRowSelection
                manualFiltering={true}
                manualPagination={true}
                manualSorting={true}
                enableGrouping
                enableStickyHeader
                className="table align-items-justify table-flush p-0 m-0"
                initialState={{
                  density: "compact",
                  showColumnFilters: false,
                }}
                // paginationDisplayMode={"pages"}
                muiPaginationProps={{
                  shape: "rounded",
                  sx: { fontFamily: "Nunito", fontSize: "10px" },
                }}
                // state={{ isLoading: isLoading }}
                muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                muiTableHeadCellProps={{
                  sx: {
                    fontWeight: "normal",
                    fontSize: "12px",
                    fontFamily: "Nunito",
                  },
                }}
                muiCircularProgressProps={{
                  color: "secondary",
                  thickness: 5,
                  size: 25,
                }}
                muiSkeletonProps={{
                  animation: "pulse",
                  height: 28,
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontWeight: "normal",
                    fontSize: "12px",
                    fontFamily: "Nunito",
                  },
                }}
                muiSearchTextFieldProps={{
                  placeholder: "Search",
                  sx: { fontFamily: "Nunito", fontSize: "12px" },
                }}
                renderTopToolbarCustomActions={({ table }) => (
                  <>
                    <div className="btn-group">
                      <button className="btn btn-sm btn-danger rounded-0">
                        <i className="fa fa-file-pdf"></i>
                      </button>

                      <button
                        className="btn btn-sm btn-success rounded-0"
                        disabled={table.getRowModel().rows.length === 0}
                        //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                        onClick={() =>
                          handleExportRows(table.getRowModel().rows)
                        }
                      >
                        <i className="fa fa-file-excel"></i>
                      </button>
                      <button className="btn btn-sm btn-info rounded-0">
                        <i className="fa fa-print"></i>
                      </button>
                      <button
                        onClick={refetch}
                        className="btn btn-sm btn-dark rounded-0"
                      >
                        <i className="fa"> &#xf021;</i>
                      </button>
                    </div>
                  </>
                )}
                rowCount={meta?.totalRowCount ?? 0}
                state={{
                  columnFilters,
                  globalFilter,
                  isLoading,
                  pagination,
                  showAlertBanner: isError,
                  showProgressBars: isRefetching,
                  sorting,
                }}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
              />
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        message="Are you sure you want to delete this client?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />

      <AddClientBranch
        getClients={refetch}
        showModal={showModal}
        handleClose={handleClose}
      />
    </>
  );
};

export default ClientsBranch;
