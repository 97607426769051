import { useEffect, useState, useMemo } from "react";
import Loader from "services/loaders/loader";
import { useNavigate, useLocation, Link } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Table from "pages/components/common_table";
import MonthlyPayments from "../components/monthlyPaymentsLineGraph";
import MonthlyLoansDisbursed from "../components/monthlyLoansBarGraph";
import ClientsPieChart from "../components/clientsPieChart";
const BranchManagerDasboard = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const controller = new AbortController();
  const [transactions, setTransactions] = useState([]);

  const getDashboardValues = async () => {
    try {
      const response = await axiosPrivate.get("/dashboard", {
        signal: controller.signal,
      });
      setData(response.data.data);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const getTransansactionsData = async () => {
    try {
      const response = await axiosPrivate.get("/transactions", {
        signal: controller.signal,
      });
      // console.log(response.data.data.transactions);
      setTransactions(response.data.data.transactions);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const [loans, setLoans] = useState([]);
  const getDisurbsedLoans = async () => {
    try {
      const response = await axiosPrivate.get("/applications/approved", {
        signal: controller.signal,
      });
      // console.log(response.data.data.transactions);
      setLoans(response.data.data.loanApps);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const [clients, setClients] = useState([]);
  const getClients = async () => {
    try {
      const response = await axiosPrivate.get("/clients/branch", {
        signal: controller.signal,
      });
      // console.log(response.data.data.transactions);
      setClients(response.data.data.clients);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  
  useEffect(() => {
    getDashboardValues();
    getTransansactionsData();
    getDisurbsedLoans();
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = () => {
    setIsLoading(false);
    getDashboardValues();
    getTransansactionsData();
    getDisurbsedLoans();
  };

  const columns = useMemo(
    () => [
      {
        id: "client", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Client Name",
        accessorFn: (row) => `${row.client}`,
      },

      {
        id: "contact", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Client Contact",
        accessorFn: (row) => `${row.client_contact}`,
      },
      {
        id: "princiapl", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Principal",
        accessorFn: (row) => `${row.principal.toLocaleString()}`,
      },
      {
        id: "due_amount", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Amount Due",
        accessorFn: (row) => `${row.amount_been_paid.toLocaleString()}`,
      },
      {
        id: "action", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Action",
        accessorFn: (row) => <Link to={`/loans/${row.id}`}>More</Link>,
      },
    ],
    []
  );
  return (
    <>
      <div className="container-fluid">
        <div className="row  mt-md-0 mt-5">
          <div className="col-md-3 col-sm-12">
            <div className="card border-0 zoom-in bg-light-primary shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-primary mb-1"> Groups </p>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <h5 className="fw-semibold text-primary mb-0">
                      {data?.groups}
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="card border-0 zoom-in bg-light-warning shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-warning mb-1">Clients</p>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <h5 className="fw-semibold text-warning mb-0">
                      {clients.length}
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="card border-0 zoom-in bg-light-info shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-info mb-1">
                    Active Loans
                  </p>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <h5 className="fw-semibold text-warning mb-0">
                      {data?.active_loans_branch}
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="card border-0 zoom-in bg-light-danger shadow-none">
              <div className="card-body">
                <div className="text-center">
                  {/* <img
                        src={""}
                        width="50"
                        height="50"
                        className="mb-3"
                        alt=""
                      /> */}
                  <p className="fw-semibold fs-3 text-danger mb-1">
                    Overdue Loans
                  </p>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <h5 className="fw-semibold text-warning mb-0">
                      {data?.over_due_branch}
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 d-flex align-items-stretch">
            <div className="card w-100 bg-light-white overflow-hidden shadow-none">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-md-6 card">
                    <div className="card-header h5 text-center">
                      Monthly Payments
                    </div>
                    <div className="card-body p-0">
                      {transactions && (
                        <MonthlyPayments transactions={transactions} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 card">
                    <div className="card-header h5 text-center">
                      Monthly Loans Disbursed
                    </div>
                    <div className="card-body p-0">
                      {loans && <MonthlyLoansDisbursed transactions={loans} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-lg-12 d-flex align-items-stretch">
            <div className="card w-100">
              <div className="card-header fw-semibold">Overdue Loans</div>
              <div className="card-body p-0">
                <Table
                  columns={columns}
                  data={data?.over_due_loans_branch ?? []}
                  isLoading={isLoading}
                  refetch={refetch}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 d-flex align-items-stretch">
            <div className="card w-100 bg-light-white overflow-hidden shadow-none">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-md-12 card">
                    <div className="card-header h5 text-center">
                      Clients By Gender
                    </div>
                    <div className="card-body">
                      <div
                        style={{
                          height: "250px",
                        }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        {clients && <ClientsPieChart transactions={clients} />}
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BranchManagerDasboard;
