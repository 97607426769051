import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import axios from "config/axios";
import fileDownload from "js-file-download";
const LoanCalculator = ({ handleLoanClose, showLoanModal }) => {
    const customStyles = {
      modalBody: {
        maxHeight: "500px", // Set your desired max height here
        overflowY: "auto", // Enable vertical scrolling if content exceeds the max height
      },
    };
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const [disabled, setDisabled] = useState(false);
  const [calcData, setCalcData] = useState([]);
  const [showModalCalc, setShowModalCalc] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const handleModalOpen = () => setShowModalCalc(true);
  const handleModalClose = () => setShowModalCalc(false);
  const onCalculate = async (data) => {
    // console.log(data)
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post("loanschedule", data, {
        signal: controller.signal,
      });
    //   console.log(response.data.data)
      progress.finish();
      setDisabled(false);
      reset();
      setCalcData(response.data.data);
      handleLoanClose();
      handleModalOpen();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  let interest = 0;
  let principal = 0;

  for (let i = 0; i < calcData?.length; i++) {
    interest += calcData[i].interest;
    principal += calcData[i].principal;
  }
  const generatePDF = async (calcData) => {
    try {
      const data = {
        data: calcData,
        interest: interest,
        principal: principal,
      };
    //   console.log(data);
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axios.post(
        `/export/reports/loans/calculator`,
        { data: data },
        { responseType: "blob" },
        {
          signal: controller.signal,
        }
      );
      // console.log(response.data);
      const download_title = "loan_calculation.pdf";
      fileDownload(response.data, download_title);
      progress.finish();
      setDisabled(false);
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Modal
        show={showLoanModal}
        backdrop="static"
        keyboard={false}
        onHide={handleLoanClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Loan Calculator{" "}
          </Modal.Title>
          <button
            onClick={handleLoanClose}
            className="btn-close btn-danger"
          ></button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onCalculate)}>
          <Modal.Body>
            <div className="container-fluid row">
              <div className="col-md-6">
                <div className="mb-1 form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="amount"
                    name="amount"
                    placeholder=""
                    {...register("amount", { required: true })}
                  />
                  <label htmlFor="amount">Application Amount</label>
                  {errors?.amount?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter applicatiton Amount
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <label htmlFor="type" className="text-small p-2">
                    Interest Type
                  </label>
                  <select
                    className="form-control"
                    id="type"
                    name="type"
                    {...register("type", { required: true })}
                  >
                    <option value=""></option>
                    <option value="fixed">Fixed Interest</option>
                    <option value="reducing_balance">Reducing Balance</option>
                  </select>
                  {errors?.type?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please select type
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <label htmlFor="frequency" className="text-small p-2">
                    Frequency
                  </label>
                  <select
                    className="form-control"
                    id="frequency"
                    name="frequency"
                    {...register("frequency", { required: true })}
                  >
                    <option value=""></option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
                {errors?.frequency?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    Please Select Frequency
                  </p>
                )}
              </div>
              <div className="col-md-6">
                <div className="mb-1 form-floating">
                  <label htmlFor="date" className="text-small p-2">
                    Start Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="date"
                    name="date"
                    placeholder=""
                    {...register("date", { required: true })}
                  />
                  {errors?.date?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter start date
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="rate"
                    name="rate"
                    placeholder=""
                    {...register("rate", { required: true })}
                  />
                  {errors?.rate?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter interest rate
                    </p>
                  )}
                  <label htmlFor="rate">Interest Rate</label>
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="period"
                    name="period"
                    placeholder=""
                    {...register("period", { required: true })}
                  />
                  {errors?.period?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter Payment Period
                    </p>
                  )}
                  <label htmlFor="rate">Payment Period</label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleLoanClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Calculate"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        show={showModalCalc}
        backdrop="static"
        keyboard={false}
        onHide={handleModalClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Loan Calculation{" "}
          </Modal.Title>
          <button
            onClick={handleModalClose}
            className="btn-close btn-danger"
          ></button>
        </Modal.Header>

        <Modal.Body style={customStyles.modalBody}>
          <div className="container-fliud">
            <table className="table table-bordered table-striped table-hover text-sm">
              <thead>
                <tr className="text-center bg-dark">
                  <th>Period</th>
                  <th>Due Date</th>
                  <th>Interest</th>
                  <th>Principal</th>
                  <th>Payment</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {calcData?.map((data) => (
                  <tr key={data.period}>
                    <td> {data.period} </td>
                    <td> {data.date} </td>
                    <td> {data.interest.toLocaleString()} </td>
                    <td> {data.principal.toLocaleString()} </td>
                    <td> {data?.payment?.toLocaleString()} </td>
                    <td> {data.balance.toLocaleString()} </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="d-flex flex-row-reverse text-dark p-0">
              <div className="py-3 px-2 text-center text-sm">
                <div className="mb-2">Total Interest</div>
                <div className="h6 font-weight-light">
                  {" "}
                  {(Math.round(interest / 100) * 100).toLocaleString()}{" "}
                </div>
              </div>
              <div className="py-3 px-2 text-center text-sm">
                <div className="mb-2">Total Principal</div>
                <div className="h6 font-weight-light">
                  {" "}
                  {(Math.round(principal / 100) * 100).toLocaleString()}{" "}
                </div>
              </div>
              <div className="py-3 px-2 text-center text-sm">
                <div className="mb-2">Total Amount Paid</div>
                <div className="h6 font-weight-light">
                  {" "}
                  {(
                    Math.round(parseInt(interest + principal) / 100) * 100
                  ).toLocaleString()}{" "}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-dark"
            onClick={handleModalClose}
          >
            Close
          </button>
          <button disabled={disabled} onClick={() => {generatePDF(calcData);}} type="submit" className="btn btn-primary">
            {!disabled && "Download"} {disabled && "Please Wait"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoanCalculator;
