
import { Link } from "react-router-dom";

const CommunicationHome = () => {
 

 

  return (
    <>
     
          <div className="container-fluid note-has-grid">
            <div className="card bg-light-dark text-white shadow-none position-relative overflow-hidden mt-md-0 mt-5">
              <div className="card-body px-4 py-3">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h5 className="fw-semibold mb-8 text-white">
                      Communication Reports
                    </h5>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            className="text-muted text-decoration-none text-white"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          Communication Reports
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fuild">
              <div className="card">
                <div className="card-body row">
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h5 text-center">
                        SMS Reports
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/communication/sms"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h5 text-center">
                        Emails Reports
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/communication/emails"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
    </>
  );
};

export default CommunicationHome;
