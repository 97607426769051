import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import toast, {Toaster} from "react-hot-toast";
import { useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const LoanPayment = ({
  handleModalClose,
  showModal,
  schedule_id,
  getLoanSchedule,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const params = useParams();
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onPayment = async (data) => {
    // console.log(data.amount)
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        `active-loans/${params.id}`,
        JSON.stringify({ amount: data.amount, schedule: schedule_id }),
        {
          signal: controller.signal,
        }
      );
      console.log(response.data)
      toast.success(response.data.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleModalClose();
      getLoanSchedule();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  return (
    <>
    <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleModalClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Loan Payment{" "}
          </Modal.Title>
          <button onClick={handleModalClose} className="btn-close btn-danger">
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onPayment)}>
          <Modal.Body>
            <div className="">
            
              <div className="mb-1 form-floating">
                <input
                  type="number"
                  className="form-control"
                  id="amount"
                  name="amount"
                  placeholder="Amount To Pay *"
                  {...register("amount", { required: true })}
                />
                <label htmlFor="amount">Amount</label>
                {errors?.amount?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    Please enter valid amount
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleModalClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Pay"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default LoanPayment;
