import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useForm, Controller } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Select from "react-select";

const CLIENT_URL = "/add-branch-client";

const AddClientBranch = ({ handleClose, showModal, getClients }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const params = useParams();

  const [disabled, setDisabled] = useState(false);
  const [group, setGroup] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const controller = new AbortController();

  const getGroups = async () => {
    try {
      const response = await axiosPrivate.get("/clients/groups", {
        signal: controller.signal,
      });
      setGroup(response.data.data.groups);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = group?.map((group) => ({
    value: `${group.id}`,
    label: `${group.title}`,
  }));
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "55px",
      width: "240px", 
      borderColor: "#ccc",
    }),
  };
  const onSave = async (data) => {
    
    data.branchid = params.id;
      try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(CLIENT_URL, data, {
        signal: controller.signal,
      });
      // console.log(response?.data)
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleClose();
      getClients();
    } catch (error) {
      if (!error?.response) {
        // console.log(error)
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Add New Client
          </Modal.Title>
          <button onClick={handleClose} className="btn-close btn-danger">
            <i className="material-icons opacity-10"></i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-4">
                <div className="form-floating mb-1">
                  <input
                    type="text"
                    className="form-control"
                    id="firstname"
                    name="firstname"
                    placeholder=""
                    {...register("firstname", { required: true })}
                  />
                  <label htmlFor="firstname">Surname</label>
                  {errors?.firstname?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter surname
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <label htmlFor="gender" className="text-small p-2">
                    Select Gender
                  </label>
                  <select
                    className="form-control"
                    id="gender"
                    placeholder=""
                    name="gender"
                    {...register("gender", { required: true })}
                  >
                    <option value=""></option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  {errors?.gender?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please select gender
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder=""
                    {...register("email", {
                      pattern: { value: /^\S+@\S+$/i },
                    })}
                  />
                  <label htmlFor="email">Email</label>
                  {errors?.email?.type === "pattern" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter valid mail or leave blank
                    </p>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-1 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="lastname"
                    name="lastname"
                    placeholder=""
                    {...register("lastname", { required: true })}
                  />
                  {errors?.lastname?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter Givenname
                    </p>
                  )}
                  <label htmlFor="firstname">Givenname</label>
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="date"
                    className="form-control"
                    id="dob"
                    placeholder="Date Of Birth"
                    name="dob"
                    {...register("dob", { required: true })}
                  />
                  <label htmlFor="dob">Date Of Birth</label>

                  {errors?.dob?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter client date of birth
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    placeholder="Address"
                    {...register("address", { required: true })}
                  />
                  <label htmlFor="address">Address</label>

                  {errors?.address?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter valid address
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-1 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="identification"
                    name="identification"
                    placeholder="Identification"
                    {...register("identification", { required: true })}
                  />
                  {errors?.identification?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter passport number or NIN
                    </p>
                  )}
                  <label htmlFor="identification">Identification</label>
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="tel"
                    className="form-control"
                    id="contact"
                    aria-invalid={errors.contact ? "true" : "false"}
                    name="contact"
                    placeholder="Contact"
                    {...register("contact", { required: true })}
                  />
                  {errors?.contact?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter valid contact
                    </p>
                  )}
                  <label htmlFor="contact">Contact</label>
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="occupation"
                    name="occupation"
                    aria-invalid={errors.occupation ? "true" : "false"}
                    placeholder="Occupation"
                    {...register("occupation", { required: true })}
                  />
                  {errors?.occupation?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter occupation
                    </p>
                  )}
                  <label htmlFor="occupation">Occupation</label>
                </div>
              </div>

              <div className="col-lg-8 mb-1 form-floating">
                <textarea
                  type="text"
                  className="form-control"
                  id="otherinfo"
                  name="otherinfo"
                  placeholder=""
                  {...register("otherinfo")}
                  style={{ border: "solid 1px #ccc", height: "50px" }}
                ></textarea>
                <label htmlFor="otherinfo" className="px-4">More Information</label>
              </div>
              <div className="col-lg-4">
               
                <div className="mb-3 input-group input-group-outline">
                  <Controller
                    control={control}
                    name="group"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        {...register("group", { required: true })}
                        options={options}
                        onChange={onChange}
                        isMulti={false}
                        onBlur={onBlur}
                        value={value}
                        className="form-control-md"
                        name={name}
                        styles={customStyles}
                        placeholder="Select client group"
                        ref={ref}
                      />
                    )}
                  />
                  {errors?.group?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please select client group
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Save"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddClientBranch;
