import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import toast, {Toaster} from "react-hot-toast";
import { useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const LoanRollOver = ({
  handleModalClose,
  showModal,
  getLoanSchedule,
  getLoan
}) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const params = useParams();
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSave = async (data) => {
    data.loanid = params.id
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        `loan/rollover`,
        data,
        {
          signal: controller.signal,
        }
      );
      // console.log(response.data.data)
      toast.success(response.data.messages);
      progress.finish();
      setDisabled(false);
      reset();
      handleModalClose();
      getLoanSchedule();
      getLoan();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  return (
    <>
    <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleModalClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Loan RollOver{" "}
          </Modal.Title>
          <button onClick={handleModalClose} className="btn-close btn-danger">
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="">
            
              <div className="mb-1 form-floating">
                <input
                  type="date"
                  className="form-control"
                  id="date"
                  name="date"
                  placeholder=""
                  {...register("date", { required: true })}
                />
                <label htmlFor="date" className="text-small">Rollover Date</label>
                {errors?.date?.type === "required" && (
                  <p className="text-small m-0 text-start text-danger help-block">
                    Please enter date
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleModalClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-danger"
            >
              {!disabled && "RollOver"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default LoanRollOver;
