
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
const LoansFeesReport = () => {

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const controller = new AbortController();
  const [loans, setLoans] = useState([]);
  const [dates, setDates] = useState([]);

  const onQuery = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.post(
        `/reports/accounting/loans`,
        data,
        {
          signal: controller.signal,
        }
      );

      setLoans(response.data.data.loans.filter((loan) => loan.status === "approved"));
      setDates(response.data.data.dates);
      progress.finish();
      setDisabled(false);
      reset();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const total = loans
    .map((data) => data.amount)
    .reduce((a, b) => a + b, 0);
  return (
    <>
      <Toaster />
      <>

            <div className="container-fluid note-has-grid">
              <div className="card bg-light-dark text-white shadow-none position-relative overflow-hidden mt-md-0 mt-5">
                <div className="card-body px-4 py-3">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <h5 className="fw-semibold mb-8 text-white">
                        Loans Reports - Loans Processing Fees
                      </h5>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link
                              className="text-muted text-decoration-none text-white"
                              to="/dashboard"
                            >
                              Dashboard
                            </Link>
                          </li>
                          <li className="breadcrumb-item" aria-current="page">
                            Loans Processing Fees
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fuild">
                <div className="card">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card mb-5 mb-lg-0">
                        <div className="card-header bg-light-dark">
                          <h2 className="h5 p-1 text-bold text-uppercase text-center text-white">
                            Loans Processing Fees{" "}
                          </h2>
                        </div>
                        <div className="card-body mt-3 mb-3">
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <form
                                className="row needs-validation"
                                id="generalLegerForm"
                                onSubmit={handleSubmit(onQuery)}
                              >
                                <div className="form-group col-md-3">
                                  <label className="form-label">
                                    Select Start Date
                                  </label>
                                  <input
                                    type="date"
                                    placeholder="Select Start Date"
                                    className="form-control shadow border-1"
                                    {...register("start_date", {
                                      required: true,
                                    })}
                                    style={{ height: "45px" }}
                                  />
                                  {errors?.start_date?.type === "required" && (
                                    <p className="text-small m-0 text-danger help-block">
                                      This field is required
                                    </p>
                                  )}
                                </div>
                                <div className="form-group col-md-3">
                                  <label className="form-label">
                                    Select End Date
                                  </label>
                                  <input
                                    type="date"
                                    placeholder="Select End Date"
                                    className="form-control shadow border-1"
                                    {...register("end_date", {
                                      required: true,
                                    })}
                                    style={{ height: "45px" }}
                                  />
                                  {errors?.end_date?.type === "required" && (
                                    <p className="text-small m-0 text-danger help-block">
                                      This field is required
                                    </p>
                                  )}
                                </div>
                                <div className="form-group col-md-3 pt-3 mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-primary text-center rounded-0"
                                    disabled={disabled}
                                  >
                                    {!disabled && "Generate"}{" "}
                                    {disabled && "Please Wait ....."}
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="mt-3">
                            <table className="table table-hover table-bordered">
                              <thead>
                                <tr>
                                  <th className="text-left" colSpan="8">
                                    <div className="row">
                                      <p className="text-left col-4">
                                        Start Date : {dates?.start_date}
                                      </p>
                                      <p className="text-left col-4">
                                        End Date: {dates?.end_date}
                                      </p>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <thead>
                                <tr>
                                  <th>Date Of Application</th>
                                  <th>Loan Number</th>
                                  <th>Client</th>
                                  <th>Loan Frequency</th>
                                  <th>Loan Type</th>
                                  <th>Loan Interest Rate</th>
                                  <th>Loan Processing Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {loans?.map((loan) => (
                                  <tr key={loan?.id}>
                                    <td>{loan?.date}</td>
                                    <td>{loan?.reference}</td>
                                    <td>{loan?.client}</td>
                                    <td>{loan?.frequency}</td>
                                    <td>{loan?.type}</td>
                                    {/* <td>{loan?.comment}</td> */}
                                    <td>{loan?.interest}</td>
                                    <td>{loan?.loan_fee?.toLocaleString()}</td>
                                  </tr>
                                ))}
                                <tr>
                                  <th colSpan={6}>Total</th>
                                  <th>{total.toLocaleString()}</th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
      </>
    </>
  );
};

export default LoansFeesReport;
