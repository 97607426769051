import { Link } from "react-router-dom";

const LoansHome = () => {
  return (
    <>

          <div className="container-fluid note-has-grid">
            <div className="card bg-light-dark text-white shadow-none position-relative overflow-hidden mt-md-0 mt-5">
              <div className="card-body px-4 py-3">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h6 className="fw-semibold mb-8 text-white">
                      Loans Reports
                    </h6>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            className="text-muted text-decoration-none text-white"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          Loans Reports
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fuild">
              <div className="card">
                <div className="card-body row">
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">
                        Active Loans
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/loans/active-loans"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">
                        Pending Loans
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/loans/pending-loans"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">
                        Overdue Loans
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/loans/overdue-loans"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">
                        Settled Loans
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/loans/settled-loans"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">
                        Aging Loans
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/loans/aging-loans"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">
                        Loan Balances
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/loans/loan-balances"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">Interest On Loans</div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/loans/interest-loans"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">Loan Fees</div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/loans/loan-fees"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
    </>
  );
};

export default LoansHome;
