import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Table from "pages/components/common_table";
import ConfirmModal from "pages/components/confirmModal";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
const ClientGroups = () => {
  const controller = new AbortController();
 

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar();

  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const getGroups = async () => {
    try {
      const response = await axiosPrivate.get("/clients/groups", {
        signal: controller.signal,
      });
      setGroups(response.data.data.groups);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = async () => {
    setIsLoading(true);
    await getGroups();
  };
  const handleConfirm = async () => {};
  const handleCancel = async () => {};
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const columns = useMemo(
    () => [
      {
        accessorKey: "title", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Title",
      },
      {
        accessorKey: "description", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Description",
      },
      {
        id: "members", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Members",
        accessorFn: (row) => `${row?.members?.toLocaleString()}`,
      },

      {
        id: "status", //access nested data with dot notation
        accessorFn: (row) => (
          <div className="">
            {row.status === "active" ? (
              <span className="text-small text-center badge badge-sm bg-success">
                Active
              </span>
            ) : row.status === "inactive" ? (
              <span className="text-small text-center badge badge-sm bg-danger">
                Inactive
              </span>
            ) : (
              ""
            )}
          </div>
        ),
        enableClickToCopy: true,
        size: 50,
        header: "Status",
      },
      {
        id: "id", //access nested data with dot notation
        accessorFn: (row) => (
          <button
            className="btn btn-link text-danger mb-0"
            onClick={() => {
              setShow(true);
            }}
          >
            <i className="ti ti-trash"></i>
          </button>
        ),
        enableClickToCopy: false,
        size: 50,
        header: "Action",
      },
    ],
    []
  );
  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.post("/clients/groups", data, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      getGroups();
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  return (
    <>
      <Toaster />
          <div className="container-fluid note-has-grid">
            <div className="card bg-light-danger shadow-none position-relative overflow-hidden mt-md-0 mt-5">
              <div className="card-body px-4 py-3 ">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h5 className="fw-semibold mb-8">Client Groups</h5>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            className="text-muted text-decoration-none"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          Client groups
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fuild">
              <div className="card">
                <div className="card-header row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="fw-semibold mb-8">Client Groups</h5>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-md-8">
                      <Table
                        columns={columns}
                        data={groups}
                        isLoading={isLoading}
                        refetch={refetch}
                        height={"150px"}
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="card ">
                        <div className="card-header">Add New Client Group</div>
                        <div className="card-body p-1 mt-2">
                          <form
                            className="container"
                            noValidate
                            onSubmit={handleSubmit(onSave)}
                          >
                            <div className="mb-2 form-floating">
                              <input
                                type="text"
                                className="form-control px-2"
                                id="title"
                                name="title"
                                placeholder=" "
                                {...register("title", {
                                  required: true,
                                })}
                              />

                              <label htmlFor="title" className="px-2">
                                Client Groups Title
                              </label>
                              {errors?.title?.type === "required" && (
                                <p className="text-small m-0 text-start text-danger help-block">
                                  This Field is Required
                                </p>
                              )}
                            </div>
                            <div className="mb-2 form-floating">
                              <input
                                type="text"
                                className="form-control px-2"
                                id="description"
                                name="description"
                                placeholder=""
                                {...register("description", {
                                  required: true,
                                })}
                              />

                              <label htmlFor="description" className="px-2">
                                Client Groups Description
                              </label>
                              {errors?.description?.type === "required" && (
                                <p className="text-small m-0 text-start text-danger help-block">
                                  This Field is Required
                                </p>
                              )}
                            </div>

                            <button
                              className="btn btn-outline-primary float-end"
                              disabled={disabled}
                            >
                              {!disabled && "Save"} {disabled && "Please Wait"}
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
      <ConfirmModal
        message="Are you sure you want to delete this client group?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

export default ClientGroups;
