import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { Modal } from "react-bootstrap";

const SendBulkSMS = ({ getSMS, handleClose, showModal }) => {
  const progress = new ProgressBar();
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const controller = new AbortController();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.post("/sms/general", data, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      handleClose();
      getSMS();
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Send SMS
          </Modal.Title>
          <button onClick={handleClose} className="btn-close btn-danger">
            <i className="material-icons opacity-10"></i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="form-floating mb-1">
              <textarea
                type="text"
                className="form-control"
                id="message"
                name="message"
                placeholder=""
                style={{ height: "100px" }}
                {...register("message", {
                  required: true,
                })}
              />
              <label htmlFor="message">Message</label>
              {errors?.message?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter message
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Send"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default SendBulkSMS;
