import { Modal, Button } from "react-bootstrap";

const ConfirmModal = ({ message, onConfirm, onCancel, show, setShow }) => {

  const handleClose = () => {
    setShow(false);
    onCancel();
  };

  const handleConfirm = () => {
    setShow(false);
    onConfirm();
  };

  return (
    <Modal show={show} onHide={handleClose} centered >
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
