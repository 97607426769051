import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import ProgressBar from "@badrap/bar-of-progress";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
const AddGarantor = ({ handleClose, showModal, getLoan }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const params = useParams();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post("garantors", data, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      getLoan();
      handleClose();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const [disabled, setDisabled] = useState(false);


  return (
    <>
      {" "}
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Add New Garantor{" "}
          </Modal.Title>
          <button
            onClick={handleClose}
            className="btn-close btn-danger"
          ></button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-1 form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder=""
                      {...register("name", { required: true })}
                    />
                    <label htmlFor="name">Fullname</label>
                    {errors?.name?.type === "required" && (
                      <p className="text-small m-0 text-start text-danger help-block">
                        This Field is Required
                      </p>
                    )}
                    <input
                      value={params?.id}
                      {...register("loanid")}
                      type="hidden"
                    />
                  </div>
                  <div className="mb-1 form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="nin"
                      name="nin"
                      placeholder="Id No."
                      {...register("nin", { required: true })}
                    />
                    <label htmlFor="nin">Identification</label>
                    {errors?.nin?.type === "required" && (
                      <p className="text-small m-0 text-start text-danger help-block">
                        This Field is Required
                      </p>
                    )}
                  </div>

                 
                  <div className="mb-1 form-floating">
                    <textarea
                      type="text"
                      className="form-control"
                      id="location"
                      name="location"
                      placeholder="Location"
                      {...register("location", { required: true })}
                    ></textarea>
                    <label htmlFor="location">Location</label>
                    {errors?.location?.type === "required" && (
                      <p className="text-small m-0 text-start text-danger help-block">
                        This Field is Required
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-1 form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="occupation"
                      name="occupation"
                      placeholder="Occupation"
                      {...register("occupation", { required: true })}
                    />
                    <label htmlFor="occupation">Occupation</label>
                    {errors?.occupation?.type === "required" && (
                      <p className="text-small m-0 text-start text-danger help-block">
                        This Field is Required
                      </p>
                    )}
                  </div>
                  <div className="mb-1 form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="contact"
                      name="contact"
                      placeholder=""
                      {...register("contact", { required: true })}
                    />
                    <label htmlFor="contact">Contact</label>
                    {errors?.contact?.type === "required" && (
                      <p className="text-small m-0 text-start text-danger help-block">
                        This Field is Required
                      </p>
                    )}
                  </div>

                  {/* {errors?.notes?.type === "required" && <p className="text-small m-0 text-start text-danger help-block">This Field is Required</p>} */}

                  <div className="mb-1 form-floating">
                    <textarea
                      type="text"
                      className="form-control"
                      id="notes"
                      name="notes"
                      placeholder="Description"
                      {...register("notes")}
                    ></textarea>
                    <label htmlFor="notes">Description</label>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark float-start"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Save"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddGarantor;
