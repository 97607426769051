import { useState, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import Loader from "services/loaders/loader";
import Table from "pages/components/common_table";
import LoanPayment from "./components/active/loan-repayment";
import toast, { Toaster } from "react-hot-toast";
import LoanTransactionsPayment from "./components/active/loan-payment_schedule";
import ConfirmModal from "pages/components/confirmModal";
import LoanRollOver from "./components/active/actions/loan-rollover";
import LoanReschedule from "./components/active/actions/loan-reschedule";
import LoanUpdate from "./components/active/actions/loan-update-interest";
import LoanPenalty from "./components/active/loan-penalty-modal";
import LoanPaymentGeneral from "./components/active/loan-repayment-general";
import LoanTransactionsModal from "./components/active/loan-statement-payment";

const LoanActiveSingle = ({ roles }) => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [loan, setLoan] = useState([]);
  const [showTransactionsModal, setShowTransactionsModal] = useState(false);
  const [showPenaltyModal, setShowPenaltyModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleModalTransactionsOpen = () => setShowTransactionsModal(true);
  const handleModalTransactionsClose = () => setShowTransactionsModal(false);
  const handleModalPenaltyOpen = () => setShowPenaltyModal(true);
  const handleModalPenaltyClose = () => setShowPenaltyModal(false);

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const [showModalGeneralRepayment, setShowModalGeneralRepayment] =
    useState(false);

  const handleModalGeneralOpen = () => setShowModalGeneralRepayment(true);
  const handleModalGeneralClose = () => setShowModalGeneralRepayment(false);

  const controller = new AbortController();
  const getLoanAppData = async () => {
    try {
      const response = await axiosPrivate.get(`/applications/${params.id}`, {
        signal: controller.signal,
      });
      setLoan(response?.data?.data?.loanApp);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getLoanAppData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetch = async () => {
    setIsLoading(true);
    await getLoanSchedule();
  };
  const [scheduleData, setScheduleData] = useState();

  const [scheduleId, setScheduleID] = useState([]);

  const getLoanSchedule = async () => {
    try {
      const response = await axiosPrivate.get(`/active-loans/${params.id}`, {
        signal: controller.signal,
      });
      // console.log(response)
      setScheduleData(response?.data?.data?.loans);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getLoanSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      header: "#",
      size: 50,
      id: "period",
      accessorFn: (row) => row.period,
    },
    {
      header: "Due Date",
      size: 50,
      id: "date",

      accessorFn: (row) => row.date,
    },

    {
      header: "Principal",
      size: 50,
      id: "principal",
      accessorFn: (row) => row?.principal?.toLocaleString(),
    },
    {
      header: "Paid Principal",
      size: 50,
      id: "principal_paid",
      accessorFn: (row) => row?.principal_paid?.toLocaleString(),
    },
    {
      header: "Interest",
      size: 50,
      id: "interest",
      accessorFn: (row) => row?.interest?.toLocaleString(),
    },
    {
      header: "Paid Interest",
      size: 50,
      id: "interest_paid",
      accessorFn: (row) => row?.interest_paid?.toLocaleString(),
    },
    {
      header: "Penalties",
      size: 50,
      id: "penalties",
      accessorFn: (row) => row?.penalty?.toLocaleString(),
    },
    {
      header: "Paid Penalties",
      size: 50,
      id: "penalty_paid",
      accessorFn: (row) => row?.penalty_paid?.toLocaleString(),
    },

    {
      header: "Principal Balance",
      size: 50,
      id: "balances",
      accessorFn: (row) => row.amount.toLocaleString(),
    },
    {
      header: "Status",
      size: 50,
      id: "schedule",
      accessorFn: (row) =>
        row.due_status === "due" ? (
          <span className="text-small text-center badge badge-sm bg-info">
            Due
          </span>
        ) : row.due_status === "overdue" ? (
          <span className="text-small text-center badge badge-sm bg-warning">
            Over Due
          </span>
        ) : (
          <span className="text-small text-center badge badge-sm bg-success">
            Cleared
          </span>
        ),
    },
    {
      header: "Payment Status",
      size: 50,
      id: "status",
      accessorFn: (row) =>
        row.status === "paid" ? (
          <span className="text-small text-center badge badge-sm bg-success">
            Paid
          </span>
        ) : row.status === "partial" ? (
          <span className="text-small text-center badge badge-sm bg-info">
            Partial
          </span>
        ) : (
          <span className="text-small text-center badge badge-sm bg-danger">
            Not Paid
          </span>
        ),
    },
    {
      header: "Total Amount Paid",
      size: 50,
      id: "paid",
      accessorFn: (row) => row.amount_paid.toLocaleString(),
    },
    {
      header: "Balance",
      size: 50,
      id: "balance",
      accessorFn: (row) =>
        (row.interest + row.principal - row.amount_paid).toLocaleString(),
    },
    {
      header: "Total Payment",
      size: 50,
      id: "total",
      accessorFn: (row) => (row.interest + row.principal).toLocaleString(),
    },
    {
      header: "Action",
      size: 50,
      id: "action",
      accessorFn: (row) => (
        <div className="p-0">
          {roles.includes(10000076) && (
            <button
              className="btn p-0 btn-sm text-dark mb-0"
              onClick={() => {
                handleModalOpen();
                setScheduleID(row.id);
              }}
            >
              Pay
            </button>
          )}
          {roles.includes(10000077) && (
            <button
              className="btn p-0 px-2 text-info mb-0"
              onClick={() => {
                handleModalTransactionsOpen();
                getScheduleData(row.id);
              }}
            >
              <i className="ti ti-receipt"></i>
            </button>
          )}
          {roles.includes(10000078) && (
            <button
              className="btn p-0 px-1 text-danger mb-0"
              onClick={() => {
                handleModalPenaltyOpen();
                getPenalties(row.id);
              }}
            >
              <i className="ti ti-parking"></i>
            </button>
          )}
        </div>
      ),
    },
  ];
  const [transactions, setTransactions] = useState([]);

  const getScheduleData = async (schedule_id) => {
    try {
      const response = await axiosPrivate.get(`/transactions/${schedule_id}`, {
        signal: controller.signal,
      });
      // console.log(response?.data?.data?.transactions)
      setTransactions(response?.data?.data?.transactions);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const [penalties, setPenalties] = useState([]);
  const getPenalties = async (schedule_id) => {
    try {
      const response = await axiosPrivate.get(`/penalties/${schedule_id}`, {
        signal: controller.signal,
      });
      // console.log(response?.data?.data?.transactions)
      setPenalties(response?.data?.data?.penalties);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const column_action = [
    {
      header: "Action",
      size: 50,
      id: "action",
      accessorFn: (row) => row.action,
    },
    {
      header: "Amount Before",
      size: 50,
      id: "amount",
      accessorFn: (row) => row.amount.toLocaleString(),
    },
    {
      header: "More Data",
      size: 50,
      id: "data",
      accessorFn: (row) => row.data,
    },
    {
      header: "Timestamp",
      size: 50,
      id: "timestamp",
      accessorFn: (row) => row.timestamp,
    },
  ];
  const onStopLoan = async () => {
    const response = await axiosPrivate.patch(
      `/applications/${params.id}`,
      JSON.stringify({ status: "stopped" }),
      {
        signal: controller.signal,
      }
    );
    toast.success(response.data.messages);
    getLoanAppData();
  };
  const onReactiveLoan = async () => {
    const response = await axiosPrivate.patch(
      `/applications/${params.id}`,
      JSON.stringify({ status: "ongoing" }),
      {
        signal: controller.signal,
      }
    );
    toast.success(response.data.messages);
    getLoanAppData();
  };
  const onFlagOff = async () => {
    const response = await axiosPrivate.patch(
      `/applications/${params.id}`,
      JSON.stringify({ status: "flagoff" }),
      {
        signal: controller.signal,
      }
    );
    toast.success(response.data.messages);
    getLoanAppData();
  };

  const [show, setShow] = useState(false);
  const [showReactive, setShowReactive] = useState(false);
  const [showFlag, setShowFlag] = useState(false);
  const handleCancel = () => setShow(false);
  const handleCancelReactive = () => setShow(false);
  const handleCancelFlag = () => setShowFlag(false);
  const [showModalRollOver, setShowModalRollOver] = useState(false);
  const handleModalRollOverOpen = () => setShowModalRollOver(true);
  const handleModalRollOverClose = () => setShowModalRollOver(false);
  const [showModalRescheduled, setShowModalRescheduled] = useState(false);
  const handleModalRescheduledOpen = () => setShowModalRescheduled(true);
  const handleModalRescheduledClose = () => setShowModalRescheduled(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const handleModalUpdateOpen = () => setShowModalUpdate(true);
  const handleModalUpdateClose = () => setShowModalUpdate(false);

  const [showModalPayment, setShowModalPayment] = useState(false);
  const handleModalPaymentOpen = () => setShowModalPayment(true);
  const handleModalPaymentClose = () => setShowModalPayment(false);

  return (
    <>
      <Toaster />

      <div className="container-fluid note-has-grid">
        <div className="card bg-light-info shadow-none position-relative overflow-hidden mt-md-0 mt-5">
          <div className="card-body px-4 py-3 ">
            <div className="row align-items-center">
              <div className="col-12">
                <h5 className="fw-semibold mb-8">Loan Summary</h5>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        className="text-muted text-decoration-none"
                        to="/dashboard"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Loan Summary
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      {loan?.number}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fuild">
          <div className="card overflow-hidden">
            <div className="card-body p-0">
              <div className="row align-items-center mt-5">
                <div className="col-lg-4 mt-1 p-0 order-lg-1 order-2">
                  <div className="mt-n5">
                    <div className="d-flex align-items-center justify-content-center mb-2">
                      <div
                        className="linear-gradient d-flex align-items-center justify-content-center rounded-circle"
                        style={{ width: "110px", height: "110px" }}
                      >
                        <div
                          className="border border-4 border-white d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{ width: "100px", height: "100px" }}
                        >
                          <img
                            src={
                              loan?.client?.client_image ??
                              "../../dist/images/profile/user-1.jpg"
                            }
                            alt=""
                            className="w-100 h-100"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <>
                          <h5 className="fs-2 mb-0 fw-semibold">
                            {loan?.client?.firstname} {loan?.client?.lastname}
                          </h5>
                          <p className="mb-0 fs-1">{loan?.client?.account}</p>
                          <p className="mb-0 fs-1">{loan?.number}</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 order-last">
                  <ul className="list-unstyled d-flex align-items-center justify-content-center justify-content-lg-center my-2 gap-1">
                    {loan?.status_converted !== "closed" ? (
                      <>
                        <li>
                          {roles.includes(10000070) && (
                            <button
                              className="btn btn-success btn-sm"
                              onClick={handleModalRescheduledOpen}
                            >
                              {" "}
                              <i className="ti ti-refresh-alert"></i> Reschedule
                            </button>
                          )}
                        </li>
                        <li>
                          {roles.includes(10000071) && (
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={handleModalRollOverOpen}
                            >
                              {" "}
                              <i className="ti ti-rotate-clockwise-2"></i>{" "}
                              Rollover
                            </button>
                          )}
                        </li>
                        {loan?.status_converted !== "flagoff" ? (
                          <li>
                            {roles.includes(10000072) && (
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => setShowFlag(true)}
                              >
                                <i className="ti ti-barrier-block-off"></i>{" "}
                                Write Off
                              </button>
                            )}
                          </li>
                        ) : (
                          <li>
                            <button className="btn btn-dark btn-sm">
                              This Loan Is Writtern Off
                            </button>
                          </li>
                        )}
                        {loan?.status_converted !== "stopped" ? (
                          <li>
                            {roles.includes(10000073) && (
                              <button
                                className="btn btn-dark btn-sm"
                                onClick={() => setShow(true)}
                              >
                                <i className="ti ti-hand-stop"></i> Waiver Off
                              </button>
                            )}
                          </li>
                        ) : (
                          <li>
                            {roles.includes(10000073) && (
                              <button
                                className="btn btn-success btn-sm"
                                onClick={() => setShowReactive(true)}
                              >
                                <i className="ti ti-check"></i> Reactivitate
                                Loan
                              </button>
                            )}
                          </li>
                        )}
                        <li>
                          {roles.includes(10000074) && (
                            <button
                              className="btn btn-warning btn-sm"
                              onClick={handleModalUpdateOpen}
                            >
                              <i className="ti ti-reload"></i> Update Interest
                            </button>
                          )}
                        </li>
                      </>
                    ) : (
                      <li>
                        <button className="btn btn-dark btn-sm">
                          This Loan Is Completed
                        </button>
                      </li>
                    )}
                    <li>
                      {roles.includes(10000075) && (
                        <button
                          className="btn btn-info btn-sm"
                          onClick={handleModalPaymentOpen}
                        >
                          <i className="ti ti-eye"></i> Statement
                        </button>
                      )}
                    </li>
                    <li>
                      {roles.includes(10000075) && (
                        <button
                          className="btn btn-success btn-sm"
                          onClick={handleModalGeneralOpen}
                        >
                          <i className="ti ti-currency-dollar"></i> Pay Loan
                        </button>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row p-2">
            <Table
              refetch={refetch}
              columns={columns}
              isLoading={isLoading}
              data={scheduleData ?? []}
            />
            <div className="col-md-4 p-0">
              <div className="card mt-2 shadow-md border-1">
                <div className="card-body text-sm p-0">
                  <ul className="list-group">
                    <li className="list-group-item border-0 d-flex align-items-center">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 fs-2">Loan Amount</h6>
                      </div>
                      <p className="btn  text-small text-dark pe-4 ps-0 mb-0 ms-auto w-0 w-md-auto">
                        {" "}
                        {loan?.amount?.toLocaleString()}
                      </p>
                    </li>

                    <li className="list-group-item border-0 d-flex align-items-center">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 fs-2">Loan Total Payment</h6>
                      </div>
                      <p className="btn  text-small text-dark pe-4 ps-0 mb-0 ms-auto w-0 w-md-auto">
                        {" "}
                        {loan?.amountToBePaid?.toLocaleString()}
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 fs-2">Loan Balance</h6>
                      </div>
                      <p className="btn text-small text-dark pe-4 ps-0 mb-0 ms-auto w-0 w-md-auto">
                        {" "}
                        {loan?.amountLeft?.toLocaleString()}
                      </p>
                    </li>

                    <li className="list-group-item border-0 d-flex align-items-center">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 fs-2">Loan Interest Rate</h6>
                      </div>
                      <p className="btn  text-small text-dark pe-4 ps-0 mb-0 ms-auto w-0 w-md-auto">
                        {" "}
                        {loan?.rate} %
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 fs-2">Loan Penalty Rate</h6>
                      </div>
                      <p className="btn  text-small text-dark pe-4 ps-0 mb-0 ms-auto w-0 w-md-auto">
                        {" "}
                        {loan?.penalty_percentage} %
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 fs-2">Loan Frequency</h6>
                      </div>
                      <p className="btn  text-small text-dark pe-4 ps-0 mb-0 ms-auto w-0 w-md-auto">
                        {" "}
                        {loan?.frequency === "monthly"
                          ? "Monthly"
                          : loan?.frequency === "daily"
                          ? "Daily"
                          : loan?.frequency === "weekly"
                          ? "Weekly"
                          : loan?.frequency === "yearly"
                          ? "Yearly"
                          : ""}
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 fs-2">Loan Type</h6>
                      </div>
                      <p className="btn  text-small text-dark pe-4 ps-0 mb-0 ms-auto w-0 w-md-auto">
                        {" "}
                        {loan?.type}
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 fs-2">Loan Period</h6>
                      </div>
                      <p className="btn  text-small text-dark pe-4 ps-0 mb-0 ms-auto w-0 w-md-auto">
                        {" "}
                        {loan?.period}
                      </p>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center">
                      <div className="d-flex align-items-start flex-column justify-content-center">
                        <h6 className="mb-0 fs-2">Loan Officer</h6>
                      </div>

                      <p className="btn  text-small text-dark pe-4 ps-0 mb-0 ms-auto w-0 w-md-auto">
                        {" "}
                        {loan?.officer?.firstname} {loan?.officer?.lastname}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-8 mt-2">
              <div className="card">
                <div className="card-header">
                  <h6 className="mb-0 fs-3">
                    Loan Actions After Disurbment History
                  </h6>
                </div>
                <div className="card-body p-0">
                  <Table
                    refetch={refetch}
                    columns={column_action}
                    isLoading={isLoading}
                    data={loan?.afterActions ?? []}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LoanPayment
        schedule_id={scheduleId}
        showModal={showModal}
        handleModalClose={handleModalClose}
        getLoanSchedule={getLoanSchedule}
      />
      <LoanTransactionsPayment
        transactions={transactions}
        showTransactionsModal={showTransactionsModal}
        handleModalTransactionsClose={handleModalTransactionsClose}
      />
      {showModalPayment && (
        <LoanTransactionsModal
          transactions={transactions}
          showTransactionsModal={showModalPayment}
          handleModalTransactionsClose={handleModalPaymentClose}
        />
      )}
      <LoanPenalty
        transactions={penalties}
        showTransactionsModal={showPenaltyModal}
        handleModalTransactionsClose={handleModalPenaltyClose}
      />
      <ConfirmModal
        message="Are you sure you want to waiver off (stop) this loan?"
        onConfirm={onStopLoan}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
      <ConfirmModal
        message="Are you sure you want to Reactivate this loan?"
        onConfirm={onReactiveLoan}
        onCancel={handleCancelReactive}
        show={showReactive}
        setShow={setShowReactive}
      />
      <ConfirmModal
        message="Are you sure you want to Write off (flag off) this loan?  Please note this loan will be auto completed"
        onConfirm={onFlagOff}
        onCancel={handleCancelFlag}
        show={showFlag}
        setShow={setShowFlag}
      />
      <LoanRollOver
        handleModalClose={handleModalRollOverClose}
        showModal={showModalRollOver}
        getLoanSchedule={getLoanSchedule}
        getLoan={getLoanAppData}
      />
      <LoanReschedule
        handleModalClose={handleModalRescheduledClose}
        showModal={showModalRescheduled}
        getLoanSchedule={getLoanSchedule}
        getLoan={getLoanAppData}
      />
      <LoanUpdate
        handleModalClose={handleModalUpdateClose}
        showModal={showModalUpdate}
        getLoanSchedule={getLoanSchedule}
        getLoan={getLoanAppData}
      />
      <LoanPaymentGeneral
        showModal={showModalGeneralRepayment}
        handleModalClose={handleModalGeneralClose}
        getLoanSchedule={getLoanSchedule}
      />
    </>
  );
};

export default LoanActiveSingle;
