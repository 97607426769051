import axios from "axios";

// const BASE_URL = "http://localhost/furaha/wi-twins/";
const BASE_URL = "https://api.wi-twins.com/";


export default axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const axiosPrivate = axios.create({
        baseURL : BASE_URL,
        headers: {'Content-Type': 'application/json'},
        withCredentials: false
        
});
