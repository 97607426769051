import GeneralManagerDashboard from "./dashboard/general";
import BranchManagerDasboard from "./dashboard/branch";
import NoDashboard from "./dashboard/nodashboard";

const DashBoard = ({roles}) => {
  
  return (
    <>
      {roles?.includes(100000112) && <GeneralManagerDashboard />}
      {roles?.includes(10000012) && <BranchManagerDasboard />}
      {roles.includes(100000112) || roles.includes(10000012) ? (
        ""
      ) : (
        <NoDashboard />
      )}
    </>
  );
};

export default DashBoard;
