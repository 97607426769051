import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Title,
  Filler,
  Tooltip,
  Legend,
  defaults,
} from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  (defaults.font.family = "Nunito")
);
const ClientsPieChart = ({ transactions }) => {
  const [chartOptions, setChartOptions] = useState({});
  const [chartData, setChartData] = useState({ datasets: [] });
  useEffect(() => {

    // Extract months and total amounts
    const totalMale = transactions?.filter(client =>client.gender ==="male").length;
    const totalFemale = transactions?.filter(client =>client.gender ==="female").length;

    // Prepare chart data
    const data = {
      labels: ["Male", "Female"],
      datasets: [
        {
          label: "Clients",
          data: [totalMale, totalFemale],
          tension: 0.1,
          fill: true,
          borderColor: ["rgb(2,15,70)", "rgb(183,4,4)"],
          backgroundColor: ["rgba(2,15,70,0.8)", "rgba(183,4,4, 0.8)"], // Area under the line
          pointBackgroundColor: ["rgba(2,15,70,0.9)", "rgba(183,4,4,0.9)"], // Point color
          pointRadius: 5, // Point size
        },
      ],
    };

    setChartData(data);
    const options = {
      layout: {
        padding: 0, // Remove padding around the chart
      },
     
      plugins: {
        legend: {
          display: true, // Hide legend
        },
        tooltip: {
          enabled: true,
          titleFont: {
            size: 14,
            weight: "bold",
          },
          bodyFont: {
            size: 12,
          },
        },
      },
    };
    setChartOptions(options);
  }, [transactions]);
  return (
    <>
      <Pie data={chartData} options={chartOptions} />
    </>
  );
};

export default ClientsPieChart;
