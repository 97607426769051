import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useLogout from "middlewares/hooks/useLogout";

const Navbar = ({handleSidebarToggle, roles}) => {
  const navigate = useNavigate();


    const logout = useLogout();
    const signOut = async () => {
      if (window.confirm("Are you sure you want to logout?")) {
        logout();
        localStorage.clear();
        navigate("/");
      }
    };
const user_info = JSON.parse(localStorage.user_info);
  return (
    <>
      <header className="app-header">
        <nav className="navbar navbar-expand-lg bg-white">
          <ul className="navbar-nav d-block d-sm-none">
            <li className="nav-item">
              <button
                className="nav-link sidebartoggler nav-icon-hover ms-n3"
                id="headerCollapse"
                onClick={handleSidebarToggle}
              >
                <i className="ti ti-menu-2"></i>
              </button>
            </li>
          </ul>

          <div className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto"></ul>
            <div className="navbar-nav ml-auto float-right-buttons d-noned-sm-flex">
              <Link
                to="/accounts/profile"
                className="btn btn-sm btn-warning mx-1"
              >
                {user_info?.branch}
              </Link>
              <Link to="/accounts/profile" className="btn btn-sm btn-info mx-1">
                {user_info?.role}
              </Link>
              <Link
                to="/accounts/profile"
                className="btn btn-sm btn-danger mx-1"
              >
                {user_info?.name}
              </Link>
            </div>
          </div>
          <div className="dropdown">
            <div
              className="rounded-circle overflow-hidden me-6"
              data-bs-toggle="dropdown"
            >
              <img
                src="dist/images/profile/user-1.jpg"
                alt=""
                width="40"
                height="40"
              />
            </div>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <Link to="/accounts/profile" className="dropdown-item">
                  <span>
                    <i className="ti ti-user p-1"></i>
                  </span>
                  Profile
                </Link>
              </li>
              <li>
                <Link to="/accounts/profile" className="dropdown-item">
                  <span>
                    <i className="ti ti-lock p-1"></i>
                  </span>
                  Change Password
                </Link>
              </li>
              <li>
                <button onClick={signOut} className="dropdown-item">
                  <span>
                    <i className="ti ti-logout text-danger p-1"></i>
                  </span>
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </nav>

        {/* Show the buttons on small and extra-small screens */}
        <div className="d-block d-sm-none btn-group">
          <Link to="/accounts/profile" className="btn btn-sm btn-info">
            {user_info?.role}
          </Link>
          <Link to="/accounts/profile" className="btn btn-sm btn-danger">
            {user_info?.name}
          </Link>
        </div>
      </header>
    </>
  );
};

export default Navbar;
