import { Link } from "react-router-dom";
const HealthSummary = () => {

  return (
    <>

          <div className="container-fluid note-has-grid">
            <div className="card bg-light-danger shadow-none position-relative overflow-hidden mt-md-0 mt-5">
              <div className="card-body px-4 py-3 ">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h5 className="fw-semibold mb-8">System Health</h5>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            className="text-muted text-decoration-none"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          System Health Summary
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fuild">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-4 col-sm-12">
                      <div className="card shadow-lg border-2 rounded">
                        <div className="card-header">
                          <div className="text-end">
                            <p className="text-sm text-capitalize">
                              SMS Service
                            </p>
                            <h4 className="mb-0 text-warning">OK</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="card shadow-lg border-2 rounded">
                        <div className="card-header">
                          <div className="text-end">
                            <p className="text-sm text-capitalize">
                              Email Service
                            </p>
                            <h4 className="mb-0 text-success">OK</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="card shadow-lg border-2 rounded">
                        <div className="card-header">
                          <div className="text-end">
                            <p className="text-sm text-capitalize">
                              Client Service
                            </p>
                            <h4 className="mb-0 text-success">Ok</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="card shadow-lg border-2 rounded">
                        <div className="card-header">
                          <div className="text-end">
                            <p className="text-sm text-capitalize">
                              CronJob Service
                            </p>
                            <h4 className="mb-0 text-success">Ok</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="card shadow-lg border-2 rounded">
                        <div className="card-header">
                          <div className="text-end">
                            <p className="text-sm text-capitalize">
                              Loans Service
                            </p>
                            <h4 className="mb-0 text-success">Ok</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="card shadow-lg border-2 rounded">
                        <div className="card-header">
                          <div className="text-end">
                            <p className="text-sm text-capitalize">
                              Retry On Failure{" "}
                            </p>
                            <h4 className="mb-0 text-success">ON</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="card shadow-lg border-2 rounded">
                        <div className="card-header">
                          <div className="text-end">
                            <p className="text-sm text-capitalize">
                              Mobile Banking
                            </p>
                            <h4 className="mb-0 text-success">On</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="card shadow-lg border-2 rounded">
                        <div className="card-header">
                          <div className="text-end">
                            <p className="text-sm text-capitalize">
                              Reporting Service
                            </p>
                            <h4 className="mb-0 text-success">ON</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="card shadow-lg border-2 rounded">
                        <div className="card-header">
                          <div className="text-end">
                            <p className="text-sm text-capitalize">Backup</p>
                            <h4 className="mb-0 text-success">ON</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
    </>
  );
};

export default HealthSummary;
