import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const AddAssets = ({ getAssets, handleClose, showModal }) => {
  const progress = new ProgressBar();
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const controller = new AbortController();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const [accounts, setAccounts] = useState([]);
   const getAccounts = async () => {
     try {
       const response = await axiosPrivate.get(`/settings/accounts`, {
         signal: controller.signal,
       });
       setAccounts(response?.data?.data?.accounts);
     } catch (error) {
       // console.log(error)
       if (error?.response?.status === 401) {
         navigate("/", { state: { from: location }, replace: true });
       }
     }
   };
  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.post("/assets", data, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      handleClose();
      getAssets();
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  useEffect( ()=>{
    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Add Asset
          </Modal.Title>
          <button onClick={handleClose} className="btn-close btn-danger">
            <i className="material-icons opacity-10"></i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="row">
              <div className="form-floating mb-1 col-6">
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  placeholder=""
                  {...register("title", {
                    required: true,
                  })}
                />
                <label htmlFor="title">Title</label>
                {errors?.title?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter title
                  </p>
                )}
              </div>
              <div className="form-floating mb-1 col-6">
                <input
                  type="number"
                  className="form-control"
                  id="price"
                  name="price"
                  placeholder=""
                  {...register("price", {
                    required: true,
                  })}
                />
                <label htmlFor="price">Price</label>
                {errors?.price?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter price
                  </p>
                )}
              </div>
              <div className="form-floating mb-1 col-12">
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  name="description"
                  placeholder=""
                  {...register("description", {
                    required: true,
                  })}
                />
                <label htmlFor="description">Description</label>
                {errors?.description?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter description
                  </p>
                )}
              </div>
              <div className="form-floating mb-1 col-6">
                <input
                  type="date"
                  className="form-control"
                  id="date"
                  name="date"
                  placeholder=""
                  {...register("date", {
                    required: true,
                  })}
                />
                <label htmlFor="date">Date</label>
                {errors?.date?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter date
                  </p>
                )}
              </div>
              <div className="form-floating mb-1 col-6">
                <select
                  className="form-control"
                  id="account"
                  name="account"
                  placeholder=""
                  {...register("account", {
                    required: true,
                  })}
                >
                    {accounts.map(account => (
                        <option value={account.id} key={account.id}>{account.title}</option>
                    ))}
                </select>
                <label htmlFor="account">Select Vote</label>
                {errors?.account?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter account
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Submit"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddAssets;
