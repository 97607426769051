import { Link } from "react-router-dom";

const AccountingHome = () => {
  return (
    <>
 
          <div className="container-fluid note-has-grid">
            <div className="card bg-light-dark text-white shadow-none position-relative overflow-hidden mt-md-0 mt-5">
              <div className="card-body px-4 py-3">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h6 className="fw-semibold mb-8 text-white">
                      Accounting Reports
                    </h6>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            className="text-muted text-decoration-none text-white"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          Accounting Reports
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fuild">
              <div className="card">
                <div className="card-body row">
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">
                        Cash Book
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/accounting/cashbook"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">
                        General Ledger
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/accounting/general-ledger"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">
                        Trial Balance
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/accounting/trial-balance"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">
                        Profit & Loss
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/accounting/income-statement"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">
                        Profit & Loss Detailed
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/accounting"
                          className="btn btn-danger"
                        >
                          Coming Soon
                        </Link>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">
                        Balance Sheet
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/accounting/balance-sheet"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">
                        Payment Transactions
                      </div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/accounting/payment-transactions"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">Incomes</div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/accounting/incomes-general"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card shadow-lg border-1">
                      <div className="card-header h6 text-center">Expenses</div>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <Link
                          to="/reports/accounting/expenses-general"
                          className="btn btn-primary"
                        >
                          Click To View
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
    </>
  );
};

export default AccountingHome;
