import { useState, useMemo } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
// import Table from "pages/components/common_table";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here

const SettledLoansBranch = ({ roles }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const columns = useMemo(
    () => [
      {
        header: "Action",
        id: "loan_app_id",
        size: 50,
        accessorFn: (row) => (
          <>
            {roles.includes(10000043) && (
              <Link
                to={`/loans/${row.loan_app_id}`}
                className="text-dark font-weight-bold text-xs"
              >
                view
              </Link>
            )}
          </>
        ),
        enableClickToCopy: false,
      },
      {
        header: "Approved Date",
        accessorKey: "loan_app_date",
        size: 50,
        enableClickToCopy: true,
      },
      {
        header: "Loan N0.",
        accessorKey: "loan_app_number",
        enableClickToCopy: true,
      },
      {
        header: "Group",
        size: 50,
        id: "customer_category_title",
        accessorFn: (row) => row.customer_category_title,
        enableClickToCopy: true,
      },
      {
        header: "A/C N0.",
        id: "client_account_number",
        size: 50,
        accessorFn: (row) => `${row.client_account_number}`,

        enableClickToCopy: true,
      },
      {
        header: "National Id",
        id: "client_identification",
        size: 50,
        accessorFn: (row) => `${row.client_identification}`,

        enableClickToCopy: true,
      },
      {
        header: "Contact",
        id: "client_contact",
        size: 50,
        accessorFn: (row) => `${row.client_contact}`,

        enableClickToCopy: true,
      },
      {
        header: "Client",
        id: "client_firstname",
        size: 50,
        accessorFn: (row) => `${row.client_firstname} ${row.client_lastname}`,

        enableClickToCopy: true,
      },
      {
        header: "Officer",
        id: "user_firstname",
        size: 50,
        accessorFn: (row) => `${row.user_firstname} ${row.user_lastname}`,
        enableClickToCopy: true,
      },
      {
        header: "Cycle",
        id: "loan_app_frequency",
        size: 50,
        enableClickToCopy: true,
        accessorFn: (row) =>
          row.loan_app_frequency === "monthly"
            ? "Monthly"
            : row.loan_app_frequency === "weekly"
            ? "Weekly"
            : row.loan_app_frequency === "daily"
            ? "Daily"
            : "Yearly",
      },
      {
        header: "Principal Taken",
        id: "loan_app_amount",
        size: 50,
        enableClickToCopy: true,
        accessorFn: (row) => parseFloat(row.loan_app_amount).toLocaleString(),
      },

      {
        header: "Expected Interest",
        id: "loan_interest_amount",
        size: 50,
        enableClickToCopy: true,
        accessorFn: (row) =>
          parseFloat(row?.loan_interest_amount)?.toLocaleString(),
      },

      {
        header: "Branch",
        id: "branch_name",
        size: 50,
        enableClickToCopy: true,
        accessorFn: (row) => row?.branch_name,
      },

      {
        header: "Status",
        id: "loan_app_converted",
        size: 50,
        accessorFn: (row) => (
          <div className="align-middle float-end text-sm">
            {row?.loan_app_converted === "ongoing" ? (
              <span className="text-small text-center badge badge-sm bg-success">
                OnGoing
              </span>
            ) : row?.loan_app_converted === "closed" ? (
              <span className="text-small text-center badge badge-sm bg-info">
                Completed
              </span>
            ) : row?.loan_app_converted === "stopped" ? (
              <span className="text-small text-center badge badge-sm bg-primary">
                Stopped
              </span>
            ) : row?.loan_app_converted === "overdue" ? (
              <span className="text-small text-center badge badge-sm bg-danger">
                Overdue
              </span>
            ) : (
              "Error"
            )}
          </div>
        ),
        enableClickToCopy: true,
      },
    ],
    [roles]
  );
  // eslint-disable-next-line
  const [columnFilters, setColumnFilters] = useState([]);
  // eslint-disable-next-line
  const [globalFilter, setGlobalFilter] = useState("");
  // eslint-disable-next-line
  const [sorting, setSorting] = useState([]);
  // eslint-disable-next-line
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const {
    data: { data = [], meta } = {}, // your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, // refetch when columnFilters changes
      globalFilter, // refetch when globalFilter changes
      pagination.pageIndex, // refetch when pagination.pageIndex changes
      pagination.pageSize, // refetch when pagination.pageSize changes
      sorting, // refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = `server-logic/branch/settled-loans`;

      try {
        const response = await axiosPrivate.get(fetchURL, {
          params: {
            start: pagination.pageIndex * pagination.pageSize,
            size: pagination.pageSize,
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? "",
            sorting: JSON.stringify(sorting ?? []),
            branchid: params.id,
          },
        });

        // console.log(response.data.data);
        return response.data.data;
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        console.error("Axios request failed", error);
        throw error;
      }
    },
    placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
  });
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  return (
    <>
      <div className="container-fluid note-has-grid">
        <div className="card bg-light-info shadow-none position-relative overflow-hidden mt-md-0 mt-5">
          <div className="card-body px-4 py-3 ">
            <div className="row align-items-center">
              <div className="col-12">
                <h5 className="fw-semibold mb-8">Setlled Loans Branch</h5>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        className="text-muted text-decoration-none"
                        to="/dashboard"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Setlled Loans Branch
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fuild">
          <div className="card">
            <div className="card-header row">
              <div className="col-md-6 col-sm-12">
                <h5 className="fw-semibold mb-8">Setlled Loans Branch</h5>
              </div>
            </div>
            <div className="card-body p-0">
              {/* <Table
                columns={columns}
                data={loans?.filter((loan) => loan.status === "ongoing")}
                isLoading={isLoading}
                refetch={refetch}
              /> */}
              <MaterialReactTable
                data={data}
                columns={columns}
                enableColumnFilterModes
                enableColumnOrdering
                enableRowSelection
                manualFiltering={true}
                manualPagination={true}
                manualSorting={true}
                enableGrouping
                enableStickyHeader
                className="table align-items-justify table-flush p-0 m-0"
                initialState={{
                  density: "compact",
                  showColumnFilters: false,
                }}
                // paginationDisplayMode={"pages"}
                muiPaginationProps={{
                  shape: "rounded",
                  sx: { fontFamily: "Nunito", fontSize: "10px" },
                }}
                // state={{ isLoading: isLoading }}
                muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                muiTableHeadCellProps={{
                  sx: {
                    fontWeight: "normal",
                    fontSize: "12px",
                    fontFamily: "Nunito",
                  },
                }}
                muiCircularProgressProps={{
                  color: "secondary",
                  thickness: 5,
                  size: 25,
                }}
                muiSkeletonProps={{
                  animation: "pulse",
                  height: 28,
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontWeight: "normal",
                    fontSize: "12px",
                    fontFamily: "Nunito",
                  },
                }}
                muiSearchTextFieldProps={{
                  placeholder: "Search",
                  sx: { fontFamily: "Nunito", fontSize: "12px" },
                }}
                renderTopToolbarCustomActions={({ table }) => (
                  <>
                    <div className="btn-group">
                      <button className="btn btn-sm btn-danger rounded-0">
                        <i className="fa fa-file-pdf"></i>
                      </button>

                      <button
                        className="btn btn-sm btn-success rounded-0"
                        disabled={table.getRowModel().rows.length === 0}
                        //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                        onClick={() =>
                          handleExportRows(table.getRowModel().rows)
                        }
                      >
                        <i className="fa fa-file-excel"></i>
                      </button>
                      <button className="btn btn-sm btn-info rounded-0">
                        <i className="fa fa-print"></i>
                      </button>
                      <button
                        onClick={refetch}
                        className="btn btn-sm btn-dark rounded-0"
                      >
                        <i className="fa"> &#xf021;</i>
                      </button>
                    </div>
                  </>
                )}
                rowCount={meta?.totalRowCount ?? 0}
                state={{
                  columnFilters,
                  globalFilter,
                  isLoading,
                  pagination,
                  showAlertBanner: isError,
                  showProgressBars: isRefetching,
                  sorting,
                }}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettledLoansBranch;
