import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import axios from "config/axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import fileDownload from "js-file-download";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
const LoanTransactionsModal = ({
  handleModalTransactionsClose,
  showTransactionsModal,
}) => {
  // console.log(scheduleId);
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const navigate = useNavigate();
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const params = useParams();
  const [transactions, setTransactions] =useState([]);
  let total = 0;
  // let penalty = 0;
  // let payment = 0;
  for (let i = 0; i < transactions.length; i++) {
    total += transactions[i].amount;
    // penalty += transactions[i].type === "penalty" ? transactions[i].amount : 0;
    // payment += transactions[i].type === "payment" ? transactions[i].amount : 0;
  }

  const controller = new AbortController();
  const getPenalties = async () => {
    try {
      const response = await axiosPrivate.get(`/loan-statement/${params.id}`, {
        signal: controller.signal,
      });
      // console.log(response?.data?.data?.transactions)
      setTransactions(response?.data?.data);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect( ()=>{
    getPenalties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const generatePDF = async (generate_data) => {
    // console.log(generate_data)
    try {
      const data = {
        transactions: generate_data,
        penalty: "penalty",
        payment: "payment",
        total: total,
      };
      //  console.log(data);
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axios.post(
        `/export/reports/loans/payment/single`,
        { data: data },
        { responseType: "blob" },
        {
          signal: controller.signal,
        }
      );
      //  console.log(response.data);
      const download_title = "loan_schedule_history.pdf";
      fileDownload(response.data, download_title);
      progress.finish();
      setDisabled(false);
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  return (
    <>
      <Toaster />
      <Modal
        show={showTransactionsModal}
        backdrop="static"
        keyboard={false}
        onHide={handleModalTransactionsClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Loan Statement{" "}
          </Modal.Title>
          <button
            onClick={handleModalTransactionsClose}
            className="btn-close btn-danger"
          ></button>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fliud">
            <table className="table table-bordered text-sm">
              <thead>
                <tr className="text-center fs-2">
                  <th>Transaction ID</th>
                  <th>Amount</th>
                  <th>Narrative</th>
                  <th>Date </th>
                  {/* <th>Type</th> */}
                  {/* <th>User</th> */}
                </tr>
              </thead>
              <tbody className="text-center fs-1">
                {transactions?.map((data) => (
                  <tr key={data.id}>
                    <td> {data.code} </td>
                    <td> {data.amount.toLocaleString()} </td>
                    <td> {data.narrative}</td>
                    <td> {data.timestamp} </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="d-flex flex-row-reverse text-dark">
              <div className="text-center text-small">
                {/* <div className="mb-2 text-dark">Total Amount</div> */}
                <div className="fs-1 font-weight-light">
                  {/* {payment.toLocaleString()}{" "} */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-dark"
            onClick={handleModalTransactionsClose}
          >
            Close
          </button>
          <button
            disabled={disabled}
            onClick={() => {
              generatePDF(transactions);
            }}
            type="submit"
            className="btn btn-primary"
          >
            {!disabled && "Download"} {disabled && "Please Wait"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoanTransactionsModal;
