import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useForm, Controller } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Select from "react-select";

const AddNewApplication = ({ handleClose, showModal, getLoansAps }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const params = useParams();
  const [disabled, setDisabled] = useState(false);
  const [clients, setClient] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    data.branchid = params.id;
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post("applications", data, {
        signal: controller.signal,
      });
      console.log(response);
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      reset();
      getLoansAps();
      handleClose();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const controller = new AbortController();
  const getClients = async () => {
    try {
      const response = await axiosPrivate.get("simple/clients", {
        signal: controller.signal,
      });
      setClient(response.data.data.clients);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = clients?.map((client) => ({
    value: `${client.id}`,
    label: `${client.firstname} ${client.lastname} - ${client.account} - ${client.contact}`,
  }));
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "60px",
      width: "240px",
      borderColor: "#cccccc",
      backgroundColor: "#fff",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      opacity: 1,
      zIndex: 9999,
    }),
  };
  return (
    <>
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            New Loan Application
          </Modal.Title>
          <button
            onClick={handleClose}
            className="btn-close btn-danger"
          ></button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-1 form-floating">
                  <Controller
                    control={control}
                    name="client"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        {...register("client", { required: true })}
                        options={options}
                        onChange={onChange}
                        isMulti={false}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                        searchable={true}
                        styles={customStyles}
                        placeholder="Please select client"
                        ref={ref}
                      />
                    )}
                  />
                  {errors?.client?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please Select Client
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="amount"
                    name="amount"
                    placeholder=""
                    {...register("amount", { required: true })}
                  />
                  <label htmlFor="amount">Amount</label>
                  {errors?.amount?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter Applicatiton Amount
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <select
                    className="form-control"
                    id="type"
                    name="type"
                    {...register("type", { required: true })}
                  >
                    <option value=""></option>
                    <option value="fixed">Fixed Interest</option>
                    <option value="reducing_balance">Reducing Balance</option>
                  </select>
                  <label htmlFor="type" className="px-3">
                    Interest Type
                  </label>
                  {errors?.type?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please select Interest Type
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-1 form-floating">
                  <select
                    className="form-control"
                    id="frequency"
                    name="frequency"
                    {...register("frequency", { required: true })}
                  >
                    <option value=""></option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                  <label htmlFor="frequency" className="px-3">
                    Frequency
                  </label>
                  {errors?.frequency?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please Select Frequency
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="fee"
                    name="fee"
                    placeholder="Processing Fee *"
                    {...register("fee", { required: true })}
                  />
                  <label htmlFor="fee">Processing Fee</label>
                  {errors?.fee?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter Processing Fee
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="date"
                    className="form-control"
                    id="date"
                    name="date"
                    placeholder="Application Date"
                    {...register("date", { required: true })}
                  />
                  <label htmlFor="date">Application Date</label>
                  {errors?.date?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter loan application date.
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-1 form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="rate"
                    name="rate"
                    placeholder="Interest Rate *"
                    {...register("rate", { required: true })}
                  />
                  <label htmlFor="rate"> Interest Rate</label>
                  {errors?.rate?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter valid address
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="period"
                    name="period"
                    placeholder="Payment Period *"
                    {...register("period", { required: true })}
                  />
                  <label htmlFor="period">Tenure Period</label>
                  {errors?.period?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter tenure Period
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="method"
                    name="method"
                    placeholder="Method of Disbursment"
                    {...register("method", { required: true })}
                  />
                  <label htmlFor="method">Method Of Disbursment</label>
                  {errors?.method?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter method of disbursment
                    </p>
                  )}
                </div>
              </div>

              <div className="form-floating col-lg-12 mb-2">
                <textarea
                  type="text"
                  className="form-control form-control-lg"
                  id="info"
                  name="info"
                  placeholder="More Information"
                  {...register("info")}
                  style={{ border: "solid 1px #ccc", height: "100px", fontSize: "11px" }}
                ></textarea>
                <label htmlFor="info" className="px-4">
                  More Information
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Apply"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddNewApplication;
