import { Link } from "react-router-dom";
import { useMemo } from "react";
import Table from "pages/components/common_table";
const CLientLoans = ({ loans, refetch, isLoading }) => {
  const columns = useMemo(
    () => [
      {
        id: "loan_number", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Loan Number",
        accessorFn: (row) => row.number,
      },
      {
        id: "principal", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Principal",
        accessorFn: (row) => row.amount.toLocaleString(),
      },

      {
        id: "interest", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Interest",
        accessorFn: (row) => (row.amounttobepaid - row.amount).toLocaleString(),
      },

      {
        id: "amount_paid", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Amount Paid",
        accessorFn: (row) => row.amountpaid.toLocaleString(),
      },
      {
        id: "date", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Start Date",
        accessorFn: (row) => row.date,
      },
      {
        id: "user", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "User",
        accessorFn: (row) => `${row.officer.firstname} ${row.officer.lastname}`,
      },
      {
        id: "id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Action",
        accessorFn: (row) => (
          <div className="align-middle text-center text-sm">
            <Link
              to={`/loans/${row.id}`}
              className="text-dark font-weight-bold text-xs"
            >
              <i className="ti ti-arrow-bar-right"></i>
            </Link>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <>
      <div className="border-none">
        <div>
          <h6 className="fw-semibold p-4 text-center">Active Loans</h6>
          <Table
            columns={columns}
            data={loans?.filter((loan) => loan.status === "ongoing") ?? []}
            isLoading={isLoading}
            refetch={refetch}
          />
        </div>
        <div>
          <h6 className="fw-semibold p-4 text-center">Overdue Loans</h6>
          <Table
            columns={columns}
            data={loans?.filter((loan) => loan.status === "overdue") ?? []}
            isLoading={isLoading}
            refetch={refetch}
          />
        </div>
        <div>
          <h6 className="fw-semibold p-4 text-center">Completed Loans</h6>
          <Table
            columns={columns}
            data={loans?.filter((loan) => loan.status === "closed") ?? []}
            isLoading={isLoading}
            refetch={refetch}
          />
        </div>
      </div>
    </>
  );
};

export default CLientLoans;
