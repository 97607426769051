import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Table from "pages/components/common_table";
import ConfirmModal from "pages/components/confirmModal";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
const Branches = () => {
  const controller = new AbortController();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar();

  const [branches, setBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const getBranches = async () => {
    try {
      const response = await axiosPrivate.get("/settings/branches", {
        signal: controller.signal,
      });
      setBranches(response.data.data.branches);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = async () => {
    setIsLoading(true);
    await getBranches();
  };
  const handleConfirm = async () => {};
  const handleCancel = async () => {};
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Name",
      },
      {
        accessorKey: "timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Timestamp",
      },
      {
        id: "id", //access nested data with dot notation
        accessorFn: (row) => (
          <button
            className="btn btn-link text-danger mb-0"
            onClick={() => {
              setShow(true);
            }}
          >
            <i className="ti ti-trash"></i>
          </button>
        ),
        enableClickToCopy: false,
        size: 50,
        header: "Action",
      },
    ],
    []
  );
  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.post("/settings/branches", data, {
        signal: controller.signal,
      });
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      getBranches();
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  return (
    <>
      <Toaster />
     
          <div className="container-fluid note-has-grid">
            <div className="card bg-light-danger shadow-none position-relative overflow-hidden mt-md-0 mt-5">
              <div className="card-body px-4 py-3 ">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h5 className="fw-semibold mb-8">Branches</h5>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            className="text-muted text-decoration-none"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                          Branches 
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fuild">
              <div className="card">
                <div className="card-header row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="fw-semibold mb-8">Branches</h5>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-md-8">
                      <Table
                        columns={columns}
                        data={branches}
                        isLoading={isLoading}
                        refetch={refetch}
                        height={"150px"}
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="card ">
                        <div className="card-header">Add New Branch</div>
                        <div className="card-body p-1 mt-2">
                          <form
                            className="container"
                            noValidate
                            onSubmit={handleSubmit(onSave)}
                          >
                            <div className="mb-2 form-floating">
                              <input
                                type="text"
                                className="form-control px-2"
                                id="name"
                                name="name"
                                placeholder=" "
                                {...register("name", {
                                  required: true,
                                })}
                              />

                              <label htmlFor="name" className="px-2">
                                Branch Title
                              </label>
                              {errors?.name?.type === "required" && (
                                <p className="text-small m-0 text-start text-danger help-block">
                                  This Field is Required
                                </p>
                              )}
                            </div>
                            <button
                              className="btn btn-outline-primary float-end"
                              disabled={disabled}
                            >
                              {!disabled && "Save"} {disabled && "Please Wait"}
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
      <ConfirmModal
        message="Are you sure you want to delete this branch?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

export default Branches;
