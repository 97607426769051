import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const EditClient = ({ handleClose, showModal, clientData, getClient }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  // console.log(clientData)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: clientData });

  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.patch(
        `/clients/${clientData.id}`,
        data,
        {
          signal: controller.signal,
        }
      );
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      handleClose();
      getClient();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Edit Client - {clientData?.account} - {clientData?.firstname}{" "}
            {clientData?.lastname}
          </Modal.Title>
          <button
            onClick={handleClose}
            className="btn-close btn-danger"
          ></button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-4">
                <div className="form-floating mb-1">
                  <input
                    type="text"
                    className="form-control"
                    id="firstname"
                    name="firstname"
                    defaultValue={clientData?.firstname}
                    {...register("firstname", { required: true })}
                  />
                  <label htmlFor="firstname">Surname</label>
                  {errors?.firstname?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter your firstname
                    </p>
                  )}
                </div>

                <div className="form-floating mb-1">
                  <label htmlFor="gender" className="text-small p-2">
                    Select Gender
                  </label>
                  <select
                    className="form-control"
                    id="gender"
                    name="gender"
                    defaultValue={clientData?.gender}
                    {...register("gender", { required: true })}
                  >
                    <option value=""></option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  {errors?.gender?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please select gender
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    defaultValue={clientData?.email}
                    {...register("email", { pattern: { value: /^\S+@\S+$/i } })}
                  />
                  <label htmlFor="email">Email</label>
                  {errors?.email?.type === "pattern" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter valid mail or leave blank
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-1 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="lastname"
                    name="lastname"
                    defaultValue={clientData?.lastname}
                    {...register("lastname", { required: true })}
                  />
                  <label htmlFor="lastname">Lastname</label>
                  {errors?.lastname?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter lastname
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="date"
                    className="form-control"
                    id="dob"
                    name="dob"
                    defaultValue={clientData?.dob}
                    {...register("dob", { required: true })}
                  />
                  <label htmlFor="dob">Date Of Birth</label>
                  {errors?.dob?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter client date of birth
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    defaultValue={clientData?.address}
                    {...register("address", { required: true })}
                  />
                  <label htmlFor="address">Address</label>
                  {errors?.address?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter valid address
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-1 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="identification"
                    name="identification"
                    defaultValue={clientData?.identification}
                    {...register("identification", { required: true })}
                  />
                  <label htmlFor="identification">Identification</label>
                  {errors?.identification?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter passport number or NIN
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="tel"
                    className="form-control"
                    id="contact"
                    name="contact"
                    defaultValue={clientData?.contact}
                    {...register("contact", { required: true })}
                  />
                  <label htmlFor="contact">Contact</label>
                  {errors?.contact?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter valid contact
                    </p>
                  )}
                </div>

                <div className="mb-1 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="occupation"
                    name="occupation"
                    defaultValue={clientData?.occupation}
                    {...register("occupation", { required: true })}
                  />
                  <label htmlFor="occupation">Occupation</label>
                  {errors?.occupation?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please enter occupation
                    </p>
                  )}
                </div>
              </div>

              <div className="col-lg-12 mb-2 form-floating">
                <textarea
                  type="text"
                  className="form-control form-control-lg"
                  id="otherinfo"
                  name="otherinfo"
                  defaultValue={clientData?.otherinfo}
                  {...register("otherinfo")}
                  style={{ border: "solid 1px #ccc", height: "70px", fontSize: "11px" }}
                ></textarea>
                <label htmlFor="otherinfo" className="px-4">
                  {" "}
                  More Information
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Update"} {disabled && "Please Wait"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default EditClient;
